import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";


//semantic
import {
    Popup,
    Icon,
    Container, 
    Grid, 
    Form, 
    Button, 
    Message, 
    Loader} from 'semantic-ui-react';

import ConfigSteps from "../ConfigSteps/ConfigSteps";
import Sidebar from "../Sidebar/Sidebar";
import ConfigSection from "./ConfigSection/ConfigSection";
import ErrorPopup from "./ErrorPopup/ErrorPopup";
import PortalDatabaseViewer from "../../PortalDatabaseViewer/PortalDatabaseViewer";
import PopulateConnectionStore from '../../../hoc/PopulateConnectionStore/PopulateConnectionStore';
import ConnectionAdd from '../../ConnectionAdd/connectionAddUpdated';


//react hook form
import { useForm, FormContext } from 'react-hook-form';
import { formHasErrors_h, generateFormErrorsArray_h } from '../../../utils/formHelpers';
import { createUUID_h } from "../../../utils/uuidGenerator";

//api's
import { getPluginById } from "../../../feathers/services/plugins";
import { getQbTables, createQbTable } from "../../../feathers/services/qbTables";
// import { getQbApps } from "../../../feathers/services/qbApps";
import { createConfiguration } from "../../../feathers/services/configrations";
import { getConfiguration, patchConfiguration } from "../../../feathers/services/configrations";


//utils 
import { ApiError_h } from "../../../utils/apiHelpers";
import { createSemanticDropdownObjects_h } from "../../../utils/formHelpers";
import { generateConfigObjectFromFormData_h, generateConfigSectionsFromConfigMapping_h } from "./mapConfigUtils";

//router
import { useHistory } from 'react-router-dom';

//react router dom
import { useParams } from 'react-router-dom';

//styles
import styles from './index.module.css';




function MapConfiguration() {

    

    //when a user is editing a configuration, the URL scheme has "edit" in it.
    var userIsEditingExistingConfig = window.location.href.indexOf('edit') >= 0 ? true : false;

    /*************************************************
     *  Redux
     *************************************************/
    //error here is from an API call error when calling connections service
    const { isLoading, connections } = useSelector(state => state.connections);

    /*************************************************
     *  React Router
     *************************************************/

    const history = useHistory();

    /*************************************************
     *  URL Params
     *************************************************/
    // URL Query Parameters
    const params = useParams();

    // Get the connection id from the route - config id only used for editing purposes
    const { connectionId, pluginId, configId } = params;
    var error = null;

    /*************************************************
     *  State
     *************************************************/
    //commented this out as it does not seem to be used anywhere
    // var [errors, setErrors]                           = useState(false);
    var [showErrorPopup, setShowErrorPopup]           = useState(false);
    var [errorPopupMessage, setErrorPopupMessage]     = useState('');
    var [onSubmitLoading, setOnSubmitLoading]         = useState(false);
    var [initialQueryError, setInialQueryError]       = useState('');
    var [plugin, setPlugin]                           = useState(null);
    var [configSections, setConfigSections]           = useState(null);
    var [pageLoading, setPageLoading]                 = useState(true);
    //sets the qb tables a user has access to
    var [dbTables, setDbTables]                       = useState([]);
    //sets the qb apps a user has access to. 
    // var [apps, setApps]                               = useState([]);
    var [newFieldEmitter, setNewFieldEmitter]         = useState(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var [configurationToEdit, setConfigurationToEdit] = useState(null);
    var [portalOpen, setPortalOpen]                   = useState(false);
    //when adding a connection you need to know what type of connection to add.  This variables controls that process.
    var [addConnectionType, setAddConnectionType]     = useState('');

    /*************************************************
     *  React Hook Form
     *************************************************/
    var methods = useForm();
    var { handleSubmit, errors, formState: { isSubmitting } } = methods;


    /*************************************************
     *  Effects
     *************************************************/

    useEffect(()=>{
        //if the connections page gets too big, when you come to this page you're half way tdown the page - this resets scrollbar to account for this.
        window.scrollTo(0, 0);
    },[]);

    //if a user is not editing a configuration, gets plugin and qb tables based on URL params.
    useEffect(()=>{
        if ( !userIsEditingExistingConfig ) {
            Promise.all([getPluginById(pluginId), getQbTables(connectionId)]).then((res) => {
                var plugin   = res[0];
                var qbTables = res[1].data;
                // var qbApps   = res[2].data;


                //create table dropdown options:
                var qbTableDropdowns = createSemanticDropdownObjects_h(qbTables, "id", "name", "id");
                // var qbAppDropdowns   = createSemanticDropdownObjects_h(qbApps, "dbid","dbname", "dbid");

                //set necessary state
                // setApps(qbAppDropdowns);
                setPlugin(plugin);
                setConfigSections(plugin.configTemplate.configSections);
                setDbTables(qbTableDropdowns);
                setPageLoading(false);
            }).catch((error) => {
                setPageLoading(false);
                // setIsLoading(false);

                // eslint-disable-next-line react-hooks/exhaustive-deps
                var error = new ApiError_h(error);
                setInialQueryError(`There was an error obtaining the necessary data.  Please make sure the connection you used is valid, and/or try this setup with a different connection.  Error details: ${error.getUserMessage()}`);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //if a user is editing an existing configuration
    useEffect(()=>{
        if( userIsEditingExistingConfig ) {
            async function getPageLoadData() {
                //get the configuration
                try {
                    var configuration = await getConfiguration(configId);
                    setConfigurationToEdit(configuration);
                } catch (error) {
                    setPageLoading(false);
                    // setIsLoading(false);

                   
                    var err = new ApiError_h(error);
                    setInialQueryError(`There was an error obtaining the necessary data.  Please make sure this is a valid configuration.  Error details: ${err.getUserMessage()}`);
                    return;
                }


                (async () => {
                    try {
                        var [plugin, qbTables] = await Promise.all([
                            getPluginById(configuration.plugin_id),
                            getQbTables(connectionId)
                        ]);

                        //create table dropdown options:
                        qbTables = qbTables.data;
                        var qbTableDropdowns = createSemanticDropdownObjects_h(qbTables, "id", "name", "id");
                        // var qbAppDropdowns   = createSemanticDropdownObjects_h(qbApps.data, "dbid", "dbname", "dbid");

                        //set necessary state
                        setPlugin(plugin);

                        //map the configMapping to configSections
                        var configSections = [...plugin.configTemplate.configSections];
                        var configMapping  = configuration.configMapping;

                        //this checks to see if the user picked the same connection as they previously had for this configuration.  If they did, then use their existing field mapping for this plugin, else don't use the existing mapping and start with a blank configuration from the plugin and no default values set.
                        if ( connectionId === configuration.connection_id ) {
                            configSections = generateConfigSectionsFromConfigMapping_h(configMapping, configSections);
                        }
                        
                        setConfigSections(configSections);
                        // setApps(qbAppDropdowns);
                        setDbTables(qbTableDropdowns);
                        setPageLoading(false);
                    } catch (error) {
                        setPageLoading(false);
                        // setIsLoading(false);

                        
                        var err = new ApiError_h(error);
                        setInialQueryError(`There was an error obtaining the necessary data (plugin and Quickbase tables).  Error details: ${err.getUserMessage()}`);
                        return;
                    }
                })();
            }

            getPageLoadData();

            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //After submital, remove the loading gif on the submit button of the form.  This is being done for performance reasons, "isSubmitting" is called in a function that processes a bunch of data and it freezes before the gif can show.
    useEffect(()=>{
        if( !isSubmitting && onSubmitLoading ) {
            setOnSubmitLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);


    
    



    /*************************************************
     *  Handlers
     *************************************************/

     const handleOpenPortal = (bool)=>{
        setPortalOpen(bool);
     };

    /**
     * Handles main form submittal
     * @param {Object} formData React hook form form data
     */
    const onSubmit = formData => {
        // setOnSubmitLoading(true);

        //large amount of processing
        var configMapping = generateConfigObjectFromFormData_h(configSections, formData);
        //If the user is editing an existing configuration
        if( userIsEditingExistingConfig ) {
            patchConfiguration(configId, {configMapping, connection_id: connectionId, tempPluginId:plugin._id}).then((res)=>{
                const configId = res._id;
                history.push(`/edit/config/success/${plugin._id}/${configId}`);
            }).catch((error)=>{
                setOnSubmitLoading(false);
                error = new ApiError_h(error);
                setErrorPopupMessage(`There was an error updating this configuration.  Please try again by refreshing the page.  If the problem persists, please contact support.  Error details: ${error.getUserMessage()}`);
                setShowErrorPopup(true);
            });
        }

        //if the user is creating a new configuration
        if( !userIsEditingExistingConfig ) {
            var configuration = {
                plugin_id: pluginId,
                configMapping,
                connection_id: connectionId
            };

            createConfiguration(configuration).then((res) => {
                const configId = res._id;
                history.push(`/config/success/${pluginId}/${configId}`);
            }).catch((error) => {
                setOnSubmitLoading(false);
                error = new ApiError_h(error);
                setErrorPopupMessage(`There was an error creating this configuration.  Error details: ${error.getUserMessage()}`);
                setShowErrorPopup(true);
            });
        }

        
    };

    /**
     * Handles creating a new dbtable
     * @param {Object} table
     * @param {String} table.connectionId Id of this connection object
     * @param {String} table.tableName New table name being created.
     * TODO - remove the connection object form this, it's present in this component alreayd.
     */
    const handleAddNewDbTable = ( table ) => {
        return createQbTable({connectionId:table.connectionId , tableName: table.tableName}).then((res)=>{
            setDbTables([...dbTables, { key: res.data.id, text: table.tableName, value: res.data.id }]);
            return Promise.resolve(res);
        }).catch((error)=>{
            setErrorPopupMessage("There was an error adding this item.  Please try again.  If the problem persists, please contact support or manually add the needed item to your Quickbase application.  Click outside of this message to close.");
            setShowErrorPopup(true);
            return Promise.reject(error);
        });
    };

    /**
     * Create new dbfield handler
     * @param {Object} data
     * @param {Object} data.newField Semantic UI dropdown formatted field {key, text, value} 
     * @param {String} data.fieldType Render type of the new field. 
     * @param {String} data.configSectionName Name of the configsection that this new field was added to
     * @param {String} data.dbTable Table the field was added to - UID of table (dbid in QB for instance).
     * @param {Boolean} data.error Error, if present.  Triggers error modal to show
     */
    const handleAddNewDBField = (data)=>{
        if(data.error) {
            setErrorPopupMessage(`There was an error adding this item.  You can manually add the needed field to your Quickbase application and click the refresh icon in this configuration.  ERROR MSG: ${data.errorMsg}  Click outside of this message to close.`);
            setShowErrorPopup(true);
        } else {
            setNewFieldEmitter(data);
        }
    };


    /**
     * Handles adding new children sections to the UI.
     * @param {Object} configSectionObject Actual object from the config section of the plugin
     * @param {number} indexOfParent This is the index of the parent in the configSections array.  Allows the script to place the child right next to the parent configSection in the UI.
     */
    const handleAddNewSection = (configSectionObject, indexOfParent) => {
        //if you are editing a configSection, defaultValues are passed from the server on page load - we need to remove all of these when adding child tables.
        if( userIsEditingExistingConfig ) {
            configSectionObject.defaultValue = null;
            configSectionObject.fields = configSectionObject.fields.map(field=>{
                return {...field, defaultValue: null};
            });
        }
        //Child section names have to be renamed as they need to be unique.  Convetion is to add "__uuid" to the config section name, and subsequently all the fields in that configsection.  This keeps all fields and configsections unique for react-hook-form submittal.
        var updatedConfigSectionObject = {...configSectionObject};
        var uuidForChild = createUUID_h();
        updatedConfigSectionObject.name = `${updatedConfigSectionObject.name}__${uuidForChild}`;
       //insert right after the parent
        var updatedConfigSections = [...configSections];
        updatedConfigSections.splice(indexOfParent + 1, 0, updatedConfigSectionObject);

        setConfigSections(updatedConfigSections);
    };


    /**
     * Handles deleting a repeatable section.
     * @param {string} nameOfConfigSection Name of the configuration section to be deleted.  These are unique.
     */
    const handleDeleteSection = ( nameOfConfigSection )=>{
        var updatedConfigSections = [...configSections];

        for (var i = 0; i < updatedConfigSections.length; i++) {
            if (updatedConfigSections[i].name === nameOfConfigSection) {
                updatedConfigSections.splice(i, 1);
                break;
            }
        }

        setConfigSections(updatedConfigSections);
    };

    //determines what connection type to show int he add connection modal.
    const handleSetConnectionType = ({type})=>{
        setAddConnectionType(type);
    };







    /*************************************************
     *  Main Content
     *************************************************/
    return (
        <PopulateConnectionStore>
            {/*Top Process Steps*/}
            <ConfigSteps activeStep={2} />

            <Container>

                {/* add new connections  */}
                <ConnectionAdd 
                    type={addConnectionType}
                />
                {/* add new connections  */}


                {/* Loading Gif */}
                {pageLoading && !initialQueryError &&
                    <div className={styles['margin-top']}>
                        <Loader active inline='centered' size='large'><span>Loading Data...</span></Loader>
                    </div>
                }
                {/* Loading Gif */}


                {/* Initial Page Load Error Message */}
                {initialQueryError &&
                    <Message negative>
                        <Message.Header>Error Encountered</Message.Header>
                        <p>{initialQueryError}</p>
                    </Message>
                }
                {/* Initial Page Load Error Message */}


                {/* Portal Database Viewer  */}
                {portalOpen && 
                    <PortalDatabaseViewer
                        connectionId={connectionId}
                        portalOpen={portalOpen}
                        setPortalOpen={handleOpenPortal}
                    />
                }
                {/* Portal Database Viewer  */}



                {/* Configuration Form  */}
                {!pageLoading && !initialQueryError && plugin &&
                    <FormContext {...methods}>
                        <Form
                            // loading={true}
                            // success={formSubmitSuccess}
                            onSubmit={()=>{
                                setOnSubmitLoading(true);
                                //large amount of processing here, let the loading gif show before this starts otherwise the browser gets bogged down.
                                setTimeout(()=>{
                                    handleSubmit(onSubmit)();
                                }, 5);
                            }}
                            error={formHasErrors_h(errors)}
                        >
                            <Grid>
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={12}
                                    widescreen={12}
                                >

                                    <h3>{plugin.name && plugin.name.toUpperCase()} CONFIGURATION</h3>
                                    <p>
                                        <em>This step requires you to configure the plugin by mapping the tables and fields in each of the sections below to your Quickbase application.  If you get lost, see the help video to the right.</ em>
                                    </p>
                                    
                                    {/* Launch Plugin ID Finder Link  */}
                                    {plugin.showIdFinder &&
                                        <Popup
                                            content='This plugin requires you to find DBIDs or FIDs from your Quickbase application.  Clicking this will open a popup to make your job of finding these IDs much easier.'
                                            trigger={<span onClick={handleOpenPortal} className={styles["find-ids"]}><Icon size="large" name="search plus" /> Click to Find Quickbase ID's</span>
                                            }
                                        />
                                    }
                                    {/* Launch Plugin ID Finder Link  */}

                                    <br />

                                    {/* uses react context to pass React Hook Form down deeply nested structures. */}

                                    {configSections &&

                                        configSections.map((configSection, i) =>
                                            <ConfigSection
                                                key={configSection.name}
                                                configSectionObject={configSection}
                                                handleSetConnectionType={handleSetConnectionType}
                                                handleAddNewSection={handleAddNewSection}
                                                handleDeleteSection={handleDeleteSection}
                                                index={i}
                                                dbTables={dbTables}
                                                connectionId={connectionId}
                                                onAddNewTable={handleAddNewDbTable}
                                                onAddNewField={handleAddNewDBField}
                                                newFieldEmitter={newFieldEmitter}
                                                configSections={configSections}
                                                connectionsLoading={isLoading}
                                                connections={connections}
                                            />
                                    )}


                                </Grid.Column>
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={4}
                                    widescreen={4}
                                >
                                    <Sidebar 
                                        helpText="What is a configuration?"
                                        youtubeVideoId="PqMkEEKJiPU"
                                    />
                                </Grid.Column>
                            </Grid>

                            <div className={styles["margin-bottom"]} />

                            <Button
                                basic
                                size={"large"}
                                color={"teal"}
                                type={"submit"}
                                loading={onSubmitLoading}
                            >
                                Save and Continue
                            </Button>

                            <div className={styles["margin-bottom"]} />

                            {/* Error Messags  */}
                            <Grid>
                                <Grid.Column
                                    mobile={16}
                                    tablet={16}
                                    computer={16}
                                    largeScreen={12}
                                    widescreen={12}
                                >
                                    <Message
                                        negative
                                        error
                                        header='There were some errors submitting this form'
                                        list={generateFormErrorsArray_h(errors)}
                                    />
                                </Grid.Column>
                            </Grid>
                            {/* Error Messags  */}

                            <div className={styles["config-bottom-margin"]} />
                            



                        </Form>
                    </FormContext>                    
                }
                {/* End Configuration Form  */}



                {/* Error Notifications for Add New Field and Add New Table  */}
                <ErrorPopup 
                    onOpen={(e)=>setShowErrorPopup(true)}
                    onClose={(e)=>setShowErrorPopup(false)}
                    isOpen={showErrorPopup}
                    errorTitle="Error"
                    errorMsg={errorPopupMessage ? errorPopupMessage : "There was an error adding this item.  Please try again.  If the problem persists, please contact support or manually add the needed item to your Quickbase application.  Click outside of this message to close."}
                />

            </Container>
        </PopulateConnectionStore>
    );

}

export default MapConfiguration;