/**
* Payment Profile API Calls
*/

import client from '../index';


/***********************************
 * TYPEDEFS
 ***********************************/
/**
 * TODO: Add this to typedef's file.
 * ProfileObject typedef
 * @typedef {Object} PaymentProfileObject
 * @property {string} customerProfileId Assignable customer id controlled by developer (mongodb user_id)
 * @property {string} customerPaymentProfileId The users customer payment profile id
 * @property {object} payment Payment object
 * @property {object} payment.creditCard Credit card object
 * @property {object} payment.creditCard.cardNumber Obfuscated credit card number
 * @property {object} payment.creditCard.expirationDate Expiration date of card obfuscated
 * @property {object} payment.creditCard.cardType Type of card
 * @property {array} subscriptions array of subscription id's associated with this user
 * @property {object} billTo
 * @property {string} billTo.address The users street address
 * @property {string} billTo.lastName Indicates whether the Power component is present.
 * @property {string} billTo.phoneNumber Indicates whether the Wisdom component is present.
 * @property {string} billTo.city Indicates whether the Wisdom component is present.
 * @property {string} billTo.company Indicates whether the Wisdom component is present.
 * @property {string} billTo.email Indicates whether the Wisdom component is present.
 * @property {string} billTo.zip Indicates whether the Wisdom component is present.
 */
/***********************************
 * TYPEDEFS
 ***********************************/




/**
 * Adds a new connection to the database.  Returns all plugins
 * @param {object} data firstname, lastname, address, payment nonce etc
 */
export function createPaymentProfile( data ) {
    return client.service('payment-profiles').create( data );
}


/**
 * Gets a payment profile of a user from authorize.net (zip, billing etc.) only IF the user is authenticated and has access to that payment profile.
 * @param {String} customerPaymentProfileId Users payment profile id
 * @return {PaymentProfileObject}
 */
export function getPaymentProfile( customerPaymentProfileId ) {
    return client.service('payment-profiles').get(customerPaymentProfileId);
}



/**
 * 
 * @param {Object} paymentProfile Users paymetn profile
 * @param {string} paymentprofile.customerProfileId Id of the customer in Authorize.net
 * @param {string} paymentProfile.customerPaymentProfileId Id of the customers payment profile
 * @param {object} data firstname, lastname, address, payment nonce etc
 */
export function patchPaymentProfile( paymentProfile, data ) {
    return client.service('payment-profiles').patch(paymentProfile, data);
}
