import React from 'react';

//semantic
import {Grid, Divider} from 'semantic-ui-react';
import HeaderElement from "../../HeaderElement/HeaderElement";


//styles
import styles from './index.module.css';


/**
 * Outer Wrapper for All Legal Content That showcases legal section title.
 * @param {Object} props React component props object
 * @param {Object} props.children All content for this particular legal section.  JSX children content to render.
 * @param {String} props.updatedDate Last time this document was updated.  Optional.
 * @param {String} props.title Title of legal section
 */
function LegalWrapper({children, title, updatedDate}) {
    window.scrollTo(0, 0);
    return (
        <>
            <div className={styles["legal-wrapper"]}>
                <Grid centered>
                    <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12} widescreen={12}>

                        {title && 
                            <HeaderElement
                                headertext={title}
                            />
                        }

                        {updatedDate &&
                            <div>Last updated: {updatedDate}</div>
                        }

                        {(title || updatedDate) &&
                            <Divider />
                        }
                        
                        { children }
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );

}

export default LegalWrapper;