import React from 'react';
import { Icon } from 'semantic-ui-react';



//styles
import styles from './index.module.css';



/**
 * Responsible for adding error message text (red text) to the landing pages (signin/signup errors etc.)
 * @param {Object} props Component props
 * @param {string} props.errorText Text to render to the screen.  IF null, sets HomePageError component to null.
 */
function HomePageError({errorText}) {

    return (
        <>
            {errorText &&
                <>
                    <div className={styles['clear_float']}></div>
                    <div className={styles['error__text']}>
                        <Icon name="warning sign" color="red"/>{errorText}
                    </div>
                </>
                
            }
            {/* conditionally render error text  */}
        </>
    );

}

export default HomePageError;