import React, { useEffect } from 'react';

//semantic
// import {} from 'semantic-ui-react';


import { getPlugins } from "../../store/actions/pluginsActions";
import { useDispatch, useSelector } from "react-redux";


//styles
// import styles from './index.module.css';

/**
 * Grabs all plugins if the application does not already have them and adds them to the redux store.
 * @param {Object} props React component props object
 * @param {} props.children Children pass to this React component 
 */
function PopulatePluginStore({ children }) {


    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch       = useDispatch();
    const { allPlugins } = useSelector(state => state.plugins);
    //TODO - set up error handling



    /*************************************************
     *  Effects
     *************************************************/

    useEffect(() => {
        //if no plugins are present - get the plugins on page load
        if (allPlugins.length === 0) {
            dispatch(getPlugins());
        }
    }, [allPlugins.length, dispatch, allPlugins]);

    return (
        <>
             { children }
        </>
    );

}

export default PopulatePluginStore;