import React from 'react';

import { useParams } from 'react-router-dom';

//semantic
import {
    Grid,
    Embed,
    Container
} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';

/**
 * Used to showcase videos for tutorials without taking someone over to youtube
 * @param {Object} props React component props object
 * @param {string} props.id youtube video ID
 */
function VideoPlayer() {


    /*************************************************
     *  Query Parameters
     *************************************************/
    // URL Query Parameters
    const params = useParams();
    const { id } = params;

    return (
        <>
            <div className="mb-medium" />
            <Grid centered>
                <Grid.Row>
                    {/*Main Map form*/}
                    <Grid.Column mobile={16} table={16} computer={12} largeScreen={10} widescreen={10}>
                        {/* <Header as='h2' icon color="teal" textAlign='center'>
                            Video Content
                        </Header> */}
                        <div className="mb-medium" />
                        <Container>
                            <Embed
                                active
                                autoplay={true}
                                id={id}
                                // placeholder='/images/image-16by9.png'
                                source='youtube'
                            />
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );

}

export default VideoPlayer;