import React from 'react';

//semantic
import { Icon, 
        Button, 
        Grid } from 'semantic-ui-react';

import { Link } from "react-router-dom";

//styles
import styles from './index.module.css';


/**
 * Component used for homepage messages and success.  Includes button, message and icon.
 * @param {Object} props Component props
 * @param {string} props.msgText Text you want to show for the message to the user.
 * @param {string} props.iconName Name of the Semantic icon you want to show.  If null, hides icon.
 * @param {string} props.buttonText Text to show for a button.  IF null, hides button.
 * @param {string} props.link Link to wrap button with so when user clicks they are redirected there. 
 * @param {string} props.iconSize Size of icon.  Choices are "big", "huge", "large", "massive", "mini", "small", "tiny"
 */
function HomePageMsg({ msgText, iconName=null, iconSize="big", buttonText=null, link=null}) {

    //add necessary spacing if icon is present
    var successMessageStyles = [styles["success-message"]];
    if( iconName ) successMessageStyles.push(styles["margin-top"]);
    var userMsgStyles = [styles["user-msg"]];
    if(iconName) userMsgStyles.push(styles["margin-top"]);

    return (
        <div className={successMessageStyles.join(' ')}>

            {/* Conditionally shows icon if iconName prop is present */}
            {iconName &&
                <Icon
                    name={iconName}
                    size={ iconSize }
                    color="teal"
                />
            }
            {/* Conditionally shows icon */}
            
            <br />

            <div className={ userMsgStyles.join(' ') }>
                
                <span>{ msgText }</span>

                {/* Conditional Button Render */}
                {buttonText &&
                    <div className={ styles["margin-top"] }>
                        <Grid>
                            <Grid.Column textAlign="center">
                                <Link to={ link }>
                                    <Button className={styles["margin-top"]} primary>{buttonText}</Button>
                                </Link>
                            </Grid.Column>
                        </Grid>
                    </div>
                }
                {/* Conditional Button Render */}

            </div>

            

        </div>
    );

}

export default HomePageMsg;