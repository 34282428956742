/******************************
 *  Reducer for QB TABLES
 ******************************/
import * as types from '../types';
import {ApiError_h} from '../../utils/apiHelpers';


const initialState = {
    isLoading: false,
    qbFields: {},
    error: '',

    // For create reducers
    createQbField: null,
    isCreating: false,
    createError: ''
};

/**
 *
 * @param state The whole state
 * @param action
 * @param {string} action.type The type of action dispatched
 * @param {object} action.payload The data payload
 * @param {string} action.payload.tableId The table Id is required in the payload for all successful dispatch
 * @param {array} action.payload.data The response data is required in the payload for all successful dispatch
 * @return {{isLoading: boolean, qbFields: {}, error: string}|{isLoading: boolean, qbFields: {}, error: string}|{isLoading: boolean, qbFields: {}, error: string}}
 */
export default (state = initialState, action) => {
    switch (action.type) {

        /* GET REDUCERS */
        case types.GET_QB_FIELDS:
            return {
                ...state,
                isLoading: true
            };
        case types.GET_QB_FIELDS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                qbFields: {...state.qbFields, [action.payload.tableId]: action.payload.data}
            };
        case types.GET_QB_FIELDS_FAILURE:
            const error = new ApiError_h(action.payload);
            const errorMsg = error.getUserMessage();
            return {
                ...state,
                isLoading: false,
                error: errorMsg
            };
        case types.GET_QB_FIELDS_ERROR:
            return {
                ...state,
                error: ''
            };
        /* GET CASES */


        /* CREATE REDUCERS */
        case types.CREATE_QB_FIELD:
            return {
                ...state,
                isCreating: true
            };
        case types.CREATE_QB_FIELD_SUCCESS:
            return {
                ...state,
                isCreating: false,
                createError: '',
                createQbField: {[action.payload.tableId]: action.payload.data},
            };
        case types.CREATE_QB_FIELD_FAILURE:
            const createError = new ApiError_h(action.payload);
            const createErrorMsg = createError.getUserMessage();
            return {
                ...state,
                isCreating: false,
                createError: createErrorMsg,
                createQbField: null,
            };
        case types.CREATE_QB_FIELD_ERROR:
            return {
                ...state,
                createError: '',
                createQbField: null,
            };
        /* CREATE CASES */

        default:
            return state;
    }
};