import React from 'react';

//semantic
import { Message, Header, Dimmer, Loader } from 'semantic-ui-react';
import { formatToUSD_h } from "../../utils/currencyHelpers";
//styles
import styles from './index.module.css';


/**
 * Object contains information about the subscription (discount, total etc.)
 * @typedef {Object} SubscriptionSummary
 * @property {Boolean} validPromotion True/false on whether the promotion is valid or not.
 * @property {Number} subscriptionPrice The price of the subscriptin after discount is applied.
 * @property {Number} discount Discount of the promotion
 * @property {Number} basePrice Base price of the subscription
 * @property {String} details If the promotional code is invalid, gives a brief overview of why.
 */



/**
 * Used to showcase the subscription summary which includes the total for this subscriptoin less any discounts from the promotional code (if present).
 * @param {Object} props React component props object
 * @param {SubscriptionSummary} props.subscriptionSummary A summary of the subscription in question
 * @param {Boolean} props.loading Shows a loader
 */
function SubscriptionSummary({subscriptionSummary=null, loading=false, frequency}) {
    // useEffect(()=>{
    //     console.log(frequency);
    // });
    return (
        <>
            <div className={styles["top-margin"]}></div>
            <Message>
                <Header as='h3'>SUBSCRIPTION SUMMARY</Header>
                <div className={styles["top-margin"]}></div>
                <p>
                    Based on your selection and any promotional codes, your 
                    subscription total is:
                </p>
                <div className={styles["top-margin"]}></div>
                <Header as='h3'>

                    {/* If user hasn't completed form  */}
                    {(!subscriptionSummary || (!subscriptionSummary.subscriptionPrice && subscriptionSummary.subscriptionPrice !== 0)) &&
                        <span className={styles["subscription-total"]}>Complete Form for Details</span>
                    }
                    {/* If user hasn't completed form  */}


                    {/* greater than zero dollar handler  */}
                    {(subscriptionSummary && (subscriptionSummary && (typeof subscriptionSummary.subscriptionPrice === "number" && subscriptionSummary.subscriptionPrice !== 0))) &&
                        <>  
                        <span className={styles["subscription-total"]}>{ formatToUSD_h(subscriptionSummary.subscriptionPrice) }</span> &nbsp;
                            <span>billed {frequency && frequency.indexOf("monthly") >= 0 ? "Monthly" : "Yearly"}</span>
                        </>
                    }
                    {/* greater than zero dollar handler  */}


                    {/* zero dollar handler  */}
                    {subscriptionSummary && subscriptionSummary.subscriptionPrice === 0 &&
                        <> 
                            <span className={styles["subscription-total"]}>
                                {formatToUSD_h(subscriptionSummary.subscriptionPrice)} 
                            </span>
                        </>
                    }
                    {/* zero dollar handler  */}

                </Header>
                {(subscriptionSummary && subscriptionSummary.discount > 0) &&
                    <span className={styles["subscription-promo-discount"]}>(-{formatToUSD_h(subscriptionSummary.discount)} Promotional Discount)</span>
                }
                <div className={styles["top-margin"]}></div>
                <Dimmer 
                    active={loading}
                    inverted
                >
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Message>
        </>
    );

}

export default SubscriptionSummary;