import React from 'react';

// Styles
import styles from './index.module.css';

// Components
import {Container, Grid} from "semantic-ui-react";
import ConfigStepCard from "./ConfigStepCard";

/**
 * Config steps group of cards.
 * @param {Object} props Props for this component
 * @param {number} props.activeStep The current active step number
 */
function ConfigSteps({activeStep = 1}) {

    let headerTitle;

    if (activeStep === 1) {
        headerTitle = "Step #1 - Connection";
    } else if (activeStep === 2) {
        headerTitle = "Step #2 - Configuration";
    } else {
        headerTitle = "Step #3 - Completion";
    }

    return <>

        <section className={styles['wrapper']}>

            <Container>

                <header className={styles["headerTitle"]}>
                    {headerTitle}
                </header>

                <div className={styles['stepGroup']}>
                    <Grid centered padded>


                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={5} widescreen={5}
                                     className={[styles['no-padding'], 'hybrid'].join(" ")}>
                            <ConfigStepCard active={activeStep === 1} index={1}
                                            content={"Start by selecting a Quickbase Connection"}/>
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={5} widescreen={5}
                                     className={[styles['no-padding'], 'hybrid'].join(" ")}>
                            <ConfigStepCard active={activeStep === 2} index={2}
                                            content={"Map your Quickbase fields and tables"}/>
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={5} widescreen={5}
                                     className={[styles['no-padding'], 'hybrid'].join(" ")}>
                            <ConfigStepCard active={activeStep === 3} index={3}
                                            content={"Finalize installation and use your new plugin!"}/>
                        </Grid.Column>


                    </Grid>
                </div>

            </Container>

        </section>

    </>

}

export default ConfigSteps;