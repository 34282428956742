
/**
 * Certain plugins require that we add in a configuration ID to the URL to be copied by the end user and also this function can be updated in the future to handle more complex configuration requests
 * @param {String} configId The config ID in question
 * @param {Object} plugin Plugin object from the database.
 * @return {String} Returns the updated URL for he end user to open/copy
 */
export const plugginUrlGenerator_h = (configId, plugin) => {
    var pluginUrl = '';
    if( plugin.pluginUrl ) {
        pluginUrl = plugin.pluginUrl;
        pluginUrl = pluginUrl.replace(new RegExp(`{{configId}}`, 'g'), `${configId}`);
    }
    return pluginUrl;
};

