import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../store/actions/connectionActions';
import { ApiError_h } from '../../utils/apiHelpers';




//semantic
import {
    Portal,
    Segment,
    Grid,
    Header,
    Icon,
    Popup,
    Message
} from 'semantic-ui-react';

import PortalInput from "./PortalInput/PortalInput";
import { getQbApps } from "../../feathers/services/qbApps";
import { getQbTables } from "../../feathers/services/qbTables";
import { getQbFields } from "../../feathers/services/qbFields";




//styles
import styles from './index.module.css';

/**
 * Creates semantic dropdown option objects by interating over an array of objects and generating a sematnic ui Dropdown object for each unique value found in the array of objects.
 * @param {arrayOfObjects} arrayOfObjects This is an array of objects.  Each object has a property that will be a value in the dropdown options.
 * @param {*} objectPropertyValue In each object, what is the property name that contains the value for the dropdown option values.
 * @param {*} objectPropertyName In each object, what is the property name that contains the value for the dropdown option name
 *  @param {*} objectPropertyKey In each object, what is the property name that contains the key for the dropdown option
 * @returns {array} Returns an array of objects in which the array only contains unique values from the "arrayOfObjects"
 */
export const createSemanticDropdownObjects_h = (arrayOfObjects, objectPropertyValue, objectPropertyName, objectPropertyKey = null, includeKeyInLabel=false, type=false) => {
    var items = [];
    return arrayOfObjects.reduce((filtered, item, index) => {
        if (items.indexOf(item[objectPropertyValue]) < 0) {
            items.push(item[objectPropertyValue]);
            filtered.push({
                key: objectPropertyKey ? item[objectPropertyKey] : index,
                text: includeKeyInLabel ? `${item[objectPropertyName]} (ID: ${objectPropertyKey ? item[objectPropertyKey] : index}${type ? `, FT: ${item[type]}` : ''})` : item[objectPropertyName] ,
                value: item[objectPropertyValue]
            });
        }
        return filtered;
    }, []);
};

/**
 * Used to help a user find Application DBID's, Table DBID's and Field FID's for their application given a particular connection object.
 * @param {Object} props React component props object
 * @param {Object} props.connectionId Connection object from mongodb database used to obtain necessary data from Quick Base
 * @param {String} props.applicationDbid A particular applicationDbid to use.  IF passed, it defaults "Find Applicaion DBID" input to this value and disables this field.
 */
function PortalDatabaseViewer({connectionId=null, applicationDbid=null, portalOpen, setPortalOpen}) {


    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    //error here is from an API call error when calling connections service
    const { connections } = useSelector(state => state.connections);
    const connectionObject = connections.find(connection=>{
        return connection._id === connectionId;
    });

    //if no connections are present - get the connections on page load
    useEffect(() => {
        if (connections.length === 0) {
            dispatch(actions.getConnections());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /*************************************************
     *  Function Component Vars
     *************************************************/
    const ENUMS = {
        APPID: 'appId',
        TABLEID: 'tableId',
        FIELDID: 'fieldId'
    };

    const generateTableURL = (connection, tableDBID)=>{
        if (connection.auth && connection.auth.realm && tableDBID) {
            return `https://${connection.auth.realm}.quickbase.com/db/${tableDBID}?a=td`;
        }
        return '';
    };

    const generateAppURL = (connection, appDBID)=>{
        if (connection.auth && connection.auth.realm && appDBID) {
            return `https://${connection.auth.realm}.quickbase.com/db/${appDBID}`;
        }
        return '';
    };

    const generateFieldSettingsURL = (connection, tableDBID, fieldFID) => {
        if (connection.auth.realm && fieldFID && tableDBID) {
            return `https://${connection.auth.realm}.quickbase.com/db/${tableDBID}?a=mf&fid=${fieldFID}&chain=1`;
        }
        return '';
    };

    //function to run to get all applications
    const getApplications = ()=>{
        setIsAppLoading(true);
        getQbApps(connectionId).then((res) => {
            var qbAppDropdowns = createSemanticDropdownObjects_h(res.data, "dbid", "dbname", "dbid", true);
            setIsAppLoading(false);
            setIsAppDisabled(false);
            setAppOptions(qbAppDropdowns);
        }).catch((err) => {
            var error = new ApiError_h(err);
            var errorText = error.getUserMessage();
            setErrorMsg(errorText);
            setIsAppLoading(false);
        });
    };

    // function to run to get all tables
    const getTables = ()=>{
        setIsTableLoading(true);
        setTableValue('');
        if (isTableDisabled) {
            setIsTableDisabled(false);
        }
        getQbTables(connectionId, appValue).then((res) => {
            var qbTableDropdowns = createSemanticDropdownObjects_h(res.data, "id", "name", "id", true);
            setTableOptions(qbTableDropdowns);
            setIsTableLoading(false);
        }).catch((err) => {
            var error = new ApiError_h(err);
            var errorText = error.getUserMessage();
            setErrorMsg(errorText);
            setIsTableLoading(false);
        });
    };

    //function to run to get Quick Base Fields
    const getFields = ()=>{
        setIsFieldLoading(true);
        setFieldValue('');
        if (isFieldDisabled) {
            setIsFieldDisabled(false);
        }
        getQbFields(connectionId, tableValue).then((res) => {
            var qbFieldDropdowns = createSemanticDropdownObjects_h(res.data, "id", "label", "id", true, 'fieldType');
            setFieldOptions(qbFieldDropdowns);
            setIsFieldLoading(false);
        }).catch((err) => {
            var error = new ApiError_h(err);
            var errorText = error.getUserMessage();
            setErrorMsg(errorText);
            setIsFieldLoading(false);
        });
    };

    


    /*************************************************
     *  State
     *************************************************/
    //commented this out as it does not seem to be used anywhere
    // var [portalOpen, setPortalOpen]           = useState(true);

    var [isAppLoading, setIsAppLoading]       = useState(false);
    var [isTableLoading, setIsTableLoading]   = useState(false);
    var [isFieldLoading, setIsFieldLoading]   = useState(false);

    var [isAppDisabled, setIsAppDisabled]     = useState(false);
    var [isTableDisabled, setIsTableDisabled] = useState(true);
    var [isFieldDisabled, setIsFieldDisabled] = useState(true);

    var [appOptions, setAppOptions]           = useState([]);
    var [tableOptions, setTableOptions]       = useState([]);
    var [fieldOptions, setFieldOptions]       = useState([]);

    var [appValue, setAppValue]               = useState('');
    var [tableValue, setTableValue]           = useState('');
    var [fieldValue, setFieldValue]           = useState('');


    var [errorMsg, setErrorMsg]               = useState('');




    /*************************************************
     *  Handlers
     *************************************************/
    //handles all input changes
    const handleInputChange = (inputName)=>{
        return function(e, data){
            var value = data.value;
            switch (inputName) {
                case ENUMS.APPID:
                    setAppValue(value);
                    break;
                case ENUMS.TABLEID:
                    setTableValue(value);
                    break;
                case ENUMS.FIELDID:
                    setFieldValue(value);
                    break;
                default:
                    break;
            }
        };
    };

    //hanldes dismissing error message 
    const handleDismissErrorMessage = ()=>{
        setErrorMsg('');
    };

    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        getApplications();
    },[]);

    useEffect(()=>{
        if(appValue){
            getTables();
        } else {
            setTableValue('');
            setIsTableDisabled(true);
        }
    }, [appValue]);

    useEffect(()=>{
        if (tableValue) {
            getFields();
        } else {
            setFieldValue('');
            setIsFieldDisabled(true);
        }
    }, [tableValue])
    


    return (
        <>
            {/* Portal For Finding App DBID's, Field DBID's */}
            <Portal open={portalOpen}>
                <Segment
                    raised
                    inverted
                    style={{
                        backgroundColor: "#043C43",
                        margin:'15px',
                        bottom: '10px',
                        right: '20px',
                        left: '20px',
                        position: 'fixed',
                        zIndex: 10,
                        border: '3px solid #aa9c5a',
                        borderRadius: '10px'
                    }}
                >
                    <div className={styles["close-icon"]}>
                        <Popup content='Close ID Finder' trigger={<Icon
                            onClick={() => {
                                setPortalOpen(false);
                            }}
                            name="close"
                            size="large"
                        />} />
                    </div>
                    <Grid columns={3} divided centered>
                        <Grid.Row>
                            <Header as="h3" className={styles["header-portal-viewer"]}>Find Quickbase IDs  <Popup
                                content={'This area is to allow users to find Quickbase application DBIDs, table DBIDs and field FIDs very quickly.  The connection you chose in the first step of this process is used to find all Quickbase applications, tables, and fields you have access to.  Use the icons above the dropdowns to copy values to your clipboard and to also launch your Quickbase application to the application, table, or field of interest.  When you select a field in the dropdown, it will automatically copy that value to your clipboard.'}
                                on='click'
                                inverted
                                wide
                                pinned
                                trigger={<span className={styles["help-icon"]}><Icon name="question circle" size="small" /></span>}
                            /></Header>
                        </Grid.Row>
                    </Grid>
                    <Grid columns={3} divided centered stackable>
                        <Grid.Row>
                            <PortalInput 
                                label="Find Application DBID"
                                launchIconPopupTxt="Open this Application in a new window"
                                copyPopupTxt="Copy this Applications DBID to your clipboard"
                                onInputChange={handleInputChange(ENUMS.APPID)}
                                isLoading={isAppLoading}
                                isDisabled={isAppDisabled}
                                placeHolderTxt="Select Application..."
                                value={appValue}
                                options={appOptions}
                                linkURL={connectionObject && generateAppURL(connectionObject, appValue)}
                            />
                            <PortalInput
                                label="Find Table DBID"
                                launchIconPopupTxt="Open this table in a new window"
                                copyPopupTxt="Copy this tables DBID to your clipboard"
                                placeHolderTxt="Select table..."
                                isLoading={isTableLoading}
                                isDisabled={isTableDisabled}
                                onInputChange={handleInputChange(ENUMS.TABLEID)}
                                value={tableValue}
                                options={tableOptions}
                                linkURL={connectionObject && tableValue && generateTableURL(connectionObject, tableValue)}
                            />
                            <PortalInput
                                label="Find Field FID"
                                launchIconPopupTxt="Open this fields settings in a new window"
                                copyPopupTxt="Copy this fields FID to your clipboard"
                                placeHolderTxt="Select field..."
                                isLoading={isFieldLoading}
                                isDisabled={isFieldDisabled}
                                onInputChange={handleInputChange(ENUMS.FIELDID)}
                                value={fieldValue}
                                options={fieldOptions}
                                linkURL={connectionObject && tableValue && fieldValue && generateFieldSettingsURL(connectionObject, tableValue, fieldValue)}
                            />
                        </Grid.Row>
                    </Grid>

                    {errorMsg &&
                        <Message
                            error
                            icon='warning sign'
                            header='Error'
                            content={errorMsg}
                            onDismiss={handleDismissErrorMessage}
                        />
                    }
                </Segment>
            </Portal>
            {/* Portal For Finding App DBID's, Field DBID's */}
        </>
    );

}

export default PortalDatabaseViewer;{}