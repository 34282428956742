import React from 'react';


// Styles
import styles from './index.module.css';

// Components
import {Segment, Icon} from "semantic-ui-react";

/**
 * The child component of the Step Card group.
 * 
 * @param {Object} props Props for this component.
 * @param {number} props.index Required field that show the card # number
 * @param {string} props.content
 * @param {boolean} props.active
 * @returns {*}
 * @constructor
 */
function ConfigStepCard({index = 1, content, active}) {

    let classes = [styles['stepCard']];

    if(active) classes.push(styles['stepCard--active']);

    return <>


        <Segment className={classes.join(" ")}>

            <div className={styles['stepCard--number']}>#{index}</div>

            <span className={styles['stepCard--content']}>
                {content}
            </span>

            <Icon inverted name='arrow circle right' size={"large"} className={styles['stepCard--icon']} />

        </Segment>


    </>

}

export default ConfigStepCard;