/******************************
 *  Reducer for Connections
 ******************************/
import * as types from '../types';
import {ApiError_h} from '../../utils/apiHelpers';


const initialState = {
    isLoading: false,
    connections: [],
    error: ''
};

export default (state = initialState, action) => {
    switch (action.type) {



        /* PATCH REDUCERS */
        case types.PATCH_CONNECTION:
            return {
                ...state,
                isLoading: true
            };
        case types.PATCH_CONNECTION_SUCCESS:
            var updatedConnection = action.payload;
            var connections       = state.connections;

            //find the index of the connection that was updated and replace it.
            var index = connections.findIndex(connection => connection._id === updatedConnection._id);
            connections.splice(index,1,updatedConnection);
            
            return {
                ...state,
                isLoading: false,
                connections:[
                    ...connections,
                ]
            };
        case types.PATCH_CONNECTION_FAILURE:
            var error = new ApiError_h(action.payload);
            var errorMsg = error.getUserMessage();
            return {
                ...state,
                isLoading: false,
                error: errorMsg
            };
        /* PATCH REDUCERS */


        /* CREATE REDUCERS */
        case types.CREATE_CONNECTION:
            return {
                ...state,
                isLoading: true
            };
        case types.CREATE_CONNECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                connections: [...state.connections, action.payload]
            };
        case types.CREATE_CONNECTION_FAILURE:
            var errorFail = new ApiError_h(action.payload);
            var errorMsgFail = errorFail.getUserMessage();
            return {
                ...state,
                isLoading: false,
                error: errorMsgFail
            };
        case types.CLEAR_CONNECTION_ERROR:
            return {
                ...state,
                error: ''
            };
        /* CREATE CASES */




        /* GET CONNECTIONS REDUCERS */
        case types.GET_CONNECTIONS:
            return {
                ...state,
                isLoading: true
            };
        case types.GET_CONNECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                connections: [...state.connections, ...action.payload]
            };
        case types.GET_CONNECTION_FAILURE:
            var connectionError = new ApiError_h(action.payload).getMessage();
            return {
                ...state,
                error: connectionError
            };
        /* GET CONNECTIONS REDUCERS */


        /* REMOVE CONNECTIONS REDUCERS */
        case types.REMOVE_CONNECTION:
            return {
                ...state,
                isLoading: true
            };
        case types.REMOVE_CONNECTION_SUCCESS:
            var deletedConnection = action.payload;
            var connectionsArray  = state.connections;
            //find the index of the connection that was updated and replace it.
            var i = connectionsArray.findIndex(connection => connection._id === deletedConnection._id);
            connectionsArray.splice(i, 1);
            
            return {
                ...state,
                isLoading: false,
                error: '',
                connections: [...connectionsArray]
            };
        case types.REMOVE_CONNECTION_FAILURE:
            var err = new ApiError_h(action.payload).getMessage();
            return {
                ...state,
                error: err
            };
        /* REMOVE CONNECTIONS REDUCERS */

        

        default:
            return state;
    }
};