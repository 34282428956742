/** 
* Contains a set of API helpers for parsing API responses for data etc.
*/





/**
 * Connection object to send to the database.
 * @param {String} title The name/title of the connection
 * @param {string} applicationId The application id for this connection
 * @param {string} userToken User token for this connection
 * @param {string} realm The realm of the connection in Quick Base
 * @param {string} userId The id of the user creating the connection
 * @param {string} description Optional description
 * @returns {Object} Returns correctly structured object for a connection entered into the database
 */
export function createConnectionObject(title, applicationId, userToken, realm, userId, description="") {
    return {
        type: "Quick Base",
        title,
        user_id: userId,
        description,
        auth: {
            userToken,
            applicationId,
            realm
        }
    };
};


/**
 * Useful methods for extracting information from Feathers.js error objects
 * @class ApiError_h
 */
export class ApiError_h {

    /**
     *Creates an instance of ApiError_h.
     * @param {Object} apiErrorObject The error Object from the API.
     */
    constructor( apiErrorObject ) {
        this.errorObject = apiErrorObject;
        
        if ( apiErrorObject.name ) this.name = apiErrorObject.name;
        if (apiErrorObject.message) this.message = apiErrorObject.message;
        if (apiErrorObject.code) this.statusCode = apiErrorObject.code;
        if (apiErrorObject.stack) this.stackTrace = apiErrorObject.stack;
    }


    /**
     * Get the name of the error
     * @returns {string} The name of the error.
     * @memberof ApiError_h
     */
    getName() {
        return this.name;
    }

    /**
     * Get the message of the error
     * @returns {string} The message of the error.
     * @memberof ApiError_h
     */
    getMessage() {
        return this.message;
    }
    
    /**
     * Generates more readable error messages for the end user based on certain matching error types from the API.
     * @returns {string} User friendly error message
     * @memberof ApiError_h
     */
    getUserMessage() {
        if( this.errorObject.name === "Conflict" ) {
            return "This email is already in use.";
        } else if (!this.message) {
            //if a user isn't verified the server responds with a string and not an object.
            return this.errorObject;
        } else if (this.errorObject.message.indexOf("Cast to ObjectId failed") > -1) {
            return `Unable to find item (${this.errorObject.message}).  If the problem persists, please contact support with a copy this error message for details.`;
        } else {
            return `${this.message}  If the problem persists, please contact support.`;
        }
    }

    /**
     * Get the status code of the error
     * @returns {string} The status code of the error.
     * @memberof ApiError_h
     */
    getCode() {
        return this.statusCode;
    }

    /**
     * Get the stack trace of the error
     * @returns {string} The stack trace of the error.
     * @memberof ApiError_h
     */
    getStackTrace() {
        return this.stackTrace;
    }

    /**
     * Logs the entire error object for debugging
     */
    logConsoleError() {
        console.log(this.errorObject);
    }

};