/******************************
 *  Reducer for All Application Modals - TODO: combine all modals into one.
 ******************************/
import * as types from '../types';

const initialState = {
    createConnectionIsOpen: false,
    deleteConnectionIsOpen: false,
    modalState: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CREATE_CONNECTION_MODAL:
            const isOpen = action.payload;
            return {
                ...state,
                createConnectionIsOpen: isOpen
            };

        // Generic Modal State.
        case types.SET_MODAL:
            return {
                ...state,
                modalState: action.payload
            };

        default:
            return state;
    }
};