import {Icon, Popup} from "semantic-ui-react";
import React from "react";

// Styles
import styles from './index.module.css';

/**
 * Return a popup that show help topic when mouse-hover
 * @param {Object} props Component props
 * @param {string} props.help The help content.
 * @param {Boolean} props.inverted Reverses background color to black for popup.
 * @returns {*}
 * @constructor
 */
function HelpPopup({help = null, inverted=false}) {

    return <span className={styles['label--help']}>
            {help && <Popup inverted={inverted} content={help} trigger={
                <div className={styles['label--help-icon-wrapper']}>
                    <Icon name={'info circle'}/>
                </div>
            }/>}
        </span>;
}

export default HelpPopup;