import React from "react";

// Components
import {Icon, Popup} from "semantic-ui-react";


// Styles
import styles from "./index.module.css";

/**
 * Documentation links (video and user manual)
 * @param {Object} props Component props
 * @param {string} props.userManualUrl URL to download the user manual
 * @param {string} props.videoUrl Video url
 * @param {string} props.videos A list of videos to show in a modal
 * @param {Function} props.onShowVideoClick If passed, will allow the user to create custom functionality when clicking on the video. 
 * @param {string} props.documentLabel If passed, sets a custom label when user hovers over document icon
 * @param {string} props.videoLabel If passed, sets a custom label when user hovers over video icon 
 *
 */
function RelatedDocumentation({ userManualUrl, videoUrl, onShowVideoClick, documentLabel, videoLabel }) {

    const handleIconClick = (url)=>{
        window.open(url, '_blank');
    };

    return <div className={styles['detail-modal--help-assets']}>

        {userManualUrl &&
            <Popup content={documentLabel ? documentLabel : 'Download the User Manual'} trigger={<Icon name={"file pdf"} className={styles['help-icon-pdf']} link
                onClick={() => handleIconClick(userManualUrl)} />} />

        }

        {!onShowVideoClick && videoUrl && 
            <Popup content={videoLabel ? videoLabel : 'View Promo Video'} trigger={<Icon name={"youtube"} color={"red"} className={styles['help-icon-youtube']} link
                onClick={() => handleIconClick(videoUrl)} />} />
        }

        {onShowVideoClick && videoUrl &&
            <Popup content={videoLabel ? videoLabel : 'View Plugin Tutorials'} trigger={<Icon name={"youtube"} color={"red"} className={styles['help-icon-youtube']} link
            onClick={() => onShowVideoClick(videoUrl)} />} />
        }
        
    </div>
}

export  default RelatedDocumentation;