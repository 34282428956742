/**
 * Combines all reducers in this one file
*/
import { combineReducers } from 'redux';

import authReducer from './authReducer';
import userReducer from './userReducer';
import connectionsReducer from './connectionReducer';
import modalsReducer from './modals';
import qbTableReducer from "./qbTableReducer";
import configurationReducer from "./configurationReducer";
import qbFieldReducer from "./qbFieldReducer";
import pluginsReducer from './pluginsReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    connections: connectionsReducer,
    modals: modalsReducer,
    qbTables: qbTableReducer,
    qbFields: qbFieldReducer,
    configuration: configurationReducer,
    plugins: pluginsReducer
});


export default rootReducer;