import {Button, Container, Card, Grid, Input, Loader, Message} from "semantic-ui-react";
import React, { useEffect, useState } from "react";

// Styles
import styles from "./index.module.css";

import { findSubscriptions } from "../../feathers/services/subscriptions";

// Components
import InstalledPluginsCard from "./InstalledPluginsCard/InstalledPluginsCard";
import {Link} from "react-router-dom";
import SectionGroup from "../SectionGroup/SectionGroup";

import { debounce_h } from "../../utils/formHelpers";
import { searchStringForMatch_h } from '../../utils/filterSortHelpers';
import VideoTutorialPlayer from "../VideoTutorialPlayer/VideoTutorialPlayer";
import PluginButtonInstall from "../PluginButtonInstall/PluginButtonInstall";
import Account from "../Account/Account";
import HomePageMsg from "../HomePageMsg/HomePageMsg";


/**
 * Plugin Cards for brief about the plugin.
 * @param {Object} props Component props
 * @param {string} props.title Title of the plugin
 * @param {string} props.pluginType Default to Quick Base Plugin
 * @returns {*}
 * @constructor
 */
function MyInstalledPlugins({title, pluginType = "Quickbase Plugin"}) {

    // Get the window width to calculate the number of fileds to be shown in a row.
    // const { screenWidth } = useWindowDimensions();

    /*************************************************
     *  State
     *************************************************/
    var [subscriptions, setSubscriptions]                         = useState([]);
    var [errorMessage, setErrorMessage]                           = useState("");
    var [isLoading, setIsLoading]                                 = useState(true);
    var [searchTerm, setSearchTerm]                               = useState('');
    var [selectedVideoPlugin, setSelectedVideoPlugin]             = useState([]);
    var [videoOpen, setVideoOpen]                                 = useState(false);
    var [buttonInstallSubscription, setButtonInstallSubscription] = useState(null);
    var [pluginInstallPopupOpen, setPluginInstallPopupOpen]       = useState(false);

    /*************************************************
     *  Effects
     *************************************************/
    useEffect(() => {
        findSubscriptions({ 'isActive': true }).then((res) => {
            setSubscriptions(res.data);
            setIsLoading(false);
        }).catch((err) => {
            setErrorMessage("There was an error and your plugins could not be displayed.  Try refreshing this window.  If this continues to occur, please contact support.  Thank you.");
            setIsLoading(false);
        });
    }, []);
    



    /*************************************************
     *  Handleers
     *************************************************/

    const handleSearchChange = debounce_h((e, data) => {
        setSearchTerm(data.value);
    }, 300, false);

    const handleShowVideoClick = (plugin)=>{
        setSelectedVideoPlugin(plugin.documentation.videoTutorials);
        setVideoOpen(true);
    };

    const handleOpenVideo = (bool)=>{
        setVideoOpen(bool);
    };

    const handleShowButtonInstallClick = (subscription)=>{
        setButtonInstallSubscription(subscription);
        setPluginInstallPopupOpen(true);
    };

    /**
     * Handles opening/closing install plugin button modal.
     * @param {boolean} bool Determines whether or not the install plugin button modal is open/closed
     */
    const handleButtonInstallOpenClose = (bool) => {
        setPluginInstallPopupOpen(bool);
    };



    return (
        <Account pageTitle={"My Pluggins"} helpPopup={"This part of your account shows all of the plugins you currently have access to."}>


            <SectionGroup
                title="My Plugins"
                description="Your current plugins are shown below.  Click the video icons below to view tutorials for a plugin, click the pdf icon to download the user manual for a plugin, and the menu icon allows you to interact with this particular plugin."
            >
                    <Grid>

                        <Grid.Row>

                            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
                                <Container>

                                    {/* Modal For Plugin Button Installs  */}
                                    {buttonInstallSubscription &&
                                        <PluginButtonInstall
                                            subscriptionId={buttonInstallSubscription ? buttonInstallSubscription._id : null}
                                            isOpen={pluginInstallPopupOpen}
                                            onOpenClose={handleButtonInstallOpenClose}
                                        />
                                    }
                                    {/* Modal For Plugin Button Installs  */}

                                    {/* search bar */}
                                    <section className={styles['filter--wrapper']}>
                                        <div className={styles['config--search']}>
                                            <label>SEARCH: </label>
                                            <Input
                                                icon={{ name: 'search', circular: true, link: true }}
                                                placeholder='Enter search term...'
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                        {/*Add new Connection modal trigger from there*/}
                                        <Button icon={"paper plane"} primary content={"Get a New Plugin!"} as={Link} to={"/marketplace/all-plugins"} />
                                    </section>
                                    {/* search bar */}



                                    {/* Loading Gif */}
                                    {isLoading && !errorMessage &&
                                        <div className={styles['margin-top']}>
                                            <Loader
                                                active
                                                inline='centered'
                                                size='large'
                                            >
                                                <span>Loading Subscriptions...</span>
                                            </Loader>
                                        </div>
                                    }
                                    {/* Loading Gif */}



                                    {/* cards */}
                                    {!isLoading && !errorMessage &&
                                        <div>
                                            <Card.Group centered={true}>
                                                {subscriptions.filter(subscription => searchStringForMatch_h(`${subscription.name} ${subscription.plugin.name}`, searchTerm)).map(subscription =>
                                                    <InstalledPluginsCard
                                                        subscription={subscription}
                                                        key={subscription._id}
                                                        onShowVideoClick={() => handleShowVideoClick(subscription.plugin)}
                                                        onShowButtonInstallClick={handleShowButtonInstallClick}
                                                    />
                                                )}
                                            </Card.Group>
                                        </div>
                                    }
                                    {/* cards */}


                                    {/* {!isLoading && subscriptions.length < 1 && !errorMessage &&
                                        <Grid centered>
                                            <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={8} widescreen={8}>
                                                <Message color="teal">
                                                    <Message.Header>You Need Plugins!</Message.Header>
                                                    <p>Looks like you currently have no plugins associated with this account.  Click the "Get a New Plugin" button above to get your first one!</p>
                                                </Message>
                                            </Grid.Column>
                                        </Grid>
                                    } */}

                                    {!isLoading && subscriptions.length < 1 && !errorMessage &&
                                        <>
                                            <HomePageMsg
                                                iconSize="huge"
                                                iconName="alarm"
                                                msgText='Looks like you currently have no plugins associated with this account.  Click the " Get a New Plugin" button above to get your first one!'
                                            />
                                            <div className="mb-medium" />
                                        </>
                                       
                                    }

                                    {!isLoading && errorMessage &&
                                        <Grid centered>
                                            <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={8} widescreen={8}>
                                                <Message error>
                                                    <Message.Header>Error</Message.Header>
                                                    <p>{errorMessage}</p>
                                                </Message>
                                            </Grid.Column>
                                        </Grid>
                                    }

                                    <VideoTutorialPlayer
                                        open={videoOpen}
                                        openHandler={handleOpenVideo}
                                        videos={selectedVideoPlugin}
                                    />

                                </Container>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
            </SectionGroup>
        </Account>
        
    );

}

export default MyInstalledPlugins;