import React from 'react';

//semantic
import {
    Container, Grid, Button, Icon,
} from 'semantic-ui-react';


//styles
import styles from './index.module.css';
import useWindowDimensions from "../../utils/useWindowDimentions";
import {Link} from "react-router-dom";


// Sub components

/**
 * Common Thank-you page after general form submission
 * @param {Object} props Component props
 * @param {string} props.content Some content that greets.
 * @param {string} props.redirect Route link to any component
 * @returns {*}
 * @constructor
 */
function ThankYou({content, redirect = null}) {

    const {screenWidth} = useWindowDimensions();

    return (
        <>

            <Container>

                <Grid centered>

                    <Grid.Row>

                        {/*Main Map form*/}
                        <Grid.Column width={screenWidth > 786 ? 8 : 16}>

                            <div className={styles["wrapper"]}>

                                <div className={"mb-large"}/>

                                <Icon size={"huge"} name={"check circle"} color={"teal"}/>

                                <h2 className={styles["title"]}>Thank You!</h2>

                                <p className={styles["hero-text"]}>
                                    {content}
                                </p>

                                <div className={"mb-medium"}/>

                                { redirect && <>
                                    <Button primary content={"Back"} as={Link} to={redirect} />
                                    <div className={"mb-medium"}/>
                                </>}


                            </div>

                        </Grid.Column>

                    </Grid.Row>

                </Grid>

            </Container>

        </>
    );

}

export default ThankYou;