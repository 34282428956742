import React from "react";
import {useHistory} from 'react-router-dom';

//Components
import {Button, Card, GridColumn, Image, Popup} from "semantic-ui-react";


//styles
import styles from './index.module.css';


//Images
import quickbaseIcon from "./quickbase-icon.svg";


/**
 * Connection object from the database.
 * @typedef {Object} connection
 * @property {String} title The title of the connection
 * @property {String} description The description of the connection
 * @property {String} _id The db id of the connection
 * @property {String} realm The realm of the connection
 */

/**
 * Connection Item Card Child Component. Individual card.
 * @param {connection} connection The connection object in question
 * @param {String} configId configuration ID in question - only applicable for edit.
 * @param {String} pluginId plugin ID in question - only applicable for new configs 
 * @param {Boolean} userIsEditingConfig Determines whether user is editing an existing configuration, or if they are creating a new one.  Tells the script where to redirect to after clicking on a connection.
 * @return {*} Component
 * @constructor
 */
function ConnectionItem({ connection,configId = null, userIsEditingConfig=false, pluginId=null}) {

    /*************************************************
     *  Gen Settings
     *************************************************/
    const {title, description, _id, auth} = connection;
    const history                          = useHistory();



    /*************************************************
     *  Handlers
     *************************************************/
    const handleClick = (e)=>{
        if (!userIsEditingConfig) {
            return history.push(`/map/config/connection/${_id}/plugin/${pluginId}`);
        } else {
            return history.push(`/edit/map/config/${configId}/connection/${_id}`);
        }
    };


    /*************************************************
     *  Render
     *************************************************/
    return (
        <GridColumn mobile={16} tablet={8} computer={5} largeScreen={5} widescreen={4} className={"hybrid"}>
            <Card className={styles['card']}>
                <Card.Content>
                    <div className={styles['card--header-wrapper']}>
                        <Image
                            size='mini'
                            src={quickbaseIcon}
                        />
                        <Popup 
                            content={title}
                            disabled={title.length < 23}
                            inverted
                            trigger={
                                <h3
                                    className={styles['config--card-header']}>
                                    {title}
                                </h3>} 
                            />
                        
                    </div>
                    <Card.Meta>{auth.realm}.quickbase.com</Card.Meta>

                    <Card.Description>
                        {description}
                    </Card.Description>

                </Card.Content>

                <Card.Content extra>
                    <div className='ui two buttons'>
                        <Button
                            basic
                            color='teal'
                            onClick={handleClick}
                            title={"Use This Connection"}
                        >
                            Use This Connection
                        </Button>
                    </div>
                </Card.Content>
            </Card>
        </GridColumn>
    );

}

export default ConnectionItem;