/**
 * Connections API calls
 */

import client from '../index';

/**
 * TODO: document this function
 * @param connection
 * @param table
 * @return {*}
 */
export function getQbFields(connection, table) {

    /**
     * Return array of objects
     * Sample response:
     [ {
            "id": 6,
            "fieldType": "text",
            "label": "Note Summary",
            "nowrap": false,
            "bold": false,
            "required": false,
            "appearsByDefault": true,
            "findEnabled": true,
            "unique": false,
            "doesDataCopy": true,
            "audited": false,
            "properties": {
                "foreignKey": false,
                "allowNewChoices": false,
                "sortAsGiven": true,
                "carryChoices": true,
                "numLines": "6",
                "maxLength": "0",
                "appendOnly": false,
                "allowHTML": false,
                "width": "60"
            }
        },
     ]
     */

    return client.service('qb-fields').find({
        query: {
            connection,
            table,
        }
    });
}


/**
 * Create api calls for creating new field
 * @param payload
 * @param payload.connectionId
 * @param payload.tableId
 * @param payload.fieldName
 * @param payload.fieldType
 * @return {*}
 */
export function createQbFields({connectionId, tableId, fieldName, fieldType}) {
    return client.service('qb-fields').create({
        table: tableId,
        fieldName: fieldName,
        fieldType: fieldType
    }, {
        query: {
            connection: connectionId,
        }
    });
}
