import React from "react";
import {GridColumn} from "semantic-ui-react";

//Styles

import styles from "./index.module.css";
// Components
import YoutubeVideoTutorial from "../HelpTopics/YoutubeVideoTutorial";

/**
 * Sidebar that contains help video for configuration process
 * @param {Object} props React component props object
 * @param {String} props.helpText Help text to render
 * @param {String} props.youtubeVideoId
 */
function Sidebar({ helpText, youtubeVideoId}) {
    return (
        <GridColumn
            mobile={16} tablet={16} computer={16} largeScreen={4} widescreen={4}
            className={[styles.sidebar, "hybrid"].join(" ")}>

            {/*Help Video on sidebar*/}
            <h3 className={styles['sidebar--header']}>Need Help?</h3>


            {/*Help Video on sidebar*/}
            <YoutubeVideoTutorial 
                videoId={youtubeVideoId}
                text={helpText}
            />
        </GridColumn>
    );
}

export default Sidebar;