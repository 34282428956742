import React, { useEffect, useState } from 'react';


//semantic
import { 
    Grid, 
    Message, 
    Table, 
    Button, 
    TableBody, 
    Loader, 
    Icon, 
    Popup, 
    Form, 
    Segment, 
    Transition 
} from 'semantic-ui-react';

import { useParams } from 'react-router-dom';

//react hook form
import { useForm } from 'react-hook-form';

//utils
import { generateFormErrorsArray_h, isFormError_h } from "../../../utils/formHelpers";
import { ApiError_h } from "../../../utils/apiHelpers";

import useCopyToClipboard from "../../../utils/useCopyToClipboard";


//api
import { findTokens, removeToken, createToken } from "../../../feathers/services/tokens";

//styles
import styles from './index.module.css';


import DeleteModal from "../../DeleteModal/DeleteModal";






function Subscriptions() {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [isCopied, copyToClipBoard] = useCopyToClipboard();


    /*************************************************
     *  Query Parameters
     *************************************************/
    // URL Query Parameters
    const params = useParams();
    // Get the configuration id from the route
    const { configuration } = params;


    /*************************************************
    *  State
    *************************************************/
    const [error, setError]                               = useState([]);
    const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false);
    const [tokenToDelete, setTokenToDelete]               = useState(null);
    const [isLoading, setIsLoading]                       = useState(true);
    const [tokens, setTokens]                             = useState([]);
    //object that controls whether a given tokens "Copied" text shows.  Object with keys of tokens.{tokenId:boolean}
    const [isVisible, setIsVisible]                       = useState({});
    const [copyConfigVisible, setCopyConfigVisible]       = useState(false);

    

    /*************************************************
    *  Effects
    *************************************************/
    useEffect(() => {
        //get all tokens for a particular subscription 
        findTokens( configuration ).then((res) => {
            //handle setting copy visible/not visible settings
            var visibleObject = {};

            res.data.forEach((token) => {
                visibleObject[token._id] = false;
            });
            setIsVisible(visibleObject);

            //initilize tokens
            setTokens(res.data);
            
            //hide loading gif
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            var apiError = new ApiError_h(err);
            setError([apiError.getUserMessage()]);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /*************************************************
    *  React Hook Form
    *************************************************/
    var { register, handleSubmit, errors, setValue } = useForm();


    /*************************************************
    *  Handlers
    *************************************************/
    const onSubmit = async token => {
        token.configuration_id = configuration;
        createToken( token ).then((newToken)=>{
            setValue('name', '');
            setIsVisible({
                ...isVisible, 
                [newToken._id]: false});
            setTokens([...tokens, newToken])
        }).catch((err)=>{
            var apiError = new ApiError_h(err);
            setError([apiError.getUserMessage()]);
        });
    };

    const handleModalCancelButton = ()=>{
        setIsDeleteModalShowing(false);
    };

    const handleTokenDeleteConfirmation = ()=>{
        setError([]);
        setIsDeleteModalShowing(false);

        removeToken( tokenToDelete ).then((res)=>{
            setTokens(tokens.filter(token=>token._id!==tokenToDelete._id));
        }).catch((err)=>{
            var apiError = new ApiError_h(err);
            setError([apiError.getUserMessage()]);
        });
    };

    //handles copying a token
    const handleCopy = ( token )=>{
        copyToClipBoard(token.token);
        setIsVisible({
            ...isVisible,
            [token._id]:true
        });
        setTimeout(()=>{
            setIsVisible({
                ...isVisible,
                [token._id]: false
            });
        }, 1500);
    };

    //handles copying configurationID
    const handleCopyConfig = ( configuration )=>{
        copyToClipBoard(configuration);
        setCopyConfigVisible(true);
        setTimeout(() => {
            setCopyConfigVisible(false);
        }, 1500);
    };



    return (
        <>
            <Grid
                centered
            >
                <Grid.Row>
                    <Grid.Column 
                        mobile={16} 
                        tablet={16} 
                        computer={14} 
                        largeScreen={12} 
                        widescreen={10}
                    >
                        <div className={styles["token--wrapper"]}>

                            <h3>TOKEN MANAGEMENT</h3>
                            
                            {/* Loading Gif */}
                            {isLoading && 
                                <div className={styles['margin-top']}>
                                    <Loader active inline='centered' size='large'><span>Loading Tokens...</span></Loader>
                                </div> 
                            }
                            {/* Loading Gif */}

                            
                            {/* main content */}
                            {!isLoading &&
                                <>
                                    <p>Below are your tokens for this plugin and your Configuration ID.  You can view, delete and create new tokens below for this plugin.  Specific plugins will require you to utilize these for authentication.  Treat these as <strong>passwords</strong> for your plugin and handle them with care.</p>
                                    <p>
                                        <br />
                                        Configuration ID:<span className={styles["configuration-copy-icon"]}>{configuration}</span>
                                        <Popup
                                            content='Copy Token'
                                            trigger={
                                                <>
                                                    <Icon
                                                        className={styles["copy--icon"]} name="copy"
                                                        size="large"
                                                        color="teal"
                                                        onClick={event => handleCopyConfig(configuration)}
                                                    />
                                                    <Transition
                                                        visible={copyConfigVisible}
                                                        animation='scale'
                                                        duration={500}
                                                    >
                                                        <span>
                                                            <span className={styles["copy--icon"]}>Copied</span> &nbsp; &nbsp;
                                                                                    <Icon
                                                                name="check" size="large" color="teal"
                                                            />
                                                        </span>

                                                    </Transition>
                                                </>
                                            }
                                        />
                                    </p>
                                    <br />
                                    <Segment>


                                        <Message
                                            hidden={!error.length > 0}
                                            negative
                                            error
                                            header='Error:'
                                            list={generateFormErrorsArray_h(errors).concat(error)}
                                        />

                                        <Form
                                            onSubmit={handleSubmit(onSubmit)}
                                            error={error.length > 0}
                                        >
                                            <Grid
                                                verticalAlign="bottom"
                                            >
                                                <Grid.Column
                                                    mobile={16}
                                                    tablet={14}
                                                    computer={6}
                                                    largeScreen={6}
                                                    widescreen={4}
                                                >
                                                    <Form.Field
                                                        error={isFormError_h(errors.name)}
                                                    >
                                                        <label>
                                                            Enter New Token Name
                                                    </label>
                                                        <input
                                                            placeholder='Enter token name...'
                                                            type='text'
                                                            name="name"
                                                            ref={register({ required: true })}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column
                                                    mobile={16}
                                                    tablet={16}
                                                    computer={6}
                                                    largeScreen={6}
                                                    widescreen={6}
                                                >
                                                    <Button
                                                        type="submit"
                                                        icon={"add"}
                                                        primary
                                                        content={"Create New Token"}
                                                    />
                                                </Grid.Column>
                                            </Grid>
                                        </Form>

                                        {/* Main Table Content */}
                                        <div className={styles["margin-top"]} />
                                        <Table basic='very'>

                                            {tokens && tokens.length > 0 &&
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                                        <Table.HeaderCell>Token</Table.HeaderCell>
                                                        <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                            }
                                            
                                            
                                            <TableBody>
                                                {tokens && tokens.map( token =>
                                                    <Table.Row key={token._id}>
                                                        <Table.Cell>{token.name}</Table.Cell>
                                                        <Table.Cell>
                                                            {token.token} 
                                                            &nbsp; &nbsp;
                                                            
                                                            <Popup 
                                                                content='Copy Token' 
                                                                trigger={
                                                                    <>
                                                                        <Icon
                                                                            className={styles["copy--icon"]} name="copy"
                                                                            size="large"
                                                                            color="teal"
                                                                            onClick={event => handleCopy(token)}
                                                                        />
                                                                        <Transition
                                                                            visible={isVisible[token._id]}
                                                                            animation='scale'
                                                                            duration={500}
                                                                        >
                                                                            <span>
                                                                                <span className={styles["copy--icon"]}>Copied</span> &nbsp; &nbsp;
                                                                                <Icon 
                                                                                    name="check" size="large" color="teal"
                                                                                />
                                                                            </span>
                                                                            
                                                                        </Transition>
                                                                    </>
                                                                } 
                                                            />
                                            
                                                        </Table.Cell>
                                                        <Table.Cell className={styles['table-action']}>
                                                            {/*Add new Connection modal trigger from there*/}
                                                            <div className={styles['table-action--buttons']}>
                                                                <Button
                                                                    icon={"delete"}
                                                                    className={styles['token--delete']}
                                                                    onClick={event => {setIsDeleteModalShowing(true); setTokenToDelete(token);}}
                                                                />
                                                            </div>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </TableBody>
                                        </Table>
                                        {/* Main Table Content */}

                                        {tokenToDelete &&
                                            <DeleteModal
                                                confirmButtonText="Delete"
                                                modalContent={`Please confirm you want to delete the token "${tokenToDelete.name}"`}
                                                modalHeader="Delete Token"
                                                open={ isDeleteModalShowing }
                                                onCancel={handleModalCancelButton}
                                                onConfirm={handleTokenDeleteConfirmation}
                                            />
                                        }

                                    </Segment>
                                </>
                            }
                            {/* main content */}

                            <div className={styles["footer--spacing"]}></div>

                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );

}

export default Subscriptions;