import React, {useState, useEffect} from 'react';

//semantic
import {Modal, Button, Grid, Embed, Sidebar, Segment, Menu} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';

/**
 * Video Object
 * @typedef {Object} Video
 * @property {String} id The youtube video id of this paritcular video.
 * @property {String} placeholder The default image placeholder for this particular video.
 * @property {String} title The title of this particular video.
 */

// const videoContent = [
//     {
//         id: 'yrNsRyek_us',
//         placeholder: 'https://mcftech.quickbase.com/up/bdvzhwu8b/g/rcdc/eg/va/Gantt4.png',
//         title: 'Key and Peel'
//     }
// ];

/**
 * Modal that showcases a list of videos that can be played.
 * @param {Object} props React component props object
 * @param {Video} props.videos An array of video objects
 * @param {Boolean} props.open Is the modal open/closed.
 * @param {String} props.modalSize Default to Large.  One of small, medium, large, fullscreen.
 * @param {Boolean} props.autoplay Set to true will autoplay videos.
 * @param {Function} props.openHandler This runs when the modal is open/closed.  Pass a function that updates the "open" prop.  For instance when this is opened, the openHandler sets "open" to true.  When closed, vice versa.
 */
function VideoTutorialPlayer({ videos, open, openHandler, modalSize="large", autoplay=false}) {

    /*************************************************
     *  State
     *************************************************/
    const [sideBarVisible, setSideBarVisible] = useState(false);
    const [currentVideo, setCurrentVideo]     = useState(null);

    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        if(videos && videos.length > 0) {
            setCurrentVideo(videos[0]);
        } else {
            setCurrentVideo(null);
        }
    }, [videos])


    /*************************************************
     *  Handlers
     *************************************************/
    const handleViewSidebar = ()=>{
        setSideBarVisible(!sideBarVisible);
    };

    const handleItemClick = (video)=>{
        setCurrentVideo(video);
        setSideBarVisible(false);
    };



    return (
        <>
            <Modal
                centered={false}
                size={modalSize}
                open={open}
                closeIcon
                onClose={() => { openHandler(false); setSideBarVisible(false);}}
                onOpen={() => openHandler(true)}
                basic
            >
                <Modal.Header>{currentVideo ? currentVideo.title : ''}</Modal.Header>

                {currentVideo &&
                    <Modal.Content>
                        <Sidebar.Pushable as={Segment}>
                            <Sidebar
                                as={Menu}
                                animation='scale down'
                                inverted
                                vertical
                                visible={sideBarVisible}
                                direction='left'
                            >
                                {videos && videos.map((video, i) =>
                                    <Menu.Item
                                        key={i}
                                        as='a'
                                        onClick={() => handleItemClick(videos[i])}
                                    >
                                        {video.title}
                                    </Menu.Item>
                                )}
                            </Sidebar>

                            <Sidebar.Pusher>
                                <Embed
                                    defaultActive={autoplay}
                                    id={currentVideo.id}
                                    placeholder={currentVideo.placeholder ? currentVideo.placeholder : ''}
                                    source='youtube'
                                    iframe={{
                                        allowFullScreen: true
                                    }}
                                />

                            </Sidebar.Pusher>
                        </Sidebar.Pushable>

                    </Modal.Content>
                }

                {!currentVideo &&
                    <Modal.Header>
                            No video was provided for this player.
                    </Modal.Header>
                }
                

                {videos && videos.length > 1 &&
                    <Modal.Actions>
                        <Grid centered>
                            <Grid.Column mobile={16} tablet={10} computer={6} largeScreen={6} widescreen={6}>
                                <Button
                                    fluid
                                    onClick={handleViewSidebar}
                                    inverted
                                >
                                    View More Tutorials
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Modal.Actions>
                }
                
            </Modal>
        </>
    );

}

export default VideoTutorialPlayer;