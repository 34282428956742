/**  
*   File Purpose: Default configuration for development (NODE_ENV === development)
*/



const configs = {

    API_URL: "http://localhost:3030",
    AUTHORIZE_PUBLIC_KEY: "4K6dhQz94ENzUSWB7FQX6Ks6ZKcJR9q998rAVeqX5sqsRF7eG8xmb93km7Z3FuW5",
    AUTHORIZE_MERCHANT_NAME: "8K34Hgn46",
    //how many ms until an idle timeout is reached
    //1.5 hour timeout
    IDLE_TIMEOUT: 5400000,
    ENVIRONMENT: 'dev'

};


export default configs;