/**
 * Connection
 * @typedef {Object} Connection
 * @property {string} user_id The id of the user who created this connection
 * @property {string} _id ID of the this particular connection
 * @property {string} type type of connection Quick Base | Box etc.
 * @property {string} description user-derived connection description
 * @property {Object} auth authentication information for this connection
 * @property {Object} connectionConfig The configuration for this connection
 * @property {Array<ConnectionConfigField>} connectionConfig.fields Array of connection config fields for this connection
 */


/**
 * Configuration object class to handle configuration specific details.
 */
export default class Configuration {
    /**
    * @param {Object} configuration Database configuration object
    * @param {Object} [plugin] Plugin associated with this configuration
    */
    constructor(configuration, plugin = null) {
        this.configuration = { ...configuration };
        this.plugin = { ...plugin };
    }

    /**
     * 
     * @returns {string} Returns the plugin id associated with this configuration
     */
    getPluginId() {
        return this.configuration.plugin_id;
    }


    /**
     * Obtains all connections - configuration field connections and main quickbase connection. 
     * @return {Array<string>} Returns an array of connection ID's (unique Id's only - no duplicates)
     */
    getAllConfigurationConnections() {
        var configConnections = this.getConfigConnections();
        var mainQuickbaseConnection = this.getMainQuickbaseConnection();
        if (configConnections.indexOf(mainQuickbaseConnection) < 0) {
            configConnections.push(mainQuickbaseConnection);
        }
        return configConnections;
    }


    /**
     * Obtains the main Quickbase configuration ID present for this configuration
     * @returns {string} returns the main quickbase connection for this configuration
     */
    getMainQuickbaseConnection() {
        return this.configuration.connection_id;
    }

    /**
     * Gets any connections present in the configuration LESS the main Quickbase connection
     * @return {Array<string>} returns an array of connection id's
     */
    getConfigConnections() {
        //get all of the config fields that are of type connection
        const { configSections } = this.plugin.configTemplate;
        //houses any connections that need to be returned to the user
        var connections = [];

        if (configSections) {
            configSections.forEach(section => {
                let fields = section.fields;
                let name = section.name;
                fields.forEach(field => {
                    let fieldName = field.name;
                    if (field.isConnection && this.configuration.configMapping[name]) {
                        if (section.repeatable) {
                            this.configuration.configMapping[name].forEach(repeatedSection => {
                                if (repeatedSection[fieldName]) {
                                    connections.push(repeatedSection[fieldName]);
                                }
                            });
                        } else {
                            if (this.configuration.configMapping && this.configuration.configMapping[name][fieldName]) {
                                connections.push(this.configuration.configMapping[name][fieldName]);
                            }
                        }
                    }
                });
            });
        }
        return [...new Set(connections)];;
    }
}