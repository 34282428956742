import React from "react";
import { useFormContext } from "react-hook-form";






function NestedInputContainer({ InputElement, ...rest }) {
    const methods = useFormContext();
    return <InputElement {...methods} {...rest} />;
}
export default NestedInputContainer;
// export default const NestedInputContainer = ({ InputElement }) => {
//     const methods = useFormContext();

//     return <InputElement {...methods} />;
// };