/******************************
 *  Reducer for Authentication Service
 ******************************/
import * as types from '../types';
import { ApiError_h } from '../../utils/apiHelpers';


const initialState = {
    isLoggedIn: false,
    isLoading: true, 
    error: ''
};

export default (state = initialState, action) => {
    switch (action.type) {

        /* LOGIN CASES */
        case types.GET_AUTH_DATA:
            return {
                ...state,
                isLoading: true
            };
        case types.GET_AUTH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true, 
                error: ''
            };
        case types.GET_AUTH_FAILURE:
            const error = new ApiError_h( action.payload );
            var errorMsg = error.getUserMessage();
            return {
                ...state,
                isLoading: false,
                error: errorMsg
            };
        /* LOGIN CASES */

        /* REAUTHENTICATE CASES */
        case types.REAUTHENTICATE_USER_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                isLoading: false
            };
        case types.REAUTHENTICATE_USER_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                isLoading: false
            };
        /* REAUTHENTICATE CASES */


        /* LOG USER OUT CASES */
        case types.LOG_USER_OUT:
            return {
                ...state,
                isLoggedIn: false
            };
        /* LOG USER OUT CASES */




        default:
            return state;
    }
};