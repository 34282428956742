/** 
* Defines all "authentication" store actions 
*/
import * as types from '../types';





/**
 * Log User Out
 */
export const logUserOut_a = ( payload )=>{
    return {
        type: types.LOG_USER_OUT,
        payload
    };
};



 /**
  * These are used to re-authetnicate a user.
  */

//payload contains the reponse from the reauthenticate api call
export const getReAuthenticate = ( payload ) => {
    return {
        type: types.GET_REAUTHENTICATE_DATA,
        payload
    };
};

export const reAuthenticateSuccess = ( payload )=>{
    return {
        type: types.REAUTHENTICATE_USER_SUCCESS, 
        payload
    };
};

export const reAuthenticateFailure = (payload) => {
    return {
        type: types.REAUTHENTICATE_USER_FAILURE,
        payload
    };
};


/**
 * @param {Object} payload consisting of the email and password
 * @returns {object} dispatch object
 */
export const getAuth = ( payload )=>{
    return {
        type: types.GET_AUTH_DATA, 
        payload
    };
};

export const getAuthSuccess = ( payload )=>{
    return {
        type: types.GET_AUTH_SUCCESS,
        payload
    };
};

export const getAuthFailure = ( payload ) => {
    return {
        type: types.GET_AUTH_FAILURE, 
        payload
    };
};

