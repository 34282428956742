import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../../store/actions/connectionActions';
import * as modalActions from '../../../store/actions/modals';

//semantic
import {
    Table, Button
} from 'semantic-ui-react';


//styles
import styles from './index.module.css';

//utils
import { searchStringForMatch_h } from "../../../utils/filterSortHelpers";
import { debounce_h } from "../../../utils/formHelpers";


// Sub components
import SectionGroup from "../../SectionGroup/SectionGroup";
import Account from "../Account";
import ConnectionFilter from "../../ConnectionFilter/ConnectionFilter";
import ConnectionAdd from '../../ConnectionAdd/connectionAddUpdated';
import DeleteModal from '../../DeleteModal/DeleteModal';
import ConnectionSubscriptionsWarning from "../../ConnectionSubscriptionsWarning/ConnectionSubscriptionsWarning";
import HomePageMsg from "../../HomePageMsg/HomePageMsg";


/**
 * Page - List of all the connection that the current logged in user have
 * @returns {*}
 * @constructor
 */
function MyConnections() {

    /*************************************************
     *  State
     *************************************************/
    //takes a connection object that needs to be edited
    const [connectionToEdit, setConnectionToEdit]               = useState(null);
    //controls delete modal state
    const [showDeleteModal, setShowDeleteModal]                 = useState(false);
    //the connection object that will be deleted
    const [connectionToDelete, setConnectionToDelete]           = useState(null);
    //the connection to delete object title for the delte modal
    const [connectionToDeleteTitle, setConnectionToDeleteTitle] = useState('');
    const [searchTerm, setSearchTerm]                           = useState('');
    const [filterValue, setFilterValue]                         = useState('');
    const [connectionType, setConnectionType]                   = useState('Quick Base');




    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    //error here is from an API call error when calling connections service
    const { connections, isLoading } = useSelector(state => state.connections);

    //if no connections are present - get the connections on page load
    useEffect(() => {
        if( connections.length === 0 ) {
            dispatch(actions.getConnections());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    /*************************************************
     *  Handlers
     *************************************************/
    function handleEditConnectionModalClose() {
        setConnectionType("Quick Base");
        setConnectionToEdit(null);
    }

    function handleAddConnectionsClick() {
        setConnectionToEdit(null);
        dispatch(modalActions.setCreateModalState(true));
    }

    function handleEditConnection( connection ) {
        setConnectionType(connection.type);
        setConnectionToEdit( connection );
        dispatch(modalActions.setCreateModalState(true));
    }

    //used for confirming to delete a connection
    function handleOnConfirmDelete() {
        dispatch(actions.removeConnection( connectionToDelete ));
        setShowDeleteModal(false);
    }

    //used for handling when user clicks cancel in delete connection modal
    function  handleOnCancelDelete() {
        setShowDeleteModal(false);
    }

    //handles showing the confirm modal when button is clicked and setting the correct connection to delete
    function handleDeleteOnClick( connection ) {
        setShowDeleteModal(true);
        setConnectionToDelete(connection);
        setConnectionToDeleteTitle(connection.title);
    }


    const handleSearchChange = debounce_h((e, data) => {
        setSearchTerm(data.value);
    }, 100, false);

    const handleFilterChange = debounce_h((value) => {
        setFilterValue(value);
    }, 100, false);
    



    return (
        <>

            <Account pageTitle={"My Connections"} helpPopup={"This part of your account details connections currently set up in your account."}>


                <SectionGroup
                    title="My Connections"
                    description="Your connections are shown below.  Connections are an authentication mechanism to allow your plugins to interact with Quickbase applications.  Connections not associated with Quickbase applications can be created during the configuration process."
                >

                    {/*Search filter*/}
                    <ConnectionFilter
                        onAddConnectionClick={handleAddConnectionsClick}
                        onSearchChange={handleSearchChange}
                        connections={connections}
                        onFilterChange={handleFilterChange}
                    />

                    {/* No Connections Message  */}
                    {connections.length < 1 && !isLoading &&
                        <>
                            <HomePageMsg
                                iconSize="huge"
                                iconName="alarm"
                                msgText='Looks like you currently have no connections associated with this account.  Add a new connection by clicking the "Create New Connections" button above!'
                            />
                            <div className="mb-medium" />
                        </>
                    }
                    {/* No Connections Message  */}



                    { connections.length > 0 &&
                        <Table basic='very'>

                            <Table.Header>

                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>Realm</Table.HeaderCell>
                                    <Table.HeaderCell>Description</Table.HeaderCell>
                                    <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>

                            <Table.Body>

                                {/*Loop individual connections*/}
                                {connections.filter((connection) => {
                                    return searchStringForMatch_h(connection.title, searchTerm) && (filterValue ? filterValue === connection.type : true);
                                }).map(connection =>
                                    <Table.Row key={connection._id}>
                                        <Table.Cell>{connection.title}</Table.Cell>
                                        <Table.Cell><span className={styles['no-wrap--text']}>{connection.type === "Quick Base" ? "Quickbase" : connection.type}</span></Table.Cell>
                                        <Table.Cell>{connection.auth.realm ? connection.auth.realm : "N/A"}</Table.Cell>
                                        <Table.Cell>{connection.description}</Table.Cell>
                                        <Table.Cell className={styles['table-action']}>
                                            {/*Add new Connection modal trigger from there*/}
                                            <Button
                                                icon={"pencil"}
                                                onClick={event => handleEditConnection(connection)} />
                                            <Button
                                                icon={"delete"}
                                                className={styles['connection--delete']}
                                                onClick={event => handleDeleteOnClick(connection)} />
                                        </Table.Cell>
                                    </Table.Row>
                                )}

                            </Table.Body>

                        </Table>
                    }
                        


                </SectionGroup>

                <ConnectionAdd
                    existingconnection={ connectionToEdit }
                    handleModalClose={ handleEditConnectionModalClose }
                    type={connectionType}
                />
                
                <DeleteModal
                    confirmButtonText="Delete"
                    modalContent={`Please confirm you want to delete the connection "${connectionToDeleteTitle}" by clicking the "Delete" button below.`}
                    modalHeader="Delete Connection"
                    open={ showDeleteModal }
                    onCancel={handleOnCancelDelete}
                    onConfirm={handleOnConfirmDelete}
                >
                    <ConnectionSubscriptionsWarning 
                        connection={connectionToDelete}
                        deleteWarning
                    />
                </DeleteModal>

            </Account>

        </>
    );

}

export default MyConnections;