import React from 'react';

//semantic
import {
    Icon,
} from "semantic-ui-react";

import {Link} from "react-router-dom";


//custom components
import Header from '../../components/Header/Header';


//styles
import styles from './index.module.css';


/**
 * This is the main landing page layout with the wave background image when a user first hits the site (homepag).
 * Internal components are passed via props.children to this component.
 */
function LandingPageLayout(props) {

    return (
        <div className={styles["wrapper"]}>

            {/* Header */}
            <Header />
            {/* Header */}


            {/* Main Content */}
            <div className={styles["content-wrapper"]}>
                {props.children}
            </div>
            {/* Main Content */}



            {/* Footer */}
            <footer>
                <div className={styles["new-plugins"]}>
                    <span className={styles["new-plugins__left"]}>New Plugins.</span><span className={styles["new-plugins__right"]}>  Power up your business processes!</span>
                    <div className={styles["new-plugins__logo"]}>
                        <Link to="/marketplace/all-plugins">
                            <Icon className={styles["new-plugins__icon"]} name="arrow circle right" size="big" />
                        </Link>
                    </div>
                </div>

                <div className={styles["copyright"]}>
                    Oasis AppSuite.  All Rights Reserved
                </div>
                <div className={styles["footer-nav"]}>
                    <Link to="/contact"><span className={styles["footer-nav--hover"]}>Contact Us</span></Link> | <Link to="/legal/privacy-policy"><span className={styles["footer-nav--hover"]}>Privacy Policy</span></Link> | <Link to="/legal/terms-of-use"><span className={styles["footer-nav--hover"]}>Terms of Use</span></Link>
                </div>
            </footer>
            {/* Footer */}

        </div>
    );

}

export default LandingPageLayout;