/**
* Defines all user store actions
*/
import * as types from '../types';

//used to set the current user after authentication
export const setUser = ( payload ) => {
    return {
        type: types.SET_USER,
        payload
    };
};

//used to update the users payment profile
export const updatePaymentProfile = ( payload ) => {
    return {
        type: types.UPDATE_USER_PAYMENT_PROFILE,
        payload
    };
};

/**
 * Client side update of free trials after a user has burned one up.  Makes sure the UI stops showing free trial verbiage etc.
 * @param {Object} payload 
 * @param {String} payload.id The id of the plugin
 * @param {String} payload.expiration the expiration of this particular plugin.
 */
export const updateFreeTrials = (payload) => {
    return {
        type: types.UPDATE_USER_FREE_TRIALS,
        payload
    };
};



