/**
 * Houses any form helpers for the application
 */


/**
 * Used with redux form to pass semantic a true/false value to determine whether a specific input should show an error.
 * @param {string} reduxFormErrorText Redux form error text
 */
export function isFormError_h( reduxFormErrorText ) {
    if (reduxFormErrorText) {
        return true;
    } else {
        return false;
    }
};


/**
 * Checks react-hook-form to see if there are errors - returns true if there are.
 * @param {object} reduxFormErrorsObject redux form errors object.
 * @return {boolean} Returns true/false 
 */
export function formHasErrors_h( reduxFormErrorsObject ) {
    if(!reduxFormErrorsObject) return false;

    if(Object.keys(reduxFormErrorsObject).length > 0) {
        return true;
    } else {
        return false;
    }
};

/**
 * Generates an array of error messages for react-hook-form with the errors object returned from react-hook-form
 * @param {object} reduxFormErrorsObject Redux form errors object
 * @return {array} Array of error message strings
 */
export function generateFormErrorsArray_h( reduxFormErrorsObject ) {
    if (!reduxFormErrorsObject) return null;
    const keys = Object.keys( reduxFormErrorsObject );
    const errors = [];

    keys.forEach((key)=>{
        var errortext = `${reduxFormErrorsObject[key].message}`;
        //don't duplicate any keys
        if (reduxFormErrorsObject[key].message.length > 0 && errors.indexOf(errortext) < 0) {
            errors.push(errortext);
        }
    });

    return errors;
}


/**
 * Debounce function to stop user constant updates.
 * @param func {function} - Function to run after debounce
 * @param wait {number} - Wait period 
 * @param immediate  {boolean} - Pass true for the immediate argument to cause debounce to trigger the function on the leading instead of the trailing edge of the wait interval. Useful in circumstances like preventing accidental double-clicks on a "submit" button from firing a second time.
 */
export function debounce_h(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};




/**
 * Creates semantic dropdown option objects by interating over an array of objects and generating a sematnic ui Dropdown object for each unique value found in the array of objects.
 * @param {arrayOfObjects} arrayOfObjects This is an array of objects.  Each object has a property that will be a value in the dropdown options.
 * @param {*} objectPropertyValue In each object, what is the property name that contains the value for the dropdown options.
 * @returns {array} Returns an array of objects in which the array only contains unique values from the "arrayOfObjects"
 */
export const createSemanticDropdownObjects = (arrayOfObjects, objectPropertyValue) => {
    var authors = [];
    return arrayOfObjects.reduce((filtered, item, index) => {
        if (authors.indexOf(item[objectPropertyValue]) < 0) {
            authors.push(item[objectPropertyValue]);
            filtered.push({
                key: index,
                text: item[objectPropertyValue],
                value: item[objectPropertyValue]
            });
        }
        return filtered;
    }, []);
};


/**
 * Creates semantic dropdown option objects by interating over an array of objects and generating a sematnic ui Dropdown object for each unique value found in the array of objects.
 * @param {arrayOfObjects} arrayOfObjects This is an array of objects.  Each object has a property that will be a value in the dropdown options.
 * @param {*} objectPropertyValue In each object, what is the property name that contains the value for the dropdown option values.
 * @param {*} objectPropertyName In each object, what is the property name that contains the value for the dropdown option name
 *  @param {*} objectPropertyKey In each object, what is the property name that contains the key for the dropdown option
 * @returns {array} Returns an array of objects in which the array only contains unique values from the "arrayOfObjects"
 */
export const createSemanticDropdownObjects_h = (arrayOfObjects, objectPropertyValue, objectPropertyName, objectPropertyKey = null) => {
    var items = [];
    return arrayOfObjects.reduce((filtered, item, index) => {
        if (items.indexOf(item[objectPropertyValue]) < 0) {
            items.push(item[objectPropertyValue]);
            filtered.push({
                key: objectPropertyKey ? item[objectPropertyKey] : index,
                text: item[objectPropertyName],
                value: item[objectPropertyValue]
            });
        }
        return filtered;
    }, []);
};

