import React from "react";

// Styles
import styles from "./index.module.css";


// Modules
import {Card, Embed} from "semantic-ui-react";

/**
 * Youtube video responsive block
 * @param {Object} props - Props for this component
 * @param {string} props.videoId  Youtube video id
 * @param {string} props.text Help text to render above the video.
 * @returns {*}
 * @constructor
 */
function YoutubeVideoTutorial({videoId, text}) {

    return <Card className={styles['sidebar--widget']}>

        <Card.Content>

        <h4 className={styles['sidebar--widget-header']}>{text}</h4>

        {/*View of a perticular plugin TODO: make sure if there is not data then what happen.*/}
        <Embed
            id={videoId}
            placeholder={`https://assets.oasisapps.com/frontendassets/img/quickbase_help.jpg`}
            source='youtube'
            className={styles['sidebar--widget-youtube']}
            icon={"youtube"}
            iframe={{
                allowFullScreen: true
            }}
        />

        </Card.Content>

    </Card>

}

export default YoutubeVideoTutorial;