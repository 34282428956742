import React from 'react';

//sub components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";


//styles
import styles from './index.module.css';


/**
 * Main layout HOC for the Marketplace pages
 * Internal components are passed via props.children to this component.
 * Shared Components are:
 * - Header
 * - DataFilters
 * - Footer
 */
function MarketplaceLayout(props) {

    return (
        <div className={styles['wrapper']}>

            {/* Header */}
            <Header internalLayout={true}/>
            {/* Header */}

            <section className={styles['marketplace-page--wrapper']}>

                {/* Main Content */}
                {props.children}
                {/* Main Content */}

            </section>


            {/* Footer */}
            <Footer/>
            {/* Footer */}

        </div>
    );

}

export default MarketplaceLayout;