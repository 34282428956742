import React, { useState, useEffect } from 'react';


//semantic
import {Modal, Form, Button, Message} from 'semantic-ui-react';

//form errors
import { isFormError_h } from "../../../utils/formHelpers";
import { ApiError_h } from "../../../utils/apiHelpers";

import { patchSubscription } from "../../../feathers/services/subscriptions";

//react hook form
import { useForm } from 'react-hook-form';


//styles
import styles from './index.module.css';

/**
 * Edit a subscription title
 * @param {Object} props React component props object
 * @param {Object} props.subscription The subscription to be edited (database object)
 * @param {Boolean} props.open Boolean controlling if the modal is open/closed.
 * @param {Function} props.toggleOpen Function to run to open/close the modal.  Expects a boolean - open = true closed = false.
 * @param {Function} props.onSuccess Function to run after a successful update.  Takes a subscription parameter.
 *
 */
function EditSubscription({ subscription, open, toggleOpen, onSuccess}) {

    /*************************************************
     *  React Hook Form
     *************************************************/
    var { register, handleSubmit, errors } = useForm();


    /*************************************************
     *  State
     *************************************************/
    var [editSubscriptionErrors, setEditSubscriptionErrors] = useState([]);
    var [isLoading, setIsLoading]                           = useState(false);



    /*************************************************
     *  Handlers
     *************************************************/
    const onSubmit = async subscriptionUpdates => {
        setIsLoading(true);
        try {
            var updatedSubscription = await patchSubscription(subscription._id, {
                name: subscriptionUpdates.name
            });
            setIsLoading(false);
            toggleOpen(false);
            onSuccess(updatedSubscription);
        } catch (error) {
            setIsLoading(false);
            var err = new ApiError_h(error);
            setEditSubscriptionErrors([`There was an error updating your subscription.  ${err.getUserMessage()}`]);
            return;
        }
    };

    const handleClose = ()=>{
        setEditSubscriptionErrors([]);
        toggleOpen(false);
    };

    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        if( !subscription ) {
            setEditSubscriptionErrors(['No subscription provided to edit.  Please close and try again.']);
        } else {
            setEditSubscriptionErrors([]);
        }
    }, [subscription]);



    return (
        <>
            <Modal
                size="small"
                open={open}
                onClose={() => handleClose()}
                closeIcon
            >
                <Modal.Header>Edit Subscription</Modal.Header>
                
                <Modal.Content>
                    <p>Update the name of your subscription below.</p>
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        // error={formHasErrors_h(errors) || editSubscriptionErrors.length > 0}
                    >
                        <Form.Field
                            width={16}
                            required
                            error={isFormError_h(errors.name)}>
                            <input
                                defaultValue={subscription && subscription.name}
                                placeholder='Enter a name...'
                                type='text'
                                name="name"
                                ref={register({ required: "Name is a required field." })}
                            />
                        </Form.Field>
                        <div className={styles['button-wrapper'] + " clearfix"}>
                            <Button
                                floated='right'
                                loading={isLoading}
                                type="submit"
                                color='teal'
                            >
                                Submit
                            </Button>
                        </div>
                        <div>
                            <Message
                                negative
                                visible={editSubscriptionErrors.length > 0}
                                error
                                header='Errors'
                                list={editSubscriptionErrors}
                            />
                        </div>
                    </Form>
                </Modal.Content>
            </Modal>
            {/* Edit Subscription Title  */}
        </>
    );

}

export default EditSubscription;