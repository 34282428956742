import React from 'react';

// Styles
import styles from './index.module.css';

// Components
import HelpPopup from "../HelpPopup/HelpPopup";
import { Icon, Label, Popup } from 'semantic-ui-react';



/**
 * Labels for all fields in config mapping section.
 * @param {Object} props React component props object
 * @param {String} props.label The label you need to show.
 * @param {String} props.help Help label to show
 * @param {String} props.required Is the label required or not.
 * @param {String} props.sublabel Added after the regular label, italics and less bold label for notifying the user something specific about this field
 * @param {String} props.buttonText Labels can show a button that allows a user interaction like a click
 * @param {function} props.onButtonClick Function to run on button click
 */
function ConfigInputLabel({ label, help = null, required=true, sublabel="", buttonText, onButtonClick=null}) {

    return (
        <label className={styles['label-override']}>
            <span className={styles['label']}>{label}</span>
            
            <HelpPopup help={help} fieldInput={true} inverted={true} />            
            
            {required && 
                <span className={styles['required']}>*</span>
            }

            {sublabel &&
                <span className={styles['input-form--label-note']}>{sublabel}</span>
            }

            {buttonText &&
                <span className={styles['add-icon']}>
                    <Popup content='Click to create a new connection' trigger={
                        <Label 
                            color='teal'
                            horizontal
                            icon="add circle"
                            inverted
                            size="small"
                            onClick={()=>onButtonClick()}
                        >
                            <Icon name="add" />
                            {buttonText}
                        </Label>
                    } />
                </span>
            }
        </label>
    );
        

}

export default ConfigInputLabel;