import React, { useState } from 'react';

//semantic
import {Form, Label} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';
import { isFormError_h } from '../../../utils/formHelpers';
import { debounce_h } from '../../../utils/formHelpers';
import ConfigInputLabel from '../../ConfigInputLabel/ConfigInputLabel';

/**
 * Field from the database
 * @typedef {Object} Field
 * @property {String} type The type of field to render
 * @property {String} label The label to be shown for this particular field 
 * @property {Object} options Dropdown options for semantic ui react 
 * @property {String} userHelpText help text to render for the end user
 * @property {Boolean} required Is this field required or not
 * @property {any} defaultValue The default value to show to the user for a field.
 * @property {String} leftLabel The label to show to the left of the input - exmple "https://"
 * @property {String} rightLabel The label to show to the right of the input - example "quickbase.com"
 * @property {String} icon The icon to show in the input
 * @property {String} placeholder The placeholder text for this field
 * @property {String} name The name of this field for programming - a unique identifier
 * @property {String} iconPosition left/right - which side to show the icon in the input.
 * @property {Boolean} stripValueForSecurity Whether this value is present or not or if the server strips it from the response.
 */


/**
 * The field in question from the database
 * @param {Object} props
 * @param {Field} props.field The field object from the database
 * @returns Field Component to render
 */
function ConnectionField({field, register, errors, setValue}) {

    const requiredObject = (field.required) ? { required: `"${field.label}" is required.` } : { required: false };

    const customLabel = (
        <>
            <ConfigInputLabel
                label={field.label}
                required={field.required}
                help={field.userHelpText}
                sublabel={field.sensitive ? "(Hidden for Security)" : ""}
            />
        </>
    );

    /*************************************************
     *  State
     *************************************************/
    // const [inputValue, setInputValue] = useState(null);


    /*************************************************
     *  Effects
     *************************************************/
    // useEffect(()=>{
    //     console.log(field);
    // });

    /*************************************************
     *  Handlers
     *************************************************/
    const handleChange = debounce_h((e, element) => {
        const {type, name} = field;
        switch (type) {
            // case 'checkbox':
            // case 'toggle':
            // case 'slider':
            //     setValue(name, element.checked, true);
            //     break;
            case "password":
            case "text":
            case "textarea":
            case "numeric":
            case "dropdown":
                setValue(name, element.value, true);
                break;
            default:
                break;
        }

    }, 50, false);;

    const renderField = ()=>{
        var output = null;
        switch (field.type) {
            case 'textarea':
                return(
                    <Form.TextArea
                        label={customLabel}
                        placeholder={field.placeholder}
                        defaultValue={field.defaultValue}
                        onChange={handleChange}
                        error={isFormError_h(errors[field.name])}
                        ref={register({ name: field.name }, requiredObject)}
                    />
                );
            case 'text':
            case 'password':
            case 'numeric':
                var type = null;
                if(field.type === "numeric") {
                    type = "number";
                } else {
                    type = field.type;
                }

                //a left label/right label field can't have an icon
                if(field.leftLabel || field.rightLabel) {
                    output = <Form.Input
                        icon={field.icon ? field.icon : null}
                        fluid
                        type={"text"}
                        label={customLabel}
                        labelPosition="left"
                        placeholder={field.placeholder}
                        defaultValue={field.defaultValue}
                        onChange={handleChange}
                        error={isFormError_h(errors[field.name])}
                        ref={register({ name: field.name }, requiredObject)}
                    >
                        {field.leftLabel &&
                            <Label>{field.leftLabel}</Label>
                        }
                        <input />
                        {field.rightLabel && 
                            <Label>{field.rightLabel}</Label>
                        }
                    </Form.Input>;
                } else {
                    output = <Form.Input
                        icon={field.icon ? field.icon : null}
                        fluid
                        type={type}
                        label={customLabel}
                        iconPosition={field.iconPosition ? field.iconPosition : null}
                        placeholder={field.placeholder}
                        defaultValue={field.defaultValue}
                        onChange={handleChange}
                        error={isFormError_h(errors[field.name])}
                        ref={register({ name: field.name }, requiredObject)}
                    />;
                }
                return (
                    output
                );
            default:
                break;
        }
    };

    return (
        <>
            {renderField()}
        </>
    );

}


export default ConnectionField;