import React, { useState, useEffect } from 'react';
import './App.css';
import { Helmet } from "react-helmet";


//reach router views
import {
  BrowserRouter
} from "react-router-dom";

// import { Dimmer, Loader } from 'semantic-ui-react';


//routes
import Routes from './routes/index';

//redux 
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./store/actions/authActions";

import CookieConsent from "./components/CookieConsent/CookieConsent";
import IdleTimeout from "./components/IdleTimeout/IdleTimeout";
import CONFIG from "./config/index";




function App() {


  /*************************************************
  *  State
  *************************************************/
  const [isAuthenticated] = useState(false);


  /*************************************************
  *  Redux
  *************************************************/
  //get if the user is logged in
  const dispatch = useDispatch();
  //get logged in user from the redux store auth state.
  const { isLoggedIn } = useSelector(state => state.auth);


/*************************************************
 *  Effects
 *************************************************/
  useEffect(() => {
    dispatch(actions.getReAuthenticate());
  }, [isAuthenticated, dispatch]);


  return (
    <>

      {/* <Dimmer active={isLoading} page>
        <Loader size='large'>Loading...</Loader>
      </Dimmer> */}

      <CookieConsent />

      <Helmet>
        <title>Oasis AppSuite</title>
        <meta
          name="description"
          content="Oasis AppSuite is a tool that allows plugins to be installed on top of Quickbase in record time!  Visit oasisapps.com to learn more about the plugins available to streamline your Quickbase development."
        />
        {CONFIG.ENVIRONMENT === "dev" &&
          <meta name="robots" content="noindex"></meta>
        }
      </Helmet>

      <BrowserRouter>
          <IdleTimeout>
            <Routes isLoggedIn={isLoggedIn} />
          </IdleTimeout>
      </BrowserRouter>
      
    </>
    

  );

}



export default App;
