import React from 'react';

//semantic
import {Menu} from 'semantic-ui-react';


//router
import { Link } from 'react-router-dom';


/**
 * 
 * @param {object} props
 * @param {string} props.activeItem Current active item - available choices "Subscriptions", "Payment Method", "Payment History"
 */
function PaymentNav({ activeItem }) {
    

    return (
        <>
            <Menu pointing secondary>
                <Menu.Item
                    as={Link}
                    to={{
                        pathname: `/account/subscriptions`,
                    }}
                    name='Subscriptions'
                    active={ activeItem === 'Subscriptions' }
                    link
                    // onClick={handleItemClick}
                />
                <Menu.Item
                    as={Link}
                    to={{
                        pathname: `/account/payment-method`,
                    }}
                    name='Payment Method'
                    active={activeItem === 'Payment Method'}
                    link
                    // onClick={handleItemClick}
                />
                {/* <Menu.Item
                    name='Payment History'
                    active={activeItem === 'Payment History'}
                    link
                    onClick={handleItemClick}
                /> */}
            </Menu>
             
        </>
    );

}

export default PaymentNav;