import styles from "./index.module.css";
import React from "react";

import { Link } from "react-router-dom";


function Footer() {

    return <footer className={styles['footer-wrapper']}>
        <div className={styles["footer-copyright"]}>
            Oasis AppSuite. All Rights Reserved
        </div>
        <div className={styles["footer-nav"]}>
            <Link to="/contact"><span>Contact Us</span></Link>
            <Link to="/legal/privacy-policy"><span>Privacy Policy</span></Link>
            <Link to="/legal/terms-of-use"><span>Terms of Use</span></Link>
        </div>
    </footer>

}

export default Footer