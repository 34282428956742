/**
 * Houses all sagas for config service
 */
import {takeLatest, call, fork, put} from 'redux-saga/effects';
import * as actions from '../actions/qbFieldActions';
// import * as modalAction from '../actions/modals';
import * as types from '../types';
import * as api from '../../feathers/services/qbFields';


/**
 * Get Quick base Fields for the selected Table service saga's
 */

//get qbtables api call
function* getQbFields(action) {

    try {

        const {connectionId, tableId} = action.payload;

        const response = yield call(api.getQbFields, connectionId, tableId);

        //dispatch action to set success state for api call
        const tableFields = {
            tableId: tableId,
            data: response.data
        };
        yield put(actions.getQbFieldsSuccess(tableFields));

    } catch (error) {
        console.error("getQbFields Saga Error", error);
        yield put(actions.getQbFieldsFailure(error));
    }

}

// Create qbtables api call
function* createQbField(action) {

    try {

        const response = yield call(api.createQbFields, action.payload);

        yield put(actions.createQbFieldSuccess(response.data));


        // Show some success message


        // Reload the Field
        const {connectionId, tableId} = response;
        yield put(actions.getQbFields({connectionId, tableId}));


    } catch (error) {
        console.error("CreateQBFieldFailure Saga Error", error);
        yield put(actions.createQbFieldFailure(error));
    }

}

// Get qb field watcher function
function* watchGetQbFields() {
    yield takeLatest(types.GET_QB_FIELDS, getQbFields);
}

// Create qb field watcher function
function* watchCreateQbField() {
    yield takeLatest(types.CREATE_QB_FIELD, createQbField);
}


//Export all saga's here
const qbFieldSagas = [
    fork(watchGetQbFields),
    fork(watchCreateQbField),
];

export default qbFieldSagas;

