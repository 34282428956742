/**
 * Houses all sagas for authentication service
 */
import { takeLatest, call, fork, put } from 'redux-saga/effects';
import * as actions from '../actions/authActions';
import { setUser } from '../actions/userActions';
import * as types from '../types';
import * as api from '../../feathers/services/users';



/**
 * Authentication service saga's
 */



//reauthenticates a user on page load (check for jwt and make sure have a valid user)
function* reAuthenticateUser( action ) {
    try {
        const result = yield call(api.reAuthenticate);
        yield put(actions.reAuthenticateSuccess( result ));
        yield put(setUser(result.user));
    } catch (error) {
        yield put(actions.reAuthenticateFailure(error));
    }
}

function* watchReAuthenticateUser() {
    yield takeLatest(types.GET_REAUTHENTICATE_DATA, reAuthenticateUser);
}


//Authenticates a user in the database given a name/password
function* getAuthData( action ) {

    try {
        const { email, password } = action.payload;
        const result = yield call(api.login, email, password);
        yield put(setUser(result));
        yield put(actions.getAuthSuccess());
    } catch (error) {
        yield put(actions.getAuthFailure(error));
    }

}

function* watchGetAuthData() {
    yield takeLatest(types.GET_AUTH_DATA, getAuthData);
}






//Export all saga's here
const authSagas = [
    fork(watchGetAuthData),
    fork(watchReAuthenticateUser)
];

export default authSagas;

