import React, {useState} from 'react';

//semantic
import {Button, Icon, Input, Container, Dropdown, Grid} from 'semantic-ui-react';

import PluginRating from "../PluginCards/Rating/PluginRating";

//styles
import styles from './index.module.css';


//TODO: Come up with appropriate options
const ratingSortOptions = [
    {
        key: 2,
        text: "Highest to Lowest",
        value: "high",
        content: (
            <>
                <Icon name="arrow up" /><span>&nbsp; to &nbsp;&nbsp;&nbsp;</span><Icon name="arrow down" />
            </>
        )
    },
    {
        key: 1,
        text: "Lowest to Highest",
        value: "low",
        content: (
            <>
                <Icon name="arrow down" /><span>&nbsp; to &nbsp;&nbsp;&nbsp;</span><Icon name="arrow up" />
            </>
        )
    }
];

const categoryOptions = [
    {
        key: 1,
        text: "Accounting & Financial",
        value: "Accounting & Financial"
    },
    {
        key: 2,
        text: "Application Analytics",
        value: "Application Analytics"
    },
    {
        key: 3,
        text: "Data Capture",
        value: "Data Capture"
    },
    {
        key: 4,
        text: "Data Migration",
        value: "Data Migration"
    },
    {
        key: 5,
        text: "Email & Text",
        value: "Email & Text"
    },
    {
        key: 6,
        text: "Files & Documents",
        value: "Files & Documents"
    },
    {
        key: 7,
        text: "Geospatial",
        value: "Geospatial"
    },
    {
        key: 8,
        text: "Project Management",
        value: "Project Management"
    },
    {
        key: 9,
        text: "Reporting",
        value: "Reporting"
    },
    {
        key: 10,
        text: "Scheduling",
        value: "Scheduling"
    },
    {
        key: 11,
        text: "UI/UX Improvement",
        value: "UI/UX Improvement"
    }
];


const starOptions = [
    {
        key: 1,
        text: "5 stars & up",
        value: 5,
        content: (
            <>
                <PluginRating rating={5} /><span className={styles['rating-text']}>& Up</span>
            </>
        )
    },
    {
        key: 2,
        text: "4 stars & up",
        value: 4,
        content: (
            <>
                <PluginRating rating={4} /><span className={styles['rating-text']}>& Up</span>
            </>
        )
    },
    {
        key: 3,
        text: "3 stars & up",
        value: 3,
        content: (
            <>
                <PluginRating rating={3} /><span className={styles['rating-text']}>& Up</span>
            </>
        )
    },
    {
        key: 4,
        text: "2 stars & up",
        value: 2,
        content: (
            <>
                <PluginRating rating={2} /><span className={styles['rating-text']}>& Up</span>
            </>
        )
    },
    {
        key: 5,
        text: "1 star & up",
        value: 1,
        content: (
            <>
                <PluginRating rating={1} /><span className={styles['rating-text']}>& Up</span>
            </>
        )
    }
];



//default filter change handler
const defaultHandleFilterChange = e => null;


/**
 * Data filter wrapper
 * @param {Object} props Component props
 * @param {function} props.handleFilterChange Handler for a filter change
 * @param {array} props.authorOptions Options for the author dropdown
 */
function DataFilterWrapper({ onFilterChange = defaultHandleFilterChange, authorOptions }) {

    const [filterVisible, setFilterVisible] = useState(false);

    const visibleTransition = filterVisible ? "visible" : "hidden";

    return (
        <>

            <div className={styles['data-filter--wrapper']}>

                <Input
                    name="searchTerm"
                    size={'large'}
                    className={styles['data-filter--input-override']}
                    icon='search'
                    iconPosition='left'
                    onChange={ onFilterChange }
                    placeholder='Search for plugins...'
                />

                <Button className={styles['data-filter--button-override']}
                        size={'large'}
                        onClick={() => setFilterVisible(!filterVisible)}>
                    <Icon name='filter'/> Filters
                </Button>

            </div>

            {filterVisible ? "" : <div className={"mb-medium"} />}

            {/* Filter Inputs */}
            <Container className={["transition", "fade", "down", visibleTransition].join(" ")}>

                <br />
                <Grid centered>
                    <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                        <label className={styles['search-labels']}>Author</label>
                        <Dropdown
                            name="author"
                            placeholder='Select/Search Author...'
                            search
                            selection
                            clearable
                            fluid
                            onChange={onFilterChange}
                            options={authorOptions} />
                    </Grid.Column>
                        
                    <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                        <label className={styles['search-labels']}>Category</label>
                        <Dropdown
                            name="category"
                            placeholder='Select/Search by Category...'
                            search
                            multiple
                            selection
                            clearable
                            fluid
                            onChange={onFilterChange}
                            options={categoryOptions} 
                            searchInput={{ autoComplete: 'on' }}/>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                        <label className={styles['search-labels']}>Rating</label>
                        <Dropdown
                            name="rating"
                            placeholder='Filter by Rating...'
                            search
                            selection
                            clearable
                            fluid
                            onChange={onFilterChange}
                            options={starOptions}
                        />
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                        <label className={styles['search-labels']}>Sort by Rating</label>
                        <Dropdown
                            name="ratingSort"
                            placeholder="Select sort order..."
                            search
                            selection
                            clearable
                            fluid
                            onChange={onFilterChange}
                            options={ratingSortOptions} />
                    </Grid.Column>

                </Grid>
                        


                <br />
                <br />

            </Container>

        </>
    );

}

export default React.memo(DataFilterWrapper, (prevProps, nextProps)=>
    prevProps.authorOptions === nextProps.authorOptions
);