import React, {useState, useEffect} from 'react';

//semantic
import {
    Modal,
    Button, 
    Message,
    Icon
} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';

//api
import { createQbBtnInstall } from "../../feathers/services/qbBtnInstall";
import { ApiError_h } from "../../utils/apiHelpers";


/**
 * This is used to install a plugins URL button in the actual application of interest.  This allows a user to launch a plugin within their application.  There is some complicated authentication required for launching a plugin from Quick Base - this sets all of this up in the QB application of interest for the end user.
 * @param {Object} props React component props object
 * @param {String} props.subscriptionId Subscription ID for this particular plugin
 * @param {Boolean} props.isOpen Is this modal open/closed.
 * @param {Function} props.onOpenClose Handler for changing if modal is open/closed
 */
function PluginButtonInstall({subscriptionId, isOpen, onOpenClose}) {


    /*************************************************
    *  State
    *************************************************/
    const [loading, setLoading]                                             = useState(false);
    const [error, setError]                                                 = useState(false);
    const [errorMsg, setErrorMsg]                                           = useState('');
    const [installFinished, setInstallFinished]                             = useState(false);
    const [launchPluginFieldSettingsLink, setLaunchPluginFieldSettingsLink] = useState('');
    const [plugin, setPlugin]                                               = useState(null);



    /*************************************************
    *  Effects
    *************************************************/
    useEffect(()=>{
        if( !subscriptionId ) {
            setError(true);
            setErrorMsg('No subscription was provided.  If the problem persists, please contact support');
        }
    }, [subscriptionId]);

    useEffect(()=>{
        if(isOpen) {
            setError(false);
            setError(false);
            setErrorMsg('');
            setInstallFinished(false);
            setLaunchPluginFieldSettingsLink('');
        }
    }, [isOpen])



    /*************************************************
    *  Handlers
    *************************************************/
    

    const handleInstall = e=>{
        setLoading(true);
        //5f29df1cfb976a00d8bf10d9
        createQbBtnInstall({ subscriptionId }).then(res => {
            if (error) {
                setError(false);
            }
            setLaunchPluginFieldSettingsLink(res.fieldSettingURL);
            setPlugin(res.plugin);
            setLoading(false);
            setInstallFinished(true);
        }).catch(err => {
            var error = new ApiError_h(err);
            setErrorMsg(`There was an error processing this request. Error: ${error.getMessage()}`);
            setError(true);
            setLoading(false);
        });
    }



    return (
        <>
            <Modal
                onClose={() => onOpenClose(false)}
                onOpen={() => onOpenClose(true)}
                open={isOpen}
                closeIcon
            >
                <Modal.Header>Quickbase Button Install</Modal.Header>
                <Modal.Content>


                        {!installFinished &&
                            <p>
                                After completing your configuration, you can install the button to launch this plugin from your Quickbase application manually (see the plugins User Manual for details), or Oasis can install it for you.  Click the "Install" button below if you would like Oasis to complete this installation for you.
                            </p>
                        }
                        
                        {error &&
                            <Message
                                negative
                            >
                                <Message.Header>Error</Message.Header>
                                <p>{errorMsg}</p>
                            </Message>
                        }

                        {installFinished && plugin &&
                            <Message info>
                                <Message.Header><Icon name="checkmark" />Success</Message.Header>
                                <br />
                                <p>
                                    Your button was successfully installed!  You can launch "{plugin.name}" in your Quick Base application using the field that was just created called "{plugin.pluginButtonName}" linked to below: 
                                    <br/>
                                    <br />
                                    <span className="text-bold"><a href={launchPluginFieldSettingsLink} target="_blank" rel="noopener noreferrer">Click to See New Button Field Settings in Quick Base</a></span>
                                </p>
                                <p>
                                    We recommend updating this button by checking off "Display as a button on forms and reports" in the settings of the field contained in the link above.  
                                </p>
                            </Message>
                        }
                       
                </Modal.Content>
                <Modal.Content>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => onOpenClose(false)}>
                        Close
                    </Button>
                    
                    {!installFinished &&
                        <Button
                            content="Install"
                            labelPosition='right'
                            icon='upload'
                            onClick={handleInstall}
                            positive
                            loading={loading}
                        />
                    }
                    
                </Modal.Actions>
            </Modal>
        </>
    );

}

export default PluginButtonInstall;