
/**
 * Works with react hook form.  Forces alphanumeric pw that is at least 8 letters long
 * @param {String} password the password in question
 * @return {Boolean} IF invalid password, returns string, else returns true for a valid password.
 */
export const passwordInputValidator = (password) => {
    if (!password) return 'no_password';

    if (password.length < 8) {
        return ("too_short");
    } else if (password.length > 50) {
        return ("too_long");
    } else if (password.search(/\d/) == -1) {
        return ("no_num");
    } else if (password.search(/[a-zA-Z]/) == -1) {
        return ("no_letter");
    }
    return true;
};

