import React from 'react';

//semantic
import {
    Container, Grid,
} from 'semantic-ui-react';


//styles
import styles from './index.module.css';

// Sub components
import AccountMenu from "./MyAccountMenu/AccountMenu";
import HelpPopup from "../HelpPopup/HelpPopup";


/**
 *  This is a parent page for all the account pages
 *
 * @param {String} pageTitle The title of the page.
 * @param {String} helpPopup Help content. Leave blank to hide
 * @param [children] Child elements inside this node
 * @returns {*}
 * @constructor
 */
function Account({pageTitle, helpPopup = null, children}) {

    return (
        <>

            <Container>


                <h1 className={styles['section-title']}>
                    <span>
                        {pageTitle}
                        &nbsp;
                        {helpPopup ? <HelpPopup help={helpPopup} /> : ""}
                    </span>
                </h1>

                <Grid>

                    <Grid.Row>

                        <Grid.Column mobile={16} tablet={16} computer={5} largeScreen={4} widescreen={4}>

                            {/*Side Menu*/}
                            <AccountMenu/>

                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={11} largeScreen={12} widescreen={12}>

                            {children}

                            <div className={"mb-medium"} />

                        </Grid.Column>

                    </Grid.Row>

                </Grid>

            </Container>

        </>
    );

}

export default Account;