/******************************
 *  Reducer for Connections
 ******************************/
import * as types from '../types';
import {ApiError_h} from '../../utils/apiHelpers';


const initialState = {
    isLoading: false,
    config: {},
    error: ''
};

export default (state = initialState, action) => {
    switch (action.type) {

        /* GET REDUCERS */
        case types.GET_CONFIGURATION:
            return {
                ...state,
                isLoading: true
            };
        case types.GET_CONFIGURATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                config: action.payload
            };
        case types.GET_CONFIGURATION_FAILURE:
            const getErrorMsg = new ApiError_h(action.payload).getUserMessage();
            return {
                ...state,
                isLoading: false,
                error: getErrorMsg
            };
        case types.GET_CONFIGURATION_ERROR:
            return {
                ...state,
                error: ''
            };
        /* GET REDUCERS */


        /* CREATE REDUCERS */
        case types.CREATE_CONFIGURATION:
            return {
                ...state,
                isLoading: true
            };
        case types.CREATE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                config: action.payload
            };
        case types.CREATE_CONFIGURATION_FAILURE:
            const errorMsg = new ApiError_h(action.payload).getUserMessage();
            return {
                ...state,
                isLoading: false,
                error: errorMsg
            };
        case types.CLEAR_CONFIGURATION_ERROR:
            return {
                ...state,
                error: ''
            };
        /* CREATE REDUCERS */

        default:
            return state;
    }
};