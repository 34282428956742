/**
 * Functions to interact with Authorize.net utilizes Accept.js library from Authorize.nets API (injected at index.html level)
 */


/**
* TODO: Add this to typedef's file.
* ProfileObject typedef
* @typedef {Object} OpaqueData
* @property {string} dataDescriptor Data descriptor string
* @property {string} dataValue Data value object
*/
 /**
  * Obtains the none from Authorize.net
  * @param {object} paymentProfileObject Payment profile object with cardNumber, month, year, cardCode and zip
  * @param {object} auth auth object with clientKey and apiLoginID
  * @returns {OpaqueData} Returns the opaqueData needed for Authorize.net nonce.
  */
export async function obtainNonce( paymentProfileObject, auth ) {

    var authData = {};
    authData.clientKey  = auth.clientKey;
    authData.apiLoginID = auth.apiLoginID;

    var cardData = {};
    cardData.cardNumber = paymentProfileObject.cardNumber;
    cardData.month      = paymentProfileObject.expiration.slice(0,2);
    cardData.year       = paymentProfileObject.expiration.slice(2,4);
    cardData.cardCode   = paymentProfileObject.cardCode;
    cardData.zip        = paymentProfileObject.zip;
    cardData.fullName   = `${paymentProfileObject.firstName} ${paymentProfileObject.firstName}`;

    var secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;


    return new Promise((resolve, reject)=>{
        //accept is loaded through index.html
        window.Accept.dispatchData(secureData, (response) => {
            if (response.messages.resultCode === "Error") {
                var i = 0;
                var errors = [];
                while (i < response.messages.message.length) {
                    errors.push(response.messages.message[i].text);
                    i = i + 1;
                }
                reject(errors);
            } else {
                resolve(response.opaqueData)
            }
        });
    });
};