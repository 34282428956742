/**
* Defines all modal interactions
*/
import * as types from '../types';

//sets the create connection modal to open/closed.
export const setCreateModalState = (payload) => {
    return {
        type: types.SET_CREATE_CONNECTION_MODAL,
        payload
    };
};

export const setModalState = (payload) => {
    console.info("setModalState", payload)
    return {
        type: types.SET_MODAL,
        payload
    };
};



