import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

//semantic
import {
    Container,
    Grid, 
    Button, 
    Icon,
    Select,
    Form, 
    Message,
    Header,
    Popup,
    GridColumn
} from 'semantic-ui-react';

//react hook form
import { useForm } from 'react-hook-form';


//styles
import styles from './index.module.css';

//redux
import { useSelector, useDispatch } from "react-redux";

// Sub components
import ConfigSteps from "../ConfigSteps/ConfigSteps";
import useCopyToClipboard from "../../../utils/useCopyToClipboard";
import RelatedDocumentation from "../../RelatedDocumentation/RelatedDocumentation";
import PaymentModal from "../../PaymentModal/PaymentModal";
import SectionGroup from "../../SectionGroup/SectionGroup";
import PluginButtonInstall from "../../PluginButtonInstall/PluginButtonInstall";

import { formatToUSD_h } from "../../../utils/currencyHelpers";
import { getPluginById } from "../../../feathers/services/plugins";
import { createSubscription } from "../../../feathers/services/subscriptions";
import { ApiError_h } from "../../../utils/apiHelpers";
import { isFormError_h } from "../../../utils/formHelpers";
import { plugginUrlGenerator_h } from "../../../utils/pluginUrlHelpers";
import { addDaysToDateFormatted_h } from "../../../utils/dateHelpers";
import PluginTierPricing from "../../PluginTierPricing/PluginTierPricing";
import { updateFreeTrials } from "../../../store/actions/userActions";
import PromotionInput from '../../PromotionInput/PromotionInput';
import SubscriptionSummary from '../../SubscriptionSummary/SubscriptionSummary';
import { createVerifyPromotion } from "../../../feathers/services/promotionVerify";

const generateDropdownTierOptions = (plugin)=>{
    
    if (!plugin.pricing.tiers || plugin.pricing.tiers.length < 1) return [{ key: '1', value: 'err', text: `Error!` }];
    const tiers = plugin.pricing.tiers;
    var dropdownOptions = [];
    for (let i = 0; i < tiers.length; i++) {
        const tier = tiers[i];
        //add monthly dropdown option
        dropdownOptions.push({ key: `${tier.id}_monthly`, value: `${tier.id}_monthly`, text: `${tier.name} Billed Monthly - ${formatToUSD_h(tier.price.monthlyPricing)}/mo`});
        //add yearly dropdown option
        dropdownOptions.push({ key: `${tier.id}_annually`, value: `${tier.id}_annually`, text: `${tier.name} Billed Yearly - ${formatToUSD_h(tier.price.annualPricing)}/yr (${formatToUSD_h(tier.price.annualMonthlyPricing)}/mo)` });
    }

    return dropdownOptions;
};







/**
 * Configuration Success page.  This finalizes the subscription.  If a user hasn't paid yet, it shows a modal to input their payment information as well.
 */
function ConfigurationSuccess() {

    //when a user is editing a configuration, the URL scheme has "edit" in it.
    var userIsEditingExistingConfig = window.location.href.indexOf('edit') >= 0 ? true : false;

    const [isCopied, setLinkCopy] = useCopyToClipboard();


    /*************************************************
     *  Query Parameters
     *************************************************/
    // URL Query Parameters
    const params = useParams();
    // Get the connection id from the route
    const { pluginId, configId } = params;



    /*************************************************
     *  React Hook Form
     *************************************************/
    var { register, handleSubmit, errors, setValue } = useForm();




    /*************************************************
     *  State
     *************************************************/
    var [isPaymentModalOpen, setIsPaymentModalOpen]         = useState(false);
    var [userHasPaymentProfile, setUserHasPaymentProfile]   = useState(true);
    var [plugin, setPlugin ]                                = useState(false);
    var [frequency, setFrequency]                           = useState('');
    var [apiErrors, setApiErrors]                           = useState([]);
    var [isLoading, setIsLoading]                           = useState(true);
    var [submittedSuccess, setSubmittedSuccess]             = useState(false);
    var [isSubmitLoading, setIsSubmitLoading]               = useState(false);
    var [subscription, setSubscription]                     = useState(null);
    var [pluginInstallPopupOpen, setPluginInstallPopupOpen] = useState(false);
    var [userHasFreeTrial, setUserHasFreeTrial]             = useState(false);

    //Promotion State
    var [invalidPromotionMsg, setInvalidPromotionMsg]       = useState('');
    var [validPromotionMsg, setValidPromotionMsg]           = useState('');
    var [promotionalCode, setPromotionalCode]               = useState('');
    var [promotionInputLoading, setPromotionInputLoading]   = useState(false);
    var [subscriptionSummary, setSubscriptionSummary]       = useState({
        validPromotion: false,
        subscriptionPrice: null,
        discount: null,
        basePrice: null,
        details: '',
        bypassPaymentProfile: false
    });



    /*************************************************
     *  Redux
     *************************************************/
    //current user logged in
    const { user }       = useSelector(state => state);
    // const { allPlugins } = useSelector(state => state.plugins);
    const dispatch       = useDispatch();

    /*************************************************
    *  Effects
    *************************************************/


   //effect used to determine if user needs to enter a payment
    useEffect(()=>{
        //if the current user doesn't have payment information present, force them to enter it
        if( !user.paymentProfile && user.email ) {
            setUserHasPaymentProfile(false);
        } 
    }, [user]);



    //set the plugin pricing
    useEffect(()=>{
        getPluginById( pluginId ).then((plugin)=>{
            //update formatting for pricing
            plugin.monthlyPricing = formatToUSD_h(plugin.monthlyPricing);
            plugin.annualPricing = formatToUSD_h(plugin.annualPricing);
            setPlugin(plugin);
            setIsLoading(false);
        }).catch((err)=>{
            var error = new ApiError_h(err);
            setApiErrors([...apiErrors,error.getUserMessage()]);
            setIsLoading(false);
        });

        //if a user is editing an existing configuration, there is no need to show payment as that should already be taken care of
        if ( userIsEditingExistingConfig ) {
            setSubmittedSuccess(true);
        }
        register({ name: "frequency" }, { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //check for free trial
    useEffect(()=>{
        if (plugin.freeTrial > 0) {
            if (user) {
                if (!user.freeTrials) {
                    setUserHasFreeTrial(true);
                }
                if (user.freeTrials && !user.freeTrials[plugin._id]) {
                    setUserHasFreeTrial(true);
                }
            } else {
                userHasFreeTrial(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin, user]);

    /**
     * Tracks changes for frequency and promotionalCode - if either changes, gets the latest price of the plugin.
     */
    useEffect(()=>{
        if (invalidPromotionMsg) setInvalidPromotionMsg("");
        if (validPromotionMsg) setValidPromotionMsg("");

        if( promotionalCode ) {
            setPromotionInputLoading(true);
        }

        if(frequency) {
            createVerifyPromotion({
                code: promotionalCode,
                subscription: {
                    frequency
                },
                pluginId: plugin._id
            }).then(res => {
                if (!res.validPromotion) {
                    setInvalidPromotionMsg(res.details);
                } else {
                    setValidPromotionMsg("Valid promotional code!");
                }
                if (promotionalCode) {
                    setPromotionInputLoading(false);
                }
                setSubscriptionSummary(res);
            }).catch(err => {
                if(promotionalCode) setPromotionInputLoading(false);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                var errorVerify = new ApiError_h(err);
                setApiErrors(["There were errors processing this request and providing an accurate total for this subscription.  Please refresh this page and try again.  If the issue persists, please contact support."]);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frequency, promotionalCode]);
    

    /*************************************************
    *  Handlers
    *************************************************/
   //submits subscription
    const handleSubmitFrequency = data => {
        setIsSubmitLoading(true);
        setApiErrors([]);
        var subscription = {
            plugin_id: pluginId,
            configuration_id: configId,
            name: data.subscriptionName,
            frequency, 
            promotion: promotionalCode ? promotionalCode : null
        };
        createSubscription( subscription ).then((res)=>{
            setSubscription(res);
            //free trials are handled on the server, but update the front end UI so the user doesn't see another free trial.
            if (userHasFreeTrial ) {
                dispatch(updateFreeTrials({
                    expiration: addDaysToDateFormatted_h(new Date(), plugin.freeTrial),
                    id: pluginId
                }));
            }

            setIsSubmitLoading(false);
            setSubmittedSuccess(true);
        }).catch((err)=>{
            setIsSubmitLoading(false);
            var errorMsg = new ApiError_h(err);
            setApiErrors([...apiErrors, errorMsg.getUserMessage()]);
        });
    };

    // update frequency/tier selection 
    const handleSelectChange = (e, data) => {
        setFrequency(data.value);
        setValue('frequency', data.value, true);
    };

    // terms checkbox 
    const handleCheckboxChange = (e, element)=>{
        setValue("terms", element.checked, true);
    };

    /**
     * Handles checking promotion and calculating the subscription total.
     * @param {Object} promotionResponse Response from promotion check.
     */
    // const handlePromotionCheck = ( promotionResponse )=>{
    //     setSubscriptionSummary( promotionResponse );
    // };

    const handlePromotionChange = ( promotionalCode ) => {
        setPromotionalCode(promotionalCode);
    };


    //when user clicks open payment profile button
    const handlePaymentModal = (event)=>{
        event.preventDefault();
        setIsPaymentModalOpen(true);
    };

    //handler to toggle the paymentprofile open/close
    const handleTogglePaymentModal = ( modalState )=>{
        setIsPaymentModalOpen(modalState);
    };



    /**
     * Handles opening/closing install plugin button modal.
     * @param {boolean} bool Determines whether or not the install plugin button modal is open/closed
     */
    const handleButtonInstallOpenClose = (bool)=>{
        setPluginInstallPopupOpen(bool);
    };

    

    return (
        <>

            {/*Top Process Steps*/}
            <ConfigSteps activeStep={3}/>


            <Container>


                {/* Forced Payment  */}
                <PaymentModal 
                    open={ isPaymentModalOpen }
                    paymentSuccessHandler={()=>{
                        setIsPaymentModalOpen(false);
                        setUserHasPaymentProfile(true);
                    }}
                    toggleModal={ handleTogglePaymentModal }
                />
                {/* Forced Payment  */}



                {/* Used to Install URL Button in QB App  */}
                {subscription &&
                    <PluginButtonInstall
                        subscriptionId={subscription ? subscription._id : null}
                        isOpen={pluginInstallPopupOpen}
                        onOpenClose={handleButtonInstallOpenClose}
                    />
                }
                {/* Used to Install URL Button in QB App  */}
                


                {/* Pricing and Subscription Form  */}
                {!submittedSuccess && !userIsEditingExistingConfig && plugin &&
                    <Form
                        loading={isLoading}
                        error={apiErrors.length > 0}
                        onSubmit={handleSubmit(handleSubmitFrequency)}
                        className={styles['subscription-form']}
                    >
                        <Grid centered>
                            <Grid.Column 
                                mobile={16} 
                                tablet={14} 
                                computer={14} 
                                largeScreen={10} 
                                widescreen={10}
                            >
                                <SectionGroup
                                    title="Pricing and Subscription Details"
                                    description={apiErrors.length < 1 && `The subscription for "${plugin.name}" can be billed on a monthly or annual basis based on the rates in the dropdown below.  Please select your preference.  Please also add a unique name for this subscription below for your reference.`}
                                >
                                {userHasFreeTrial &&
                                    <>
                                    <p>You will not be billed until your free trial has expired.  You can cancel at any time prior to your <strong>free trial</strong> expiration date of {addDaysToDateFormatted_h(new Date(), plugin.freeTrial)} without charge.</p>
                                    </>
                                }
                                <Grid>
                                    <Grid.Column 
                                        mobile={16} 
                                        tablet={16} 
                                        computer={8} 
                                        largeScreen={8} 
                                        widescreen={8}
                                    >
                                        <Form.Group>
                                            <Form.Field
                                                width={16}
                                                required
                                                error={isFormError_h(errors.subscriptionName)}
                                            >
                                                <label>Subscription Name</label>
                                                <input
                                                    placeholder='Subscription name...'
                                                    type='text'
                                                    name="subscriptionName"
                                                    ref={register({ required: "Subscription name is required." })}
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        {/* Flat Pricing Form  */}
                                        {plugin.pricing.type === "flat" &&
                                            <Form.Group>
                                                <Form.Field
                                                    width={16}
                                                >
                                                    <label>Billing Frequency</label>
                                                    <Select
                                                        placeholder='Select Frequency'
                                                        // defaultValue={frequency}
                                                    options={[{ key: '1', value: 'monthly', text: `Monthly (${formatToUSD_h(plugin.pricing.monthlyPricing)})` },
                                                        { key: '2', value: 'annually', text: `Annual (${formatToUSD_h(plugin.pricing.annualPricing)})` }]}
                                                        onChange={handleSelectChange}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                        }
                                        {/* Flat Pricing Form  */}

                                        {/* Tier Pricing Form  */}
                                        {plugin.pricing.type === "tier" &&
                                            <>
                                                <Form.Group>
                                                    
                                                    <Form.Field
                                                        width={16}
                                                        error={isFormError_h(errors.frequency)}
                                                    >
                                                        <label>
                                                            Tier and Billing Frequency &nbsp;
                                                            <Popup trigger={<Icon link color="grey" name="question circle" />} flowing pinned on="click">
                                                                <PluginTierPricing
                                                                    plugin={plugin}
                                                                />
                                                            </Popup>
                                                        </label>
                                                        <Form.Select
                                                            fluid
                                                            placeholder='Select Tier'
                                                            // defaultValue={frequency}
                                                            options={generateDropdownTierOptions(plugin)}
                                                            onChange={handleSelectChange}
                                                        />
                                                    </Form.Field>
                                                </Form.Group>
                                            </>
                                        }
                                        {/* Tier Pricing  */}
                                        

                                        {/* Promotional Code  */}
                                        <PromotionInput 
                                            loading={promotionInputLoading}
                                            onPromotionChange={handlePromotionChange}
                                            errorMsg={invalidPromotionMsg}
                                            successMsg={validPromotionMsg}
                                        />
                                        {/* Promotional Code  */}


                                        {/* Terms and Conditions  */}
                                        {!userIsEditingExistingConfig &&
                                            <Form.Group>
                                                <Form.Checkbox
                                                    ref={register({ name: "terms" }, { required: true })}
                                                    error={isFormError_h(errors["terms"])}
                                                    onChange={handleCheckboxChange}
                                                />

                                            {plugin && plugin.documentation && plugin.documentation.termsAndConditions &&
                                                <label className={isFormError_h(errors["terms"]) ? styles['error'] : ""}>I agree to the <a className={styles["link"]} href={plugin.documentation.termsAndConditions} target="_blank">Terms of Service</a></label>
                                            }
                                                
                                            </Form.Group>
                                        }
                                        {/* Terms and Conditions  */}
                                    </Grid.Column>
                                    
                                    {/* subscription summary  */}
                                    <GridColumn 
                                        mobile={16} 
                                        tablet={16} 
                                        computer={8} 
                                        largeScreen={8} 
                                        widescreen={8}
                                    >
                                        <SubscriptionSummary 
                                            subscriptionSummary= { subscriptionSummary }
                                            frequency={frequency}
                                        />
                                    </GridColumn>
                                    {/* subscription summary  */}

                                </Grid>
                                    
                                    
                                {/* submit buttons  */}
                                <div className={styles["submit-btns"]}>
                                    <div>
                                        <Button
                                            fluid
                                            color="teal"
                                            onClick={handlePaymentModal}
                                        >
                                            {userHasPaymentProfile ? "Edit" : "Enter"} Payment Profile
                                        </Button>
                                    </div>
                                    <div className={styles["margin-top"]}>
                                        <Button
                                            fluid
                                            primary
                                            loading={isSubmitLoading}
                                            disabled={!userHasPaymentProfile && !subscriptionSummary.bypassPaymentProfile}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                                {/* submit buttons  */}



                                    <Message
                                        error
                                        header='Error'
                                        list={apiErrors}
                                    />
                                </SectionGroup>
                            </Grid.Column>
                        </Grid>
                    </Form>
                }
                {/* Pricing and Subscription Form  */}
                


                {/* success content (user documentation for plugin etc.)  */}
                {submittedSuccess &&
                    <Grid centered>
                        {/*Main Map form*/}
                        <Grid.Column mobile={16} tablet={10} computer={5} largeScreen={5} widescreen={5}>
                            <div className={styles["success-wrapper"]}>

                                <Icon size={"huge"} name={"check circle"} color={"teal"} />
                                <h2 className={styles['page-header']}>Congratulations!</h2>

                                <div className="mb-medium" />

                                <p className={styles['user-success-msg']}>
                                You have successfully {userIsEditingExistingConfig ? "updated" : "installed"} the "{plugin.name}" plugin. View documentation for this plugin by clicking the document below, or navigating to the "My Plugins" section of Oasis in your navigation bar at the top of this site to view the user manual and/or video tutorials associated with this plugin.                                  
                                </p>

                                {/* documentation  */}
                                <div className="mb-small" />
                                <RelatedDocumentation
                                    userManualUrl={plugin && plugin.documentation.userManual}
                                    // videoUrl={plugin && plugin.documentation.video}
                                />
                                {/* documentation  */}



                                {/* Success Messages and Insall Buttons - Do Not Show on Edit Configuration  */}
                                {!userIsEditingExistingConfig &&
                                    <>
                                        {/* plugin url installation */}
                                        {plugin.pluginButtonName &&
                                            <>
                                                <div className="mb-small" />
                                                <p className={`${styles['user-success-msg']}`}>
                                                    This plugin allows you to install a button to launch this plugin from your Quickbase application.  Click the button below to install this now, or you can visit the "My Plugins" section of this site to install it there at a later time.
                                                </p>
                                                <div>
                                                    <Button
                                                        primary
                                                        onClick={() => setPluginInstallPopupOpen(true)}
                                                    >
                                                        Install Launch Plugin Button
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {/* plugin url installation */}


                                        {/* author success message  */}
                                        {plugin.installSuccessMessage && plugin.installSuccessMessage.length > 0 &&
                                            <>
                                                <Header as="h3">
                                                    Message from Plugin Author
                                                </Header>
                                                <div className={`mb-small ${styles['user-success-msg']}`}>
                                                    {plugin.installSuccessMessage &&
                                                        plugin.installSuccessMessage.map((msg, i) =>
                                                            <p key={i}>
                                                                {msg}
                                                            </p>
                                                        )

                                                    }
                                                </div>
                                            </>
                                        }
                                        {/* author success message  */}

                                        {plugin.pluginUrl &&
                                            <Button primary onClick={() => setLinkCopy(plugginUrlGenerator_h(configId, plugin))}>
                                                {isCopied && <Icon name={"check circle outline"} />}
                                                    Copy Plugin URL to Clipboard
                                            </Button>
                                        }
                                    </>
                                }
                                {/* Success Messages and Insall Buttons - Do Not Show on Edit Configuration  */}
                                
                                <div className="mb-large" />
                            </div>
                        </Grid.Column>
                    </Grid>
                }
                {/* success content  */}


            </Container>
        </>
    );

}

export default ConfigurationSuccess;