/**
 * File houses regex validations for form validation errors.  This works with react hook form
 */


/**
 * Email Regex validation
 */
export const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

/**
 * Phone Regex validation
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const phoneRegex = new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);

/**
 * Credit card expiration regex validation
 */
export const creditCardExpirationRegex = new RegExp(/^(0[1-9]|1[0-2])\d{2}$/);
