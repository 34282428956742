import React from 'react';

//semantic
import {Icon} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

/**
 * Used for the custom label found in dropdowns for adding new content.
 * @param {Object} props React component props object
 * @param {string} props.type Either "table" or "field"
 */
function DropdownAdditionLabel({type}) {
    // const name = type==="table" ? "Table" : "Field";
    return (
        <span className={styles['content-styling']}><Icon name="add" color="red"/>Add New:  </span>
    );

}

export default DropdownAdditionLabel;