/**
* Connections API calls
*/

import client from '../index';

/**
 * Object used to send to verify a promotion
 * @typedef {Object} SubscriptionPromotion
 * @property {String} code The promotion code in question
 * @property {String} pluginId The plugin associated with the promotion/subscription 
 * @property {Object} subscription The subscription in question
 * @property {String} subscription.frequency The subscription frequency.  Example - tier1_annually for tier based or "monthly" for non-tier based plugins
 */

/**
 * Response object from this endpoint
 * @typedef {Object} VerifyPromotionResponse
 * @property {Boolean} validPromotion True/false on whether the promotion is valid or not.
 * @property {Number} subscriptionPrice The price of the subscriptin after discount is applied.
 * @property {Number} discount Discount of the promotion
 * @property {Number} basePrice Base price of the subscription
 * @property {String} details If the promotional code is invalid, gives a brief overview of why.
 *
 */

/**
 * Adds a new configuration to the database.
 * @param {SubscriptionPromotion} subscriptionPromotion The configuration object.
 * @return {Promise<VerifyPromotionResponse>} Responds with the title, _id, and description of the configuration
 */
export function createVerifyPromotion( subscriptionPromotion ) {
    return client.service('promotion-verify').create(subscriptionPromotion);
}