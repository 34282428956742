import React from 'react';
import {
    Switch,
    useLocation,
    Route
} from "react-router-dom";



/**
 * LANDING PAGE ROUTES ********************************************
 */
//custom components
import LandingPageLayout from "../layouts/LandingPageLayout/LandingPageLayout";
import RouteLayout from "../hoc/RouteLayoutWrapper/RouteLayoutWrapper";
//page content
import LoginForm from "../components/LoginForm/LoginForm";
import Signup from "../components/Signup/Signup";
import ResetReq from "../components/ResetReq/ResetReq";
import AccountConfirmation from "../components/AccountConfirmation/AccountConfirmation";
import UpdatePw from "../components/UpdatePw/UpdatePw";
import ResendConfirmation from "../components/ResendConfirmation/ResendConfirmation";
import PwdReset from "../components/PwdReset/PwdReset";
import Home from "../components/Home/Home";
import NotFound from "../components/NotFound/NotFound";
import PluginDetailModal from "../components/PluginCards/Details/PluginDetailModal";



/**
 * MARKETPLACE ROUTES ************************************************
 */
//custom components
import PluginCards from "../components/PluginCards/PluginCards";
import Connection from "../components/Configuration/Connection/Connection";
import MarketplaceLayout from "../layouts/MarketplaceLayout/MarketplaceLayout";
import MapConfiguration from "../components/Configuration/MapConfiguration/MapConfiguration";
import ConfigurationSuccess from "../components/Configuration/Success/ConfigurationSuccess";
import Contact from "../components/Contact/Contact";
import MyProfile from "../components/Account/MyProfile/MyProfile";
import MyConnections from "../components/Account/MyConnections/MyConnections";
import MyInstalledPlugins from "../components/MyInstalledPlugins/MyInstalledPlugins";
import PaymentHistory from "../components/Account/PaymentHistory/PaymentHistory";
import PaymentMethod from "../components/Account/PaymentMethod/PaymentMethod";
import Subscriptions from "../components/Account/Subscriptions/Subscriptions";
import PluginTokens from "../components/Account/PluginTokens/PluginTokens";
import RatePlugin from "../components/RatePlugin/RatePlugin";
import TermsOfService from "../components/Legal/TermsOfService/TermsOfService";
import PrivacyPolicy from '../components/Legal/PrivacyPolicy/PrivacyPolicy';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';



/**
 * Used for constructing all routes for the application.  It's split into landing page routes (routes that have the landing page template) and marketplace routes (routes that have the market place template)
 * @param {Object} props 
 * @param {Boolean} props.isLoggedIn Determines whether the current user is authenticated or not  
 */
function Routes() {

    let location          = useLocation();
    let pluginModalIsOpen = location.state && location.state.pluginModalIsOpen;
    let plugin            = location.state && location.state.plugin;



    return (
        <>
            <Switch>
                {/*   
                    ***************************BEGIN LANDING PAGE ROUTES*******************************
                */}
                <RouteLayout exact
                    path="/reset/:token"
                    layout={LandingPageLayout}
                    content={PwdReset}
                />

                <RouteLayout exact
                    path="/resendconfirmation"
                    layout={LandingPageLayout}
                    content={ResendConfirmation}
                />

                <RouteLayout exact
                    path="/login"
                    layout={LandingPageLayout}
                    content={LoginForm}
                />

                <RouteLayout exact
                    path="/signup"
                    layout={LandingPageLayout}
                    content={Signup}
                />

                <RouteLayout exact
                    path="/resetpw"
                    layout={LandingPageLayout}
                    content={ResetReq}
                />

                <RouteLayout exact
                    path="/confirm/:token"
                    layout={LandingPageLayout}
                    content={AccountConfirmation}
                />

                <RouteLayout exact
                    path="/updatepw"
                    layout={LandingPageLayout}
                    content={UpdatePw}
                />

                <RouteLayout exact
                    path="/about"
                    layout={LandingPageLayout}
                    content={Home}
                />

                <RouteLayout exact
                    path="/"
                    layout={LandingPageLayout}
                    content={Home}
                />
                {/*   
                    ***************************END LANDING PAGE ROUTES*******************************
                */}









                {/*   
                    ***************************BEGIN MARKETPLACE ROUTES*******************************
                */}

                <RouteLayout exact
                    path="/contact"
                    layout={MarketplaceLayout}
                    content={Contact} />

                <RouteLayout exact
                    path="/contact/:pluginId"
                    layout={MarketplaceLayout}
                    content={Contact} />

                <RouteLayout
                    path="/marketplace/:pluginName"
                    layout={MarketplaceLayout}
                    content={PluginCards} />

                {/* Configuration  */}
                {/* select connection page for new configruation */}
                <RouteLayout exact
                    path="/config/connection/:pluginId"
                    layout={MarketplaceLayout}
                    content={Connection}
                    requiresAuth
                />
                {/* Edit existing configuration connection page  */}
                <RouteLayout exact
                    path="/edit/config/:configId/connection/:connectionId"
                    layout={MarketplaceLayout}
                    content={Connection}
                    requiresAuth
                />

                {/* Map a configuration to a new plugin subscription  */}
                <RouteLayout exact
                    path="/map/config/connection/:connectionId/plugin/:pluginId"
                    layout={MarketplaceLayout}
                    content={MapConfiguration}
                    requiresAuth
                />
                {/* Edit a pre-existing configuration  */}
                <RouteLayout exact
                    path="/edit/map/config/:configId/connection/:connectionId"
                    layout={MarketplaceLayout}
                    content={MapConfiguration}
                    requiresAuth
                />
                {/* Configuration Success/Subscription Signup  */}

                <RouteLayout exact
                    path="/config/success/:pluginId/:configId"
                    layout={MarketplaceLayout}
                    content={ConfigurationSuccess}
                    requiresAuth
                />
                {/* Edit configuration success  */}
                <RouteLayout exact
                    path="/edit/config/success/:pluginId/:configId"
                    layout={MarketplaceLayout}
                    content={ConfigurationSuccess}
                    requiresAuth
                />

                {/* Configuration  */}


                {/*My Account Routes*/}
                <RouteLayout
                    exact
                    path="/account/my-profile"
                    layout={MarketplaceLayout}
                    content={MyProfile}
                    requiresAuth
                />

                <RouteLayout
                    exact
                    path="/account/my-connections"
                    layout={MarketplaceLayout}
                    content={MyConnections}
                    requiresAuth />


                <RouteLayout exact
                    path="/account/my-apps"
                    layout={MarketplaceLayout}
                    content={MyInstalledPlugins}
                    requiresAuth
                />

                <RouteLayout exact
                    path="/account/my-payments"
                    layout={MarketplaceLayout}
                    content={PaymentHistory}
                    requiresAuth
                />

                <RouteLayout exact
                    path="/account/payment-method"
                    layout={MarketplaceLayout}
                    content={PaymentMethod}
                    requiresAuth
                />

                <RouteLayout exact
                    path="/account/subscriptions"
                    layout={MarketplaceLayout}
                    content={Subscriptions}
                    requiresAuth
                />

                <RouteLayout exact
                    path="/account/tokens/:configuration"
                    layout={MarketplaceLayout}
                    content={PluginTokens}
                    requiresAuth
                />

                <RouteLayout exact
                    path="/account/rating/:pluginId"
                    layout={MarketplaceLayout}
                    content={RatePlugin}
                    requiresAuth
                />
                {/*My Account Routes*/}


                {/*Legal*/}
                <RouteLayout exact
                    path="/legal/terms-of-use"
                    layout={MarketplaceLayout}
                    content={TermsOfService}
                />
                <RouteLayout exact
                    path="/legal/privacy-policy"
                    layout={MarketplaceLayout}
                    content={PrivacyPolicy}
                />
                <RouteLayout exact
                    path="/video/:id"
                    layout={MarketplaceLayout}
                    content={VideoPlayer}
                />
                {/*Legal*/}
                

                {/*   
                    ***************************END MARKETPLACE ROUTES*******************************
                */}





                {/* CATCH ALL ROUTE  */}
                <RouteLayout
                    layout={MarketplaceLayout}
                    content={NotFound}
                />
            </Switch>

            {/* Show the modal when a background page is set */ }
            {pluginModalIsOpen && <Route path="/marketplace/:pluginName" children={<PluginDetailModal plugin={plugin}/>} /> }
        </>
    );
}

export default Routes;