import authSagas from './authSagas';
import connectionSagas from './connectionSagas';
import pluginsSagas from './pluginsSagas';
import { all } from 'redux-saga/effects';
import configurationSagas from "./configurationSagas";
import qbTableSagas from "./qbTableSagas";
import qbFieldSagas from "./qbFieldSagas";

export default function* rootSaga() {
    yield all([
        ...authSagas,
        ...connectionSagas,
        ...configurationSagas,
        ...qbTableSagas,
        ...qbFieldSagas,
        ...pluginsSagas
    ]);
}