/**
* Tokens API Calls
*/

import client from '../index';

/**
 * Finds all the tokens for a given configuration
 */
export function findTokens( configuration_id ) {
    return client.service('tokens').find({
        query: {
            configuration_id
        }
    });
}



/**
 * Deletes a token
 * @param {Object} token Token Object
 * @param {string} token._id ID of the token to be removed
 * @return {Promise<Object>} Deleted token
 */
export function removeToken(token) {
    return client.service('tokens').remove(token._id);
}


/**
 * Creates a new token for a particular configuration
 * @param {Object} token configuration to be created
 * @param {string} token.configuration_id ID of configuration associated with this token
 * @param {string} token.name The name for this particular token
 * @return {Promise<Object>} Created token
 */
export function createToken(token) {
    return client.service('tokens').create(token);
}


