import React from 'react';

//semantic
import {
    Segment, Accordion, Grid, Icon, Label, Button, Table, Header, Container
} from 'semantic-ui-react';


//styles
import styles from './index.module.css';


// Sub components
import Account from "../Account";
import PaymentNav from "../PaymentNav/PaymentNav"


function TimelineItems({ children, color }) {
    return <div className={styles['timeline-block']}>
        <div className={styles['marker']} style={{ background: color }} />
        <div className={styles['timeline-content']}>
            {children}
        </div>
    </div>
}

/**
 *
 * This component showcases the end users billing/payment history.
 * @returns {*}
 * @constructor
 */
function PaymentHistory() {

    const [activeIndex, setActiveIndex] = React.useState(null);
    

    return (
        <>

            <Account pageTitle={"Payment History"} helpPopup={"Help is here"}>

                <Grid>

                    <Grid.Row>

                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={12} widescreen={12}>

                            <PaymentNav
                                activeItem="Payment History"
                            />

                            <Container className={styles['timeline-container']}>

                                <TimelineItems key={0} color={"#135761"}>

                                    <Segment className={"mb-small"}>
                                        <Accordion>
                                            <Accordion.Title
                                                active={activeIndex === 0} /// later zero will be dynamic here
                                                index={0}
                                                onClick={() => setActiveIndex(activeIndex === 0 ? null : 0)}
                                            >
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column mobile={2} tablet={2} computer={1} largeScreen={1}
                                                            widescreen={1}>
                                                            <Icon name='dropdown' />
                                                        </Grid.Column>
                                                        <Grid.Column mobile={14} tablet={10} computer={11}
                                                            largeScreen={11}
                                                            widescreen={11}>
                                                            <div>
                                                                <Label circular className={styles['status-label']}
                                                                    color={"green"}>
                                                                    &nbsp; <Icon name={"check circle"} /> Success &nbsp;
                                                                </Label>
                                                                <Header className={styles['total-amount']} size='tiny'
                                                                    color={"green"}>$700</Header>
                                                            </div>
                                                            <div className={styles['trx-details']}>
                                                                <ul>
                                                                    <li>03/12/2020</li>
                                                                    <li>TXN ID PUBH76543HGHSAS</li>
                                                                </ul>
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column textAlign={"right"} mobile={16} tablet={4}
                                                            computer={4}
                                                            largeScreen={4} widescreen={4}>
                                                            <Button size={'tiny'} compact basic
                                                                content={"Raise Dispute"} circular />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 0}>
                                                <Table basic='very'>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Plugin Name</Table.HeaderCell>
                                                            <Table.HeaderCell>Pay Period</Table.HeaderCell>
                                                            <Table.HeaderCell textAlign={"right"}>Cost/Pay
                                                                Period</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>Geolocate</Table.Cell>
                                                            <Table.Cell>Annually</Table.Cell>
                                                            <Table.Cell textAlign={"right"}>$200.00</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>Word Fusion</Table.Cell>
                                                            <Table.Cell>Monthly</Table.Cell>
                                                            <Table.Cell textAlign={"right"}>$300.00</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>File Fusion</Table.Cell>
                                                            <Table.Cell>Monthly</Table.Cell>
                                                            <Table.Cell textAlign={"right"}>$200.00</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Accordion.Content>

                                        </Accordion>

                                    </Segment>

                                </TimelineItems>

                                <TimelineItems key={1} color={"orange"}>

                                    <Segment>
                                        <Accordion>
                                            <Accordion.Title
                                                active={activeIndex === 1} /// later zero will be dynamic here
                                                index={1}
                                                onClick={() => setActiveIndex(activeIndex === 1 ? null : 1)}
                                            >
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column mobile={2} tablet={2} computer={1} largeScreen={1}
                                                            widescreen={1}>
                                                            <Icon name='dropdown' />
                                                        </Grid.Column>
                                                        <Grid.Column mobile={14} tablet={10} computer={11}
                                                            largeScreen={11}
                                                            widescreen={11}>
                                                            <div>
                                                                <Label circular className={styles['status-label']}
                                                                    color={"yellow"}>
                                                                    &nbsp; <Icon name={"check circle"} /> Error &nbsp;
                                                                </Label>
                                                                <Header className={styles['total-amount']} size='tiny'
                                                                    color={"green"}>$700</Header>
                                                            </div>
                                                            <div className={styles['trx-details']}>
                                                                <ul>
                                                                    <li>02/12/2020</li>
                                                                    <li>TXN ID PUBH76543HGHSAS</li>
                                                                </ul>
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column textAlign={"right"} mobile={16} tablet={4}
                                                            computer={4}
                                                            largeScreen={4} widescreen={4}>
                                                            <Button size={'tiny'} compact basic
                                                                content={"Raise Dispute"} circular />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 1}>
                                                <Table basic='very'>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Plugin Name</Table.HeaderCell>
                                                            <Table.HeaderCell>Pay Period</Table.HeaderCell>
                                                            <Table.HeaderCell textAlign={"right"}>Cost/Pay
                                                                Period</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>Word Fusion</Table.Cell>
                                                            <Table.Cell>Monthly</Table.Cell>
                                                            <Table.Cell textAlign={"right"}>$300.00</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>File Fusion</Table.Cell>
                                                            <Table.Cell>Monthly</Table.Cell>
                                                            <Table.Cell textAlign={"right"}>$200.00</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Accordion.Content>

                                        </Accordion>

                                    </Segment>

                                </TimelineItems>

                            </Container>

                        </Grid.Column>

                    </Grid.Row>

                </Grid>

            </Account>

        </>
    );

}

export default PaymentHistory;