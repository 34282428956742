/**
* Inquiries API Calls
*/

import client from '../index';



/**
 * @typedef {Object} InquiryObject
 * @property {string} name name of the user
 * @property {string} subject subject for the inquiry
 * @property {string} message message for the inquiry
 * @property {string} email email for the inquiry
 * @property {Object} plugin_id The Plugin ID - only used when you want to contact an author.
 * @property {Object} user_id The user id
 */


/**
 * Creates an inquiry record for a user.
 * @param {InquiryObject} inquiry the inquiry object in question.
 * @return {Promise<InquiryObject>} responds with inquiry object that was created
 */
export function createInquiry( inquiry ) {
    return client.service('inquiries').create( inquiry );
}