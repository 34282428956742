import {useState, useEffect} from 'react';

/**
 * Custom hook that give us screen height and width for responsive ui alignments
 * @returns {{width: (Window.innerWidth|null), height: (Window.innerHeight|null)}}
 */
function useWindowDimensions() {

    const hasWindow = typeof window !== 'undefined';

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(hasWindow));

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions(hasWindow));
            };

            window.addEventListener('resize', handleResize);

            // Clear the event listner on unmount
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return windowDimensions;
}


function getWindowDimensions (hasWindow) {
    const screenWidth = hasWindow ? window.innerWidth : null;
    const screenHeight = hasWindow ? window.innerHeight : null;
    return {
        screenWidth,
        screenHeight,
    };
}

export default useWindowDimensions;