
import client from '../index';


/**
 * Used to get all Quick Base applications that a user has access to.
 * @param connection Connection ID of the connection used to obtain the quick base applications.
 * @return {Array.<{dbname: String, dbid: String}>} Returns an array of objects with properties "dbname" and "dbid" - example [{"dbname": "Ryan Test", "dbid":"bkk232d3"}]
 */
export function getQbApps(connection) {
    return client.service('qb-apps').find({
        query: {
            connection
        }
    });
}