/**
* Defines all "plugins" store actions
*/
import * as types from '../types';



/************************************************
 * GET ACTIONS
 ************************************************/

export const getPlugins = () => {
    return {
        type: types.GET_PLUGINS
    }
};

export const getPluginsFailure = payload => {
    return {
        type: types.GET_PLUGINS_FAILURE,
        payload
    };
};

export const getPluginsSuccess = payload => {
    return {
        type: types.GET_PLUGINS_SUCCESS,
        payload
    };
};


/************************************************
 * GET SINGLE ACTIONS
 ************************************************/

export const getPlugin = payload => {
    return {
        type: types.GET_PLUGIN,
        payload
    }
};

export const getPluginFailure = payload => {
    return {
        type: types.GET_PLUGIN_FAILURE,
        payload
    };
};

export const getPluginSuccess = payload => {
    return {
        type: types.GET_PLUGIN_SUCCESS,
        payload
    };
};

