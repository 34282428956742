import React from 'react';

//semantic
import {
    Grid,
    Header,
    Icon,
    Container
} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';

function NotFound() {

    return (
        <>
            <div className="mb-medium" />
            <Grid centered>
                <Grid.Row>
                    {/*Main Map form*/}
                    <Grid.Column mobile={16} table={16} computer={8} largeScreen={6} widescreen={6}>
                        <Header as='h2' icon color="red" textAlign='center'>
                            <Icon color="red" name='exclamation circle' circular />
                            <Header.Content>PAGE NOT FOUND</Header.Content>
                        </Header>
                        <div className="mb-medium" />
                        <Container textAlign='center'>
                            The page you are looking for was not found!
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );

}

export default NotFound;