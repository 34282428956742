/**
* Defines all config object store actions
*/
import * as types from '../types';


/************************************************
 * CREATE ACTIONS
 ************************************************/

/**
 * Create Configuration action
 * @param payload
 * @return {{payload: *, type: string}}
 */
export const createConfiguration = (payload) => {
    return {
        type: types.CREATE_CONFIGURATION,
        payload
    };
};

/**
 *
 * @param {object} payload
 * @return {{payload: *, type: string}}
 */
export const createConfigurationFailure = (payload) => {
    return {
        type: types.CREATE_CONFIGURATION_FAILURE,
        payload
    };
};


export const createConfigurationSuccess = (payload) => {
    return {
        type: types.CREATE_CONFIGURATION_SUCCESS,
        payload
    };
};


/************************************************
 * GET ACTIONS
 ************************************************/

/**
 * Get Configuration action
 * @param payload
 * @return {{payload: *, type: string}}
 */
export const getConfiguration = (payload) => {
    return {
        type: types.GET_CONFIGURATION,
        payload
    };
};

/**
 *
 * @param {object} payload
 * @return {{payload: *, type: string}}
 */
export const getConfigurationFailure = (payload) => {
    return {
        type: types.GET_CONFIGURATION_FAILURE,
        payload
    };
};

/**
 * On get Configuration success
 * @param payload
 * @return {{payload: *, type: string}}
 */
export const getConfigurationSuccess = (payload) => {
    return {
        type: types.GET_CONFIGURATION_SUCCESS,
        payload
    };
};



/************************************************
* DELETE ACTIONS
************************************************/



