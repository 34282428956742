
/**
 * Returns mm/dd/yyyy date format from string returned from API.
 * @param {String} date Takes date string from the server and returns formatted mm/dd/yyyy date
 */
export const formattedDate_h = ( date )=>{
    if (!date) return '';

    date = new Date(date);
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
};



/**
 * Gets UTC Time from a date string
 * @param {String} date Takes date string and converts UTC time
 */
export const formatDateToUnixTimeStamp_h = ( date )=>{
    if (!date) return '';
    return new Date(date).toISOString();
}; 


/**
 * Given a date object, adds a certain number of days and returns a formatted string date.
 * @param {Object} date Date object
 * @param {Number} days Number of days
 * @return {String} returns formatted mm/dd/yyyy date object
 */
export const addDaysToDateFormatted_h = (date, days = 0)=>{
    if(!date) return '';
    date.setDate(date.getDate() + days);
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
};