import React from 'react';

//semantic
import {Modal, Icon, Header} from 'semantic-ui-react';

//custom
import PaymentCapture from "../../components/PaymentCapture/PaymentCapture";



//styles
import styles from './index.module.css';



/**
 * 
 * @param {Object} props React props object.
 * @param {Boolean} open Dictates whether modal is open or closed
 * @param {Function} paymentSuccessHandler Success handler
 * @param {Function} toggleModal Pass a boolean to open/close modal
 */
function PaymentModal({ open=true, paymentSuccessHandler=null, toggleModal }) {

    /*************************************************
     *  State
     *************************************************/

    const onPaymentSuccess = e =>{
        paymentSuccessHandler();
    };

    return (
        <Modal 
            closeIcon
            open={ open }
            onClose={() => toggleModal(false)}
        >
            <Modal.Content className={styles["modal-content"]}>
                <Modal.Description>
                    {/* <Message info><Icon color="teal" name="warning circle" />It looks like you currently don't have a payment set up.  Please fill out the form below to proceed.</Message> */}

                    {/* <div className={styles["payment-header"]}>
                         <span className={styles["payment-profile"]}>Payment Profile</span>
                    </div> */}
                    <div className={styles["payment-header"]}>
                        <Header as='h2' textAlign="center">
                            <Icon.Group size='large'>
                                <Icon name='payment' color="teal" />
                                <Icon corner name='add' />
                            </Icon.Group>
                            <span className={styles["payment-profile"]}>Payment Profile</span>
                        </Header>
                    </div>
                    
                    
                    {/* Promotional Codes  */}
                    {/* <SectionGroup
                        title="Promotional Code"
                        description="If you have a promotional code for a free plugin, you can bypass this screen without entering your payment information by entering the promotional code below.  If you do not have a promotional code for a free plugin, please enter your payment details in the sections below (you will not be billed until your free trial has expired)."
                    >
                        <PromotionInput />
                    </SectionGroup> */}
                    {/* Promotional Codes  */}

                    <PaymentCapture 
                        successHandler={onPaymentSuccess}
                    />
                </Modal.Description>

            </Modal.Content>
        </Modal>
    );

}

export default PaymentModal;