/**
* Defines all config object store actions
*/
import * as types from '../types';


/************************************************
 * GET ACTIONS
 ************************************************/

/**
 * Create QB tables action
 *
 * @param {string} payload Connection ID
 * @return {{payload: *, type: string}}
 */
export const getQbTables = (payload) => {
    return {
        type: types.GET_QB_TABLES,
        payload
    };
};


export const getQbTablesFailure = payload => {
    return {
        type: types.GET_QB_TABLES_FAILURE,
        payload
    };
};


export const getQbTablesSuccess = payload => {
    return {
        type: types.GET_QB_TABLES_SUCCESS,
        payload
    };
};



/************************************************
 * CREATE ACTIONS
 ************************************************/

export const createQbTable = (payload) => {
    return {
        type: types.CREATE_QB_TABLE,
        payload
    };
};


export const createQbTableFailure = payload => {
    return {
        type: types.CREATE_QB_TABLE_FAILURE,
        payload
    };
};


export const createQbTableSuccess = payload => {
    return {
        type: types.CREATE_QB_TABLE_SUCCESS,
        payload
    };
};


