import React from 'react';

//semantic
import {} from 'semantic-ui-react';

//styles
import styles from './index.module.css';


/**
 * Homepage layout titles - light blue color on all of the homepages.
 * @param {Object} props Component props
 * @param {string} props.title Title text to show to the user
 */
function HomePageTitle({ title }) {

    return (
        <>
            {/* call to action */}
            < div className={styles["call-to-action"]} >
                { title }
            </div >
            {/* call to action */}
        </>
    );

}

export default HomePageTitle;