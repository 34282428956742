import React from 'react';


//images
import logo from "./images/oasis-appsuite-quickbase-plugins.png";

//styles
import styles from './index.module.css';

//router
import {NavLink, Link} from "react-router-dom";

import { useSelector } from "react-redux";

//custom components
import SidebarMenu from './SidebarMenu/SidebarMenu';


/**
 *  This is the header componetns that contains the navigationo, logo and hamburger icon.  Utilized on both
 * "LandingPageLayout" and "InternalLayout"
 */

/**
 *
 * @param {object} props Component Props
 * @param {boolean} props.internalLayout Set true if is included in MarketplaceLayout.
 */
function Header({internalLayout = ''}) {

    /*************************************************
    *  Redux
    *************************************************/
    //get if the user is logged in
    // const dispatch = useDispatch();
    //get logged in user from the redux store auth state.
    const { isLoggedIn } = useSelector(state => state.auth);


    return (
        <header className={internalLayout && styles['header--inner-pages']}>
            <Link to="/" className={styles["logo"]}>
                <div>
                    <img src={logo} alt="Quickbase Plugins Background" />
                </div>
            </Link>
            
            <div className={styles["links"]}>
                <nav className={styles["navigation-menu"]}>
                    <ul className={styles["navigation-menu--list"]}>
                        <NavLink
                            to="/about"
                            className={`${styles["nav-item"]}`}
                            activeClassName={`${styles["links--active"]}`}
                        >
                            ABOUT
                        </NavLink>
                        <NavLink
                            to="/marketplace/all-plugins"
                            className={`${styles["nav-item"]}`}
                            activeClassName={`${styles["links--active"]}`}
                        >
                            PLUGINS
                        </NavLink>

                        {!isLoggedIn &&
                            <NavLink
                                to="/login"
                                className={`${styles["nav-item"]}`}
                                activeClassName={`${styles["links--active"]}`}
                            >
                                LOGIN
                            </NavLink>
                        }

                        {isLoggedIn &&
                            <NavLink
                                to="/account/my-apps"
                                className={`${styles["nav-item"]}`}
                                activeClassName={`${styles["links--active"]}`}
                            >
                                MY PLUGINS
                            </NavLink>
                        }
                        
                        <NavLink
                            to="/contact"
                            className={`${styles["nav-item"]}`}
                            activeClassName={`${styles["links--active"]}`}
                        >
                            CONTACT
                        </NavLink>
                    </ul>
                </nav>
            </div>

            <SidebarMenu />

        </header >
    );

}

export default Header;