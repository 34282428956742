//TODO: This could be in a separate container folder

import React, {useState, useEffect} from "react";

// Styles
import styles from "./index.module.css";


//Components
import {Modal, Form, Button, Grid, Message } from "semantic-ui-react";

//react hook form
import { useForm } from 'react-hook-form';

//redux
import * as actions from '../../store/actions/connectionActions';
import * as modalActions from '../../store/actions/modals';
import { useDispatch, useSelector } from "react-redux";
// import { isFormError_h } from '../../utils/formHelpers';
import { findConnectionConfigs } from "../../feathers/services/connectionConfigs";
import ConnectionSubscriptionsWarning from "../ConnectionSubscriptionsWarning/ConnectionSubscriptionsWarning";
import RelatedDocumentation from "../RelatedDocumentation/RelatedDocumentation";
import ConnectionField from "./ConnectionField/ConnectionField";
import { generateFormErrorsArray_h, formHasErrors_h } from "../../utils/formHelpers";


/**
 * @typedef {Object} ConnectionConfigField
 * @property {string} type one of text | password | textarea | numeric | dropdown
 * @property {string} label label that shows on fields input element 
 * @property {string} name The name used for form submittal/object behind the scenes - must be unique among all other fields configs.
 * @property {string} userHelpText help text for a user that shows on icon hover on the field label.
 * @property {boolean} required is a required field for form submittal
 * @property {string} stripValueForSecurity IF this is true, then the server will strip this auth/password from a response before it's returned to the front end (only allows server-side passing of this encrypted value).
 * @property {string} defaultValue the default value fo the field in question
 * @property {string} leftLabel Allows a left label to be used for input elements - for instance "$"
 * @property {string} rightLabel Allows a right label to be used for input elements - for instance "quickbase.com"
 * @property {string} icon What font awesome icon should show
 * @property {string} iconPosition One of right | left.  Shows where the icon should be positioned
 * @property {boolean} sensitive Determines whether or not this field should be encrypted in the database. 
 */

/**
 * The complete Triforce, or one or more components of the Triforce.
 * @typedef {Object} ConnectionConfiguration
 * @property {string} type type of connection in question - example "Quick Base"
 * @property {string} description connection description
 * @property {boolean} isActive Is an active connection configuration
 * @property {string} tutorialVideo Youtube ID of tutorial video in question
 * @property {Object} config contains a property "fields" which is an array of the field configurations for this connection config
 * @property {Array.<ConnectionConfigField>} config.fields the field connection configs used.
 */

/**
 * Add new connection form modal.
 * @param {Object} props - Component props
 * @param {object} props.trigger JSX node like button to trigger modal open.
 * @param {boolean} props.openModal Optional parameter to trigger modal
 * @param {object} props.existingconnection Existing connection that needs to be edited/updated.
 * @param {object} props.handleClose Function to run on close of modal
 * @param {String} props.type The type of configuration.  This value is housed in the connection configurations database.  "Quick Base", "Dropbox" etc. are examples
 */
function ConnectionAdd({ trigger, existingconnection = null, handleModalClose, type}) {


    /*************************************************
     *  React Hook Form
     *************************************************/    
    var { register, handleSubmit, errors, setValue, reset, getValues } = useForm();

    

    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    //error here is from an API call error when calling connections service
    const { isLoading, error }       = useSelector(state => state.connections);
    const { createConnectionIsOpen } = useSelector(state => state.modals);
    //current user logged in
    // const { user }                   = useSelector(state => state);
    




    /*************************************************
     *  State
     *************************************************/

    //set state
    const [errorMsgText, setErrorMsgText]                             = useState("There was an error processing this request.  Please fill in all required fields above with a red asterisk.");
    /**
     * @type {[ConnectionConfiguration, ConnectionConfiguration]}
     */
    const [connectionConfiguration, setConnectionConfiguration]       = useState('');
    const [loadingForConnectionConfig, setLoadingForConnectionConfig] = useState(true);

    

    /*************************************************
     *  Button and Modal Handlers
     *************************************************/
    //sets modal state to open and clears any existing API call errors from redux state.
    const handleOpen = () => { 
        dispatch(modalActions.setCreateModalState(true)); 
        dispatch(actions.clearConnectionError());
    };
    
    //handle modal close
    const handleClose = ()=>{
        reset();
        setConnectionConfiguration('');
        dispatch(modalActions.setCreateModalState(false));
        dispatch(actions.clearConnectionError());
        if (handleModalClose) {
            handleModalClose();
        }
    };

    /*************************************************
     *  Effects
     *************************************************/

    //since redux controls this component - when the createConnectionModal is changed by redux, make sure to reset the state (for instance, when a form submittal is successful)
    useEffect(() => {
        if(!createConnectionIsOpen) {
            handleClose();
        }
    }, [createConnectionIsOpen]);

    //get connection configuration
    useEffect(()=>{
        if (createConnectionIsOpen) {
            setLoadingForConnectionConfig(true);
            findConnectionConfigs({ query: { type, isActive: true } }).then((res) => {
                const connectionConfig = res.data[0];
                setConnectionConfiguration(connectionConfig);
                setLoadingForConnectionConfig(false);
            }).catch((err) => {
                setErrorMsgText('There was an error processing this configuration.  Please refresh and try again.  If the problem persists, please contact support with a screenshot.');
                setLoadingForConnectionConfig(false);
            });
        }
    }, [type, createConnectionIsOpen]);

    // Updates form state on page load.  This sets all default values for an existing connection in react hook form.
    useEffect(() => {
        //utilize existing connection and set values if present
        if (existingconnection && connectionConfiguration) {
            let { auth } = existingconnection;
            auth = {...auth};
            setValue('title', existingconnection.title);
            setValue('description', existingconnection.description);

            //set only non-sensitive values - sensitive values require a user to enter them again.
            let { fields } = connectionConfiguration.config;
            Object.keys(auth).forEach(key => {
                let configFieldSettings = fields.find(field => field.name === key);
                if (configFieldSettings && !configFieldSettings.sensitive) {
                    setValue(key, auth[key]);
                }
            });
        }
    }, [existingconnection, setValue, connectionConfiguration]);

    
    
    //if there was an API call error - set the error text
    useEffect(()=>{
        setErrorMsgText(error);
    },[error, dispatch]);


    /*************************************************
     *  Handlers
     *************************************************/
    const handleTutorialClick = (id)=>{
        window.open(window.location.origin + `/video/${id}`, '_blank', 'toolbar=0,location=0,menubar=0');
    };

    /*************************************************
     *  Form Handlers
     *************************************************/

    //handles submittal of the form
    const onSubmit = formData => {
        var auth = {...formData};
        const { title, description } = auth;

        //remove title and description from object
        delete auth.title;
        if(auth.description) {
            delete auth.description;
        }

        const connection = {
            type,
            title,
            description,
            auth
        };
        //clear any previous error message text
        setErrorMsgText("");
        //clear any previous api call errors
        dispatch(actions.clearConnectionError());
        //dispatch the action to create teh new connection record
        if( !existingconnection ) {
            dispatch(actions.createConnection(connection));
        } else {
            //set connection id
            connection._id = existingconnection._id;
            dispatch(actions.patchConnection(connection));
        }
    };

    return (
        <Modal 
            open={ createConnectionIsOpen } 
            size={"small"} 
            onOpen={handleOpen} 
            onClose={handleClose} 
            trigger={trigger}
        >

            <Modal.Header className={styles['modal-header']}>

                    {connectionConfiguration && connectionConfiguration.tutorialVideo &&
                        <div className={styles['video-tutorial']}>
                            <RelatedDocumentation
                                onShowVideoClick={handleTutorialClick}
                                videoUrl={connectionConfiguration.tutorialVideo}
                                videoLabel="Not sure how to complete this connection form?  Click here to watch a step-by-step video tutorial."
                            />
                        </div>
                    }
                    
                    <span className={styles['input-form--label']}>{existingconnection ? "Edit Connection":"New Connection"}</span>
                {/* <Popup
                    content='A connection allows Oasis to securely connect to different services such as Quickbase.'
                    trigger={<Icon disabled name='help circle' color='grey' />} />  */}
                <div className={styles['connection-type--header']}>{connectionConfiguration && connectionConfiguration.type ? `${connectionConfiguration.type} Connection` : ''}</div>
            </Modal.Header>

            <Modal.Content>

                <Modal.Description>
                        {/* show subscriptions associated with this particular connection */}
                        {existingconnection &&
                            <ConnectionSubscriptionsWarning 
                                connection={existingconnection}
                            />
                        }
                        {/* show subscriptions associated with this particular connection */}
                        
                        {!errorMsgText && connectionConfiguration && connectionConfiguration.tutorialVideo &&
                            <span className={styles['video-icon--help']}>Need Help?  Click the video icon above.</span>
                        }

                        {/* error msgs fro non-form validation errors  */}
                        {errorMsgText &&
                            <Grid centered>
                                <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={10} widescreen={10}>
                                    <Message
                                        icon="warning"
                                        error={true}
                                        content={errorMsgText}
                                    />
                                </Grid.Column>
                            </Grid>
                        }
                        {/* error msgs fro non-form validation errors  */}
                    <Form   
                        loading={loadingForConnectionConfig}
                        className={styles['input-form']}
                        onSubmit={handleSubmit(onSubmit)}
                        error={formHasErrors_h(errors)}>

                        {/* connection fields  */}
                        { connectionConfiguration && connectionConfiguration.config && connectionConfiguration.config.fields &&
                            connectionConfiguration.config.fields.map((field)=>
                                {
                                    
                                    //apply the title if there is an existing connection
                                    if(field.name === "title" && existingconnection) {
                                        field.defaultValue = existingconnection.title;
                                    //apply the description if there is an existing connection
                                    } else if (field.name === "description" && existingconnection && existingconnection) {
                                        field.defaultValue = existingconnection.description;
                                    //apply any other fields other than sensitive ones
                                    } else if (existingconnection && existingconnection.auth && existingconnection.auth[field.name] && !field.sensitive) {
                                        field.defaultValue = existingconnection.auth[field.name];
                                    } 
                                    
                                    //reset default values if there is not default connection provided
                                    if(!existingconnection) field.defaultValue = null;

                                    return(
                                        <ConnectionField
                                            field={field}
                                            register={register}
                                            errors={errors}
                                            setValue={setValue}
                                            key={field.name}
                                        />
                                    )
                                }
                            )
                        }
                        {/* connection fields  */}

                        <br/> 
                        <br/>

                        <div className={styles['modal-footer-buttons']}>
                            <Button 
                                primary 
                                loading={isLoading}>
                                    {existingconnection ? "Update" : "Create"}
                            </Button>
                            <Button onClick={handleClose}>Cancel</Button>
                        </div> 

                        <Message
                            negative
                            error={true}
                            header='There were some errors submitting this form'
                            list={generateFormErrorsArray_h(errors)}
                        />

                        <br/>
                        <br/>

                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );

}

export default ConnectionAdd;