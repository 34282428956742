import React from 'react';

//semantic
import {
    Card
} from 'semantic-ui-react';
import PluginBriefCard from "../Brief/PluginBriefCard";


//styles
import styles from './index.module.css';

/**
 * Generates the list of cards/plugins to render in the marketplace
 * @param {Object} props React component props object
 * @param {Array<Objects>} props.allPlugins The array of plugins to be used to generate a list
 * @param {filterValues} props.filterValues the values passed for searching that the user has entered in the UI
 * @param {Function} props.onFilter function used to filter/sort the plugins on the screen based on the user input
 */
function CardList({allPlugins, filterValues, onCardClick, onFilter}) {

    return (
        <>
            {/*Loop Cards Here*/}
            <div className={styles['card-wrapper']}>
                <Card.Group centered stackable>
                    {onFilter(allPlugins, filterValues).map((plugin) => <PluginBriefCard
                        key={plugin._id}
                        plugin={plugin}
                        onCardClick={onCardClick}
                    />
                    )}
                </Card.Group>
            </div>
        </>
    );

}

export default React.memo(CardList, (prevProps, nextProps) =>
    prevProps.filterValues === nextProps.filterValues &&
    prevProps.allPlugins === nextProps.allPlugins);