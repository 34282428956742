/**
* Ratings API Calls
*/

import client from '../index';

/**
 * Finds all the tokens for a given configuration
 */
// export function findTokens(configuration) {
//     return client.service('tokens').find({
//         configuration_id: configuration._id
//     });
// }



/**
 * @typedef {Object} RatingObject
 * @property {string} user_id User ID
 * @property {string} plugin_id Plugin ID
 * @property {string} rating The rating of the plugin
 * @property {Object} comment Comment the user entered
 */


 

/**
 * Creates a new rating for a plugin
 * @param {Object} rating rating object to be created
 * @param {string} rating.plugin_id ID of plugin associated with this rating
 * @param {string} rating.rating The actual rating of the rating between 0-5
 * @param {string} rating.comment The name for this particular rating
 * @return {Promise<Object>} Created rating
 */
export function createRating( rating ) { 
    return client.service('ratings').create( rating );
}


/**
 * 
 * @param {string} id Id of the rating to be patched
 * @param {ConnectionObject} rating rating object to be updated
 * @return {Promise<RatingObject>} Responds with the updated rating object
 */
export function patchRating(id, rating) {
    return client.service('ratings').patch(id, rating);
}


/**
 * Tries to find a given rating
*/
export function findRatings( query ) {
    return client.service('ratings').find({ query });
}



