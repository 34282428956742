import React from "react";

// Semantic
import {Button, Card, Icon, Image, Grid, Dropdown} from "semantic-ui-react";

import {
    Link
} from "react-router-dom";

// Custom Styles
import styles from "../index.module.css";

// Sub Components
import RelatedDocumentation from "../../RelatedDocumentation/RelatedDocumentation";
import useCopyToClipboard from "../../../utils/useCopyToClipboard";


/**
 * This component is responsible for rendering the actual cards for a users different subscriptions
 * @param {object} props react props
 * @param {object} props.subscription subscription object in question
 * @param {Function} props.onShowButtonInstallClick This is used to show/hide install subscription
 */
function InstalledPluginsCard({ subscription, onShowVideoClick, onShowButtonInstallClick }) {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [isCopied, copyToClipBoard] = useCopyToClipboard();


    //handles copying a token
    const handleCopy = (configId) => {
        copyToClipBoard(configId);
    };

    return (
        <Card>
            <Image 
                src={ subscription.plugin.thumbnail } 
                wrapped 
                ui={false}
                className={styles['card--image-img']}
            />
            <Card.Content>
                <Card.Header>
                    <Grid>
                        <Grid.Column floated='left' width={14}>
                            <div className={styles['card--related-documents']}>
                                <RelatedDocumentation
                                    userManualUrl={subscription.plugin.documentation.userManual}
                                    videoUrl={subscription.plugin.documentation.video}
                                    onShowVideoClick={onShowVideoClick}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column floated='right' width={2}>
                            <Dropdown icon="ellipsis vertical" direction="left">
                                <Dropdown.Menu>
                                    {subscription.plugin.pluginButtonName &&
                                        <Dropdown.Item text='Install Plugin Button' onClick={() => onShowButtonInstallClick(subscription)}/>
                                    }
                                    <Dropdown.Item text='Edit Configuration' as={Link} to={`/edit/config/${subscription.configuration_id}/connection/${subscription.configuration.connection_id}`} />
                                    {subscription.plugin.hasValidationTokens &&
                                        <Dropdown.Item text='Token Management' as={Link} to={`/account/tokens/${subscription.configuration_id}`} />
                                    }
                                    <Dropdown.Item text='Rate Plugin' as={Link} to={`/account/rating/${subscription.plugin_id}`}/>
                                    <Dropdown.Item
                                        onClick={()=>handleCopy(subscription.configuration_id)}
                                    >
                                        Copy Config ID to Clipboard
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Grid.Column>
                    </Grid>
                    
                    <div className={styles['card--header']}>
                        <span>{subscription.name}</span>
                    </div>
                </Card.Header>

                <Card.Meta>
                    <span className={styles['card--paragraph']}>{subscription.plugin.name} (Quickbase Plugin)</span>
                </Card.Meta>

            </Card.Content>

            <Card.Content extra>
                {/* <a href={plugginUrlGenerator_h(subscription.configuration_id, subscription.plugin)} target="_blank"> */}
                    <Link to={`/edit/config/${subscription.configuration_id}/connection/${subscription.configuration.connection_id}`}>
                        <Button color='green' fluid>
                            Edit Config &nbsp;&nbsp; <Icon name={"external alternate"} />
                        </Button>
                    </Link>
                {/* </a> */}
            </Card.Content>


        </Card>
    )
}

export default InstalledPluginsCard;