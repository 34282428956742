import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

//react router dom
import { useParams } from 'react-router-dom';

//semantic
import {
    Container, Grid, Loader, Icon, Message
} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

// Sub components
import ConfigSteps from "../ConfigSteps/ConfigSteps";
import ConnectionFilter from "../../ConnectionFilter/ConnectionFilter";
import ConnectionItem from "./ConnectionItem";
import Sidebar from "../Sidebar/Sidebar";
// import * as actions from '../../../store/actions/connectionActions';
import { debounce_h } from "../../../utils/formHelpers";
import { searchStringForMatch_h } from '../../../utils/filterSortHelpers';
// import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import ConnectionAdd from "../../ConnectionAdd/connectionAddUpdated";
import HomePageMsg from "../../HomePageMsg/HomePageMsg";

import * as modalActions from '../../../store/actions/modals';

import PopulateConnectionStore from '../../../hoc/PopulateConnectionStore/PopulateConnectionStore';

/**
 * Component is used to select a particular connection at the first step of the configuration process. 
 * @param {Object} props React component props object
 * @param {boolean} props.editExistingConfig - if set to true, this page will change directions to editing an existing configuration, instead of creating a new one.  Just a slightliy different redirect.
 */
function Connection() {

    //when a user is editing a configuration, the URL scheme has "edit" in it.
    var userIsEditingExistingConfig = window.location.href.indexOf('edit') >= 0 ? true : false;

    /*************************************************
    *  URL Params
    *************************************************/
    const params = useParams();
    var configId, connectionId, pluginId = null;

    if ( userIsEditingExistingConfig ) {
        configId     = params.configId;
        //connection id associated with this configuration
        connectionId = params.connectionId;
    } else {
        pluginId = params.pluginId;
    }

    /*************************************************
     *  State
     *************************************************/
    var [existingConnection, setExistingConnection ]            = useState(null);
    var [calledForConnections, setCalledForConnections]         = useState(false);
    var [searchTerm, setSearchTerm]                             = useState(null);
    var [filterValue, setFilterValue]                           = useState('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // var [showConnectionErrorPopup, setShowConnectionErrorPopup] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var [connectionError, setConnectionError]                   = useState('');
    

    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    //error here is from an API call error when calling connections service
    const {isLoading, error, connections} = useSelector(state => state.connections);

    


    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);
    
    //connection error popup - show if error from connection dispatch
    useEffect(()=>{
        if(error) {
            // setShowConnectionErrorPopup(true);
            setConnectionError(error);
        }
    }, [error]);


    useEffect(()=>{
        if( userIsEditingExistingConfig && connections ) {
            //todo error handling if nothing found.
            setExistingConnection(connections.find(connection=>connection._id === connectionId));
        }

        //looks choppy with how fast it loads, allow loading gif to fully render before showing content
        setTimeout(()=>{
            setCalledForConnections(true);
        }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[connections]);


    /*************************************************
     *  Handleers
     *************************************************/

    const handleSearchChange = debounce_h((e, data) => {
        setSearchTerm(data.value);
    }, 200, false);

    const handleFilterChange = debounce_h((value) => {
        setFilterValue(value);
    }, 100, false);

    // const handleCloseConnectionErrorPopup = ()=>{
    //     setShowConnectionErrorPopup(false);
    //     setConnectionError('');
    // };

    function handleAddConnectionsClick() {
        dispatch(modalActions.setCreateModalState(true));
    }


    /*************************************************
     *  Render
     *************************************************/
    return (
        <PopulateConnectionStore>

            <ConfigSteps activeStep={1}/>
            

            <Container className={"mb-large"}>

                {/* Error Handling  */}
                {/* <ErrorPopup 
                    isOpen={showConnectionErrorPopup}
                    errorTitle={"Error"}
                    errorMsg={`There was an error obtaining the necessary Connections.  Please try to refresh this window.  If the problem persists, please contact support.  Error details: ${connectionError}`}
                    onClose={handleCloseConnectionErrorPopup}
                /> */}
                {/* Error Handling  */}


                {/* Loading Gif */}
                {!calledForConnections &&
                    <div className={styles['margin-top']}>
                        <Loader active inline='centered' size='large'><span>Loading Data...</span></Loader>
                    </div>
                }
                {/* Loading Gif */}


                {calledForConnections &&
                    <Grid>
                        <Grid.Row>

                            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={12} widescreen={12}>

                                <h3>MY CONNECTIONS</h3>
                                <p><em>
                                    {!userIsEditingExistingConfig && "Select a connection below for this plugin to use"}
                                    {userIsEditingExistingConfig && existingConnection &&

                                        <>
                                            <Message>
                                                <Message.Header><span className={styles['edit-warning']}><Icon name="exclamation triangle" color="red" /></span>Connection Change Alert</Message.Header>
                                                <p>
                                                    The connection you are currently using for this plugin is titled <span className={styles['existing-title']}>"{existingConnection.title}"</span>.  Changing connections will have impacts on your application and field mappings.  Verify you are are choosing the correct connection before continuing.  If you select a different connection, you will need to re-map all of your fields in the next step for this plugin.
                                                </p>
                                            </Message>
                                            <div className="mb-medium" />
                                        </>
                                    //     <span>
                                    // <Icon name="exclamation triangle" color="red"/>The connection you are currently using for this plugin is titled <span className={styles['existing-title']}>"{existingConnection.title}"</span>.  Changing connections can have major impacts on your application and field mappings.  Verify you are are choosing the correct connection before continuing.  If you select a different connection, you will need to re-map all of your fields in the next step for this plugin.
                                    // </span>
                                    }
                                </em></p>


                                {/*Search and Add New Connection button*/}
                                <ConnectionFilter 
                                    onSearchChange={handleSearchChange}
                                    onAddConnectionClick={handleAddConnectionsClick}
                                    connections={connections}
                                    onFilterChange={handleFilterChange}
                                    hideTypeFilter={true}
                                />
                                {/*Search and Add New Connection button*/}



                                {/*Add Connection Modal*/}
                                <ConnectionAdd
                                    type="Quick Base"
                                />
                                {/*Add Connection Modal*/}

                                {/* No Connections Message  */}
                                {connections.length < 1 && !isLoading &&
                                    <>
                                        <HomePageMsg
                                            iconSize="huge"
                                            iconName="alarm"
                                            msgText='Looks like you currently have no connections associated with this account.  Add a new connection by clicking the "Create New Connections" button above!'
                                        />
                                        <div className="mb-medium" />
                                    </>
                                }
                                {/* No Connections Message  */}

                                {/*List of connection and configuration button*/}
                                { connections.length > 0 && !isLoading && 
                                    <section className={styles['config--plugin-cards']}>
                                        <Grid>

                                            {connections.filter((connection) => { 
                                                return searchStringForMatch_h(connection.title, searchTerm) && "Quick Base" === connection.type;
                                            }).map(connection =>
                                                <ConnectionItem
                                                    key={connection._id}
                                                    connection={connection}
                                                    userIsEditingConfig={userIsEditingExistingConfig}
                                                    configId={userIsEditingExistingConfig ? configId : null}
                                                    pluginId={!userIsEditingExistingConfig ? pluginId : null}
                                                />
                                            )}

                                        </Grid>
                                    </section>
                                }
                                {/*List of connection and configuration button*/}



                            </Grid.Column>


                            {/*Sidebar*/}
                            <Sidebar 
                                helpText="What is a connection?"
                                youtubeVideoId="qSJNsAY99Aw"
                            />

                        </Grid.Row>

                    </Grid>
                }
            </Container>

        </PopulateConnectionStore>
    );

}

export default Connection;