/**
 * Houses all sagas for connection service
 */
import {takeLatest, call, fork, put} from 'redux-saga/effects';
import * as actions from '../actions/connectionActions';
import * as types from '../types';
import * as api from '../../feathers/services/connections';
import {setCreateModalState} from '../actions/modals';


/**
 * connection service saga's
 */






 /**
  * Create a connection.
  */
function* createConnection(action) {
    try {
        const connection = action.payload;
        const result = yield call(api.createConnection, connection);
        //set success state for api call
        yield put(actions.createConnectionSuccess(result));
        //close modal
        yield put(setCreateModalState(false));
    } catch (error) {
        yield put(actions.createConnectionFailure(error));
    }

}

function* watchCreateConnection() {
    yield takeLatest(types.CREATE_CONNECTION, createConnection);
}






/**
 * Get/Find connections
 */
function* getConnectionData() {
    try {
        const result = yield call(api.getConnections);// request and loading
        yield put(actions.getConnectionSuccess(result.data)); // put the value in the store
    } catch (error) {
        yield put(actions.getConnectionFailure(error)); // put the error in the error store
    }

}

function* watchGetConnectionData() {
    yield takeLatest(types.GET_CONNECTIONS, getConnectionData);
}






/** 
 * Patch a connection (see patch in feathers.js service methods)
 */
function* patchConnection( action ) {
    try {
        const connection = action.payload;
        const id = connection._id;
        delete connection._id;
        const result = yield call(api.patchConnection, id, connection);
        yield put(actions.patchConnectionSuccess(result)); 
        //close modal
        yield put(setCreateModalState(false));
    } catch (error) {
        yield put(actions.patchConnectionFailure(error)); // put the error in the error store
    }

}

function* watchPatchConnection() {
    yield takeLatest(types.PATCH_CONNECTION, patchConnection);
}



/** 
 * Remove a connection saga
 */
function* removeConnection(action) {
    try {
        const connection = action.payload;
        const id = connection._id;
        const result = yield call(api.removeConnection, id);
        yield put(actions.removeConnectionSuccess(result));
    } catch (error) {
        yield put(actions.removeConnectionFailure(error)); // put the error in the error store
    }

}

function* watchRemoveConnection() {
    yield takeLatest(types.REMOVE_CONNECTION, removeConnection);
}









//Export all saga's here
const connectionSagas = [
    fork(watchCreateConnection),
    fork(watchGetConnectionData),
    fork(watchPatchConnection),
    fork(watchRemoveConnection)
];

export default connectionSagas;

