import React, { useEffect, useState } from 'react';

//semantic
import {Message, Dimmer, Loader, List, Icon} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

import { findSubscriptions } from "../../feathers/services/subscriptions";

import { ApiError_h } from "../../utils/apiHelpers";

import Configuration from "../../utils/Configuration";



/**
 * Given a connection, it finds all subscirptions associated with that connection and displays a warning for an individual deleting or editing an existing connection.
 * @param {Object} props React component props object
 * @param {Object} props.connection A connection object from the database.
 * @param {boolean} props.deleteWarning  IF it's a delete warning, show some more serious error message.
 */
function ConnectionSubscriptionsWarning({connection, deleteWarning=false}) {


    /*************************************************
     *  State
     *************************************************/
    const [subscriptionNames, setSubscriptionNames] = useState([]);
    const [error, setError]                         = useState();
    const [loading, setLoading]                     = useState(true);



    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        if( connection ) {
            setLoading(true);
            findSubscriptions({'isActive':true}).then((res) => {
                let subscriptions = res.data;
                let subscriptionNamesToShow = [];
                if( subscriptions.length > 0 ) {
                    subscriptions.forEach(subscription=>{
                        let configuration = new Configuration(subscription.configuration, subscription.plugin);
                        let connections = configuration.getAllConfigurationConnections();
                        if (connections.indexOf(connection._id) > -1 && subscriptionNamesToShow.indexOf(subscription.name) < 0) subscriptionNamesToShow.push(subscription.name);
                    });
                }
                // const subscriptionNamesToShow = res.data.filter(subscription => subscription.configuration.connection_id === connection._id).map(subscription => subscription.name);
                setSubscriptionNames(subscriptionNamesToShow);
                //looks glitchy - slows the loading gif 
                setTimeout(() => {
                    setLoading(false);
                }, 750);
            }).catch((error) => {
                var err = new ApiError_h(error);
                setError(`Error obtaining your subscriptions associated with this connection.  Error details: ${err.getUserMessage()}`);
                setLoading(false);
            });
        }
    }, [connection]);

    return (
        <>
            <Message>
                <Message.Header><span className={styles['edit-warning']}><Icon name="exclamation triangle" color="red" /></span>Connection {!deleteWarning ? "Change" : "Delete"} Alert</Message.Header>

                {/* get subscription error  */}
                {error &&
                    <p className={styles['error-msg']}>There was an error obtaining the subscriptions associated with this connection. Please refresh and try again.  If the problem persists, please contact support.</p>
                }
                {/* get subscription error  */}


                {/* related subscriptions to this connection  */}
                {!error &&
                    <>
                        <p>
                            {!deleteWarning ? "Note that changing a connection can impact numerous plugins.  You are currently using this connection in the following plugin subscriptions below:" :
                            "Note that deleting a connection that is connected to existing plugin subscriptions will cause these plugins to discontinue working.  You are currently using this connection in the following plugin subscriptions below:"}
                        </p>

                        {subscriptionNames && subscriptionNames.length < 1 &&
                            < p >
                                Currently none of your subscriptions are using this connection.
                            </p>
                        }
                    </>
                }
                {/* related subscriptions to this connection  */}

                <Dimmer active={loading} inverted>
                    <Loader active inline='centered'>Loading...</Loader>
                </Dimmer>
                <List bulleted>
                    {subscriptionNames && !error && subscriptionNames.map((name, i) =>
                        <List.Item key={i}>
                            {name}
                        </List.Item>
                    )}
                </List>
            </Message>
        </>
    );

}

export default ConnectionSubscriptionsWarning;