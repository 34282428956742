import React, {useState, useEffect} from 'react';


//semantic
import {Dropdown, Popup, Icon, Grid} from 'semantic-ui-react';

import useCopyToClipboard from "../../../utils/useCopyToClipboard";


//styles
import styles from './index.module.css';


/**
 * Portal Input for PortalDatabaseViewer component
 * @param {Object} props React component props object
 * @param {String} props.label Label that needs to show for the input
 * @param {String} props.launchIconPopupTxt Popup text that needs to show for Launch icon when someone hovers over it.
 * @param {String} props.copyPopupTxt Popup text that shows when you hover over the launch icon.
 * @param {Object} props.options Semantic UI dropdown options
 *
 */
function PortalInput({label, launchIconPopupTxt, copyPopupTxt, isLoading, onInputChange, isDisabled, placedHolderTxt, value, options, linkURL}) {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [isCopied, copyToClipBoard] = useCopyToClipboard();

    const copyToClipboardSequence = ()=>{
        copyToClipBoard(value);
        setShowClipBoardSuccessMsg(true);
        setTimeout(() => {
            setShowClipBoardSuccessMsg(false);
        }, 2000)
    };

    /*************************************************
    *  State
    *************************************************/
    //commented this out as it does not seem to be used anywhere
    var [showClipBoardSuccessMsg, setShowClipBoardSuccessMsg] = useState(false);

    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        if(value) {
            copyToClipboardSequence();
        }
    },[value]);
    /*************************************************
     *  Handlers
     *************************************************/
    const handleCopyToClipBoard = ()=>{
        copyToClipboardSequence();
    };


    return (
        <Grid.Column>
            <label>{label}
                {value && linkURL &&
                    <span className={styles["interactive-icons"]}>
                        <Popup content={launchIconPopupTxt} trigger={
                            <a href={linkURL} target="_blank">
                                <Icon
                                    name="external square alternate"
                                    size="large"
                                />
                            </a>
                        } />
                    </span>
                }
                {value &&
                    <span className={styles["interactive-icons"]}>
                        <Popup content={copyPopupTxt} trigger={
                                <Icon
                                    onClick={handleCopyToClipBoard}
                                    name="copy"
                                    size="large"
                                />
                           
                        } />
                    </span>
                }
                {showClipBoardSuccessMsg &&
                    <span className={styles["interactive-icon-copy"]}>
                        <Icon
                            onClick={handleCopyToClipBoard}
                            name="checkmark"
                            size="large"
                        />
                        Copied ID
                    </span>
                }
            </label>
            <Dropdown
                value={value}
                clearable
                disabled={isDisabled}
                loading={isLoading}
                onChange={onInputChange}
                placeholder={placedHolderTxt}
                fluid={true}
                search
                selection
                options={options}
            />
        </Grid.Column>
    );

}

export default PortalInput;