/**
 * This file sets up the redux store that is passed to the react application.
 */
import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from './reducers/index';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';


// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

//create the store
const store = createStore(rootReducer, compose(
        applyMiddleware(sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

//run the saga
sagaMiddleware.run(rootSaga);


export default store;