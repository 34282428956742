/**
 * Current helper functions for the application
 */



/**
 * Returns a formatted USD currency number $xxx,xxx.xx
 * @param {number} number Number you want to format to USD.
 * @return {string} 
 */
export const formatToUSD_h = ( number ) => {

    if( typeof number === "string" ) {
        number = parseFloat(number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if( number == 0 ) {
        return "$0.00";
    }
    
    // Create USD currency formatter.
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(number);

};