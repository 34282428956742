import React from 'react';

//semantic
import {
    Segment
} from 'semantic-ui-react';

import {formatToUSD_h} from "../../utils/currencyHelpers";
import HelpPopup from "../HelpPopup/HelpPopup";

//styles
import styles from './index.module.css';

/**
 * Showcases a plugins tiers in an easily readable format
 * @param {Object} props React component props object
 * @param {} props.plugin The plugin object in question.
 */
function PluginTierPricing({plugin}) {

    return (
        <>
            <Segment.Group raised>
                <Segment >
                    <div className={styles["pricing-header"]}>
                        Tier Pricing Details
                        {plugin.pricing.helpText &&
                            <>
                                &nbsp;<HelpPopup
                                    help={plugin.pricing.helpText}
                                    inverted={false}
                                />
                            </>
                        }
                    </div>
                </Segment>
                <Segment.Group>
                    {plugin.pricing.tiers.map(tier =>
                        <Segment key={tier.id}>
                            <strong>{tier.name} - <span className={styles["tier-requests"]}>{tier.limit}</span></strong><br />
                            <div className={styles["pricing-tier-description"]}>
                                {formatToUSD_h(tier.price.annualMonthlyPricing)} per month, billed annually or {formatToUSD_h(tier.price.monthlyPricing)} month-to-month
                            </div>
                        </Segment>
                    )}
                    
                </Segment.Group>
                <Segment>
                    <span className={styles["pricing-tier-description"]}>*Bulk options available upon request.</span>
                </Segment>
                
            </Segment.Group>
        </>
    );

}

export default PluginTierPricing;