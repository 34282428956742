

/**
 * Sort an array of objects by a property string value in each object (order them alphabetically)
 * @param {array} arrayOfObjects Array of objects you want sorted into a specific order
 * @param {string} propertyName The name of the property in each object that needs to be sorted in alphabetical order
 * @param {boolean} sortLowToHigh If true sorts from a-z else sorts z-a
 */
export const alphabeticallySortArrayOfObjectsByProperty = (arrayOfObjects, propertyName, sortLowToHigh=true)=>{

    if(!arrayOfObjects || !propertyName) return false;
    if( sortLowToHigh ) {
        return arrayOfObjects.sort((a, b) => {
            var wordA = a[propertyName].toLowerCase();
            var wordB = b[propertyName].toLowerCase();
            if (wordA < wordB) { return -1; }
            if (wordA > wordB) { return 1; }
            return 0;
        });
    } else {
        return arrayOfObjects.sort((a, b) => {
            var wordA = a[propertyName].toLowerCase();
            var wordB = b[propertyName].toLowerCase();
            if (wordA > wordB) { return -1; }
            if (wordA < wordB) { return 1; }
            return 0;
        });
    }
};


/**
 * Algorithm to search text for a search term.  IF multiple words are entered, it splits the string and searches for each individual word to find a match.  If any words match, it returns true.
 * @param {String} textToSearch Text string to search
 * @param {String} searchTerm The search term the user typed.
 * @return {boolean} Returns true/false if match
 */
export const searchStringForMatch_h = ( textToSearch, searchTerm )=>{

    if(!textToSearch || !searchTerm) return true;

    //make sure these are lowercase
    textToSearch = textToSearch.toLowerCase();
    searchTerm   = searchTerm.toLowerCase();

    //create an array of words to search for and filter any empty strings
    const wordsToSearchFor = searchTerm.split(',').filter(word => word !== "").map(word=>word.trim());

    if (wordsToSearchFor.some(word => textToSearch.includes(word))) {
        return true;
    } else {
        return false;
    }

};




