import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

//redux
import { useSelector } from "react-redux";




//semantic
import {
    Container, 
    Grid, 
    Form, 
    Button,
    Message,
    Dropdown
} from 'semantic-ui-react';

//api
import {getPluginById} from "../../feathers/services/plugins";
import { createRating, patchRating, findRatings } from "../../feathers/services/ratings";

//helpers
import { formHasErrors_h, generateFormErrorsArray_h, isFormError_h } from "../../utils/formHelpers";
import { ApiError_h } from "../../utils/apiHelpers";

//react router
import { useHistory } from "react-router-dom";

//styles
import styles from './index.module.css';




//TODO: Rating options
const ratingOptions = [
    {
        key: 0,
        text: "0 - Plugin was Useless",
        value: "0"
    },
    {
        key: 1,
        text: "1 - Needs Serious Improvement",
        value: "1"
    },
    {
        key: 2,
        text: "2 - Needs Improvement",
        value: "2"
    },
    {
        key: 3,
        text: "3 - Worked for my Needs",
        value: "3"
    },
    {
        key: 4,
        text: "4 - Above My Expectations",
        value: "4"
    },
    {
        key: 5,
        text: "5 - Fantastic Plugin!",
        value: "5"
    }
];







function RatePlugin() {
    //react router
    const history = useHistory();


    /*****************************************
     * State 
     *****************************************/
    const [isSubmitted, setIsSubmitted]                   = useState(false);
    const [isLoading, setIsLoading]                       = useState(true);
    const [plugin, setPlugin]                             = useState(null);
    // const [formSubmitLoding, setFormSubmitLoading]        = useState(false);
    const [userRating, setUserRating]                     = useState('');
    const [error, setError]                               = useState([]);
    const [existingRatingObject, setExistingRatingObject] = useState(null);
    




    /*************************************************
    *  Redux
    *************************************************/
    //current user logged in
    const { user } = useSelector(state => state);


    /*************************************************
     *  Query Parameters
     *************************************************/
    // URL Query Parameters
    const params       = useParams();
    const { pluginId } = params;


    /*************************************************
     *  React Hook Form
     *************************************************/
    var { register, handleSubmit, errors, setValue } = useForm();


    /*************************************************
     *  Effects
     *************************************************/
    //register the rating dropdown and get the plugin of interest from the database
    useEffect(() => {
        //register the dropdown input
        register({ name: 'rating' }, { required: "Rating is required." });
        //get plugin in question to rate
        getPluginById( pluginId ).then((plugin)=>{
            setIsLoading(false);
            setPlugin(plugin);
        }).catch((err)=>{
            setIsLoading(false);
            var apiError = new ApiError_h(err);
            setError([apiError.getUserMessage()]);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //see if this user has an existing rating for this particular plugin
    useEffect(()=>{
        if(user._id) {
            findRatings({ user_id: user._id, plugin_id: pluginId }).then((res) => {
                if( res.data.length > 0 ) {
                    setUserRating(res.data[0].rating.toString());
                    setValue('rating', res.data[0].rating.toString());
                    setExistingRatingObject(res.data[0]);
                }
            }).catch((err) => {
                var apiError = new ApiError_h(err);
                setError([apiError.getUserMessage()]);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);



    /*************************************************
     *  Handlers
     *************************************************/
    const onSubmit = rating => {
        //populate the related plugin
        rating.plugin_id = pluginId;

        if( !existingRatingObject ) {
            createRating( rating ).then(( res )=>{
                setIsSubmitted(true);
                history.push("/account/my-apps");
            }).catch((err)=>{
                var apiError = new ApiError_h(err);
                setError([apiError.getUserMessage()]);
            });
        } else {
            patchRating( existingRatingObject._id, rating ).then((res)=>{
                setIsSubmitted(true);
                history.push("/account/my-apps");
            }).catch((err)=>{
                var apiError = new ApiError_h(err);
                setError([apiError.getUserMessage()]);
            });
        }
    };

    const onRatingChange = (e, data) => {
        //true makes sure the validation runs again on each setValue
        setValue('rating', data.value, true);
        setUserRating(data.value);
    };


    return (
        <Container className={"mb-medium"}>
            <Grid centered>
                <Grid.Row>
                    {/*Main Map form*/}
                    <Grid.Column mobile={16} table={16} computer={8} largeScreen={6} widescreen={6}>
                        <div className={styles["wrapper"]}>
                            <div className={styles["margin-top"]} />

                            <h2 className={styles["title"]}>Rate {plugin && plugin.name}</h2>

                            <p className={styles["hero-text"]}>
                                We love to get your feedback!  Please select a rating for the plugin "{plugin && plugin.name}" below.  These ratings are compiled and shared with other users.   
                            </p>

                            <div className={styles["margin-top"]} />

                            <Form
                                className={styles['input-form']}
                                onSubmit={handleSubmit(onSubmit)}
                                error={ formHasErrors_h(errors) || error.length > 0 }
                                loading={isLoading}
                            >
                                {!isSubmitted &&
                                    <>
                                        <Form.Group>
                                            <Form.Field
                                                width={16}
                                                required
                                            >
                                                <label>Rating</label>
                                                <Dropdown
                                                    error={isFormError_h(errors.rating)}
                                                    name="rating"
                                                    placeholder='Select your rating...'
                                                    search
                                                    selection
                                                    clearable
                                                    fluid
                                                    options={ratingOptions}
                                                    onChange={onRatingChange}
                                                    value={userRating}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Field
                                                width={16}
                                            >
                                                <label>Comments</label>
                                                <textarea
                                                    placeholder='Comments...'
                                                    type='text'
                                                    name="comment"
                                                    defaultValue={existingRatingObject && existingRatingObject.comment}
                                                    ref={register()}
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        <div className="text-right">
                                            <br />
                                            <Button
                                                primary
                                                loading={false}>
                                                Submit
                                            </Button>
                                        </div>
                                    </>
                                }
                                

                                <Message
                                    negative
                                    error
                                    header='There were some errors submitting this form'
                                    list={generateFormErrorsArray_h(errors).concat(error)}
                                />

                                <div className={styles['success-message']}>
                                    <Message
                                        visible={isSubmitted}
                                        color="teal"
                                        success
                                        header='Submitted!'
                                        content="You will be redirected momentarily."
                                    />
                                </div>
                            </Form>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );

}

export default RatePlugin;