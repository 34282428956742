import React, { useState, useEffect } from 'react';
import {useForm} from 'react-hook-form';
import * as api from '../../feathers/services/users';
import { ApiError_h } from '../../utils/apiHelpers';
// import { generateFormErrorsArray_h } from "../../utils/formHelpers";
import { passwordInputValidator } from "../../utils/passwordHelpers";

//semantic
import { 
    Grid, 
    Button, 
    GridColumn
} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

import { emailRegex } from "../../utils/formRegexValidations"

//custom components
import HomePageTitle from '../HomePageTitle/HomePageTitle';
import HomePageInput from '../HomePageInput/HomePageInput';
import HomePageMsg from '../HomePageMsg/HomePageMsg';
import HomePageError from "../HomePageError/HomePageError";
import PwdValidatorPopup from "../PwdValidatorPopup/PwdValidatorPopup";

// function signup() {
//     const email = "test@123ffffee45.com";
//     const password = "test"

//     api.addNewUser({ email, password, firstName: "Ryan", lastName: "Ryan" }).then((res)=>{
//         console.log(res);
//     }).catch((err) => {

//         console.log(JSON.stringify(err));
//         console.log(err);
//     });

// }




/**
 *  Responsible for the signup process
 */
function Signup() {

    //react hook form
    const { register, handleSubmit, errors, watch } = useForm();
   

    /*************************************************
     *  STATE
     *************************************************/
    //handles form submittal and UI state changes
    const [isSubmitted, setIsSubmitted]     = useState(false);
    const [isLoading, setIsLoading]         = useState(false);
    const [formErrorText, setFormErrorText] = useState('');
    const [pw, setPw]                       = useState('');
    

    //Handle signup/verify conditional text
    var titleText = "Sign up!";
    if( isSubmitted ) titleText = "Verify!";


    /*************************************************
     *  FORM SUBMITTAL
     *************************************************/
    //onsubmit of form
    const onSubmit = data => { 
        setIsLoading(true);
        //strip retypepasswrod
        const newUser = {
            firstName: data.firstName,
            lastName: data.lastName, 
            password: data.password,
            email: data.email
        };

        api.addNewUser(newUser).then((res)=>{
            setIsSubmitted(true);
        }).catch((err)=>{
            var error = new ApiError_h(err);
            var errorText = error.getUserMessage();
            setFormErrorText(errorText);
            setIsLoading(false);
        });
    };


    /*************************************************
     *  Effects
     *************************************************/
    //set error msg if there is an auth error from API
    useEffect(() => {
        if (errors.firstName || errors.lastName || errors.email || errors.password || errors.retypePassword) setFormErrorText("Please correct the required fields above in red.  Passwords must be 8 characters or more, and contain letters and numbers.");
    },
        [errors.firstName, errors.lastName, errors.email, errors.password, errors.retypePassword]
    );

    /*************************************************
     *  HANDLERS
     *************************************************/
    const handlePwChange = e =>{
        setPw(e.target.value);
    };


    return (
        <>
            <HomePageTitle title={ titleText } />

            {/* Conditional Content After Submittal */}
            { isSubmitted &&
                <HomePageMsg 
                    iconName="mail"  
                    msgText="Please visit your email and click on the confirmation link we just sent you. Check your spam folder if you do not see the email in few minutes." 
                    iconSize="huge"
                />
            }
            {/* Submitted form Content */}
            
            

            {/* Conditional Unsubmitted form content */}
            { !isSubmitted &&
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid centered>
                            <Grid.Column mobile={16} tablet={10} computer={6} largeScreen={4} widescreen={4}>

                                <Grid>
                                    <GridColumn mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8}>
                                        <HomePageInput
                                            name="firstName"
                                            labelText="First Name"
                                            placeholder="First name..."
                                            type="text"
                                            reference={register({required: true})}
                                            inputError={errors.firstName}
                                        />
                                    </GridColumn>

                                    <GridColumn mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8}>
                                        <HomePageInput
                                            name="lastName"
                                            labelText="Last Name"
                                            placeholder="Last name..."
                                            type="text"
                                            reference={register({ required: true })}
                                            inputError={errors.lastName}
                                        />
                                    </GridColumn>

                                </Grid>

                                <HomePageInput
                                    name="email"
                                    labelText="Email"
                                    placeholder="Enter your email..."
                                    type="text"
                                    reference={register({ required: true, pattern: emailRegex })}
                                    inputError={errors.email}
                                />

                                <HomePageInput
                                    popupHeader="Password Requirements"
                                    popupContent={<PwdValidatorPopup password={pw} />}
                                    name="password"
                                    labelText="Password"
                                    placeholder="Enter your password..."
                                    type="password"
                                    handleChange={handlePwChange}
                                    reference={register({ 
                                        required: true, 
                                        validate: {
                                            // match: value => value === watch('retypePassword'),
                                            securePw: passwordInputValidator
                                        } })}
                                    inputError={errors.password}
                                />

                                <HomePageInput
                                    name="retypePassword"
                                    labelText="Retype Password"
                                    placeholder="Enter your password again..."
                                    type="password"
                                    reference={register({ required: true, validate: value => value === watch('password') })}
                                    inputError={errors.retypePassword}
                                />

                                <div className={styles["submit--position"]}>
                                    <Button 
                                        loading={isLoading}
                                        type='submit' 
                                        floated='right' 
                                        primary>
                                            Submit
                                    </Button>
                                </div>

                                {/* error handler */}
                                {(errors.firstName || errors.lastName || errors.email || errors.password || errors.retypePassword || formErrorText) &&  
                                    <HomePageError
                                        errorText={ formErrorText }
                                    />
                                }
                                {/* error handler */}

                            </Grid.Column>
                        </Grid>
                    </form>
                </div>

            }
            {/* Unsubmitted form content */}
            
        </>
    );

}

export default Signup;