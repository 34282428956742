import React from 'react';

//semantic
import {Modal, Button} from 'semantic-ui-react';




/**
 * Modal for handling deleting content
 * @param {Object} props - Component props
 * @param {string} props.confirmButtonText Button text to show on confirm
 * @param {function} props.onConfirm Function to run when confirm button pressed
 * @param {function} props.onCancel Funciton to run when cancel is clicked
 * @param {string} props.modalContent Main paragraph content to render to the user - description.
 * @param {string} props.modalHeader Header of the modal
 * @param {boolean} props.open If the modal is open or not
 */
function DeleteModal({confirmButtonText="Confirm", modalContent=null,modalHeader=null, open, onConfirm, onCancel, children}) {

    return (
        <Modal size="tiny" open={open} onClose={ onCancel }>
            <Modal.Header>{modalHeader}</Modal.Header>
            <Modal.Content>
                { children } 
                <p>{modalContent}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    color="black"
                    onClick={ onCancel }>Cancel</Button>
                <Button 
                    negative 
                    color="black"
                    onClick={ onConfirm }>{confirmButtonText}</Button>
            </Modal.Actions>
        </Modal>
    );

}

export default DeleteModal;