import React, { useEffect, useState } from "react";

// Styles
// import styles from "./index.module.css";



//Components
import {Input, Dropdown, Button, Grid} from "semantic-ui-react";


/**
 * Connection filters that send filtered data request to the API.
 * @param {object} props react props
 * @param {function} props.onAddConnectionClick What happens when a user adds a connection
 * @param {function} props.onSearchChange Search change updates
 * @param {function} props.onFilterChange Filter change updates
 * @param {Array<Object>} props.connections Current set of connections that user has access to (from database)
 * @param {boolean} props.hideTypeFilter Determines whether to hide the type filter for connections.  Some areas force certain connections to shwo (example - Quick Base) where the only connections provided are of type Quick Base.  No need to show the type filter in these instances.
 */
function ConnectionFilter({ onAddConnectionClick, onSearchChange, onFilterChange, connections = [], hideTypeFilter=false }) {

    const [options, setOptions] = useState([]);

    useEffect(()=>{
        if(connections) {
            let dropdownOptions = [...new Set(connections.map(connection => connection.type))].map((connection) => { return { key: connection, value: connection, text: connection } });
            setOptions(dropdownOptions);
        }
    },[connections]);

    const handleFilterChange = (e, element)=>{
        if (onFilterChange) onFilterChange(element.value);
    };

    return (
        <Grid >
            <Grid.Column 
                mobile={16} 
                tablet={16} 
                computer={5} 
                largeScreen={4}
                widescreen={4}
            >
                <Input
                    icon={{ name: 'search', circular: true, link: true }}
                    placeholder='Search Connections...'
                    onChange={onSearchChange}
                    fluid
                />
            </Grid.Column>
            <Grid.Column
                mobile={16}
                tablet={16}
                computer={5}
                largeScreen={4}
                widescreen={4}
            >   
                {!hideTypeFilter &&
                    <Dropdown
                        search
                        clearable
                        selection
                        placeholder="Filter by Type..."
                        options={options}
                        onChange={handleFilterChange}
                    />
                }
                
            </Grid.Column>
            <Grid.Column 
                textAlign="right"
                mobile={16}
                tablet={16}
                computer={6}
                largeScreen={8}
                widescreen={8}
            >
                <Button
                    icon={"add"}
                    primary
                    content={"Create Quickbase Connection"}
                    onClick={onAddConnectionClick}
                />
            </Grid.Column>
        </Grid>
    );

}

export default ConnectionFilter;