/**
* Subsciptions API Calls
*/

import client from '../index';

/**
 * Gets subscriptions
 */
export function findSubscriptions(query) {
    return client.service('subscriptions').find({ query });
}



/**
 * @param {Object} subscription Id of the connection to be removed
 * @param {Object} subscription._id Id of the subscription to be removed
 * @return {Promise<Object>} Deleted subscription
 */
export function removeSubscription( subscription ) {
    return client.service('subscriptions').remove(subscription._id);
}


/**
 * 
 * @param {Object} subscription Subscription to be created
 * @param {string} subscription.plugin_id Plugin id of this subscription
 * @param {string} subscription.configuration_id The Configuration ID related to this subscription
 * @param {string} subscription.name The name for this particular subscription
 * @param {string} subscription.frequency The frequency for this particular subscription
 */
export function createSubscription( subscription ) {
    return client.service('subscriptions').create(subscription);
}


/**
 * 
 * @param {string} id Id of the rating to be patched
 * @param {Object} subscription subscription object to be updated
 * @return {Promise<SubscriptionObject>} Responds with the updated subscription object
 */
export function patchSubscription(id, rating) {
    return client.service('subscriptions').patch(id, rating);
}


