import React from 'react';

//semantic
import {Icon} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';

/**
 * Popup that shows red or green circles based on the validation criteria that has been completed for a new password request
 * @param {Object} props React.js props
 * @param {String} props.password Password in question
 */
function PwdValidatorPopup({password}) {

    const letterRegExp = /[a-zA-Z]/g;
    const numberRegExp = /\d/;

    var characterCountPassed = password ? password.length >= 8 : false;
    var letterInPassword = password ? letterRegExp.test(password) : false;
    var numberInPassword = password ? numberRegExp.test(password) : false;


    return (
        <div>
            <br />
            <Icon color={characterCountPassed ? "teal" : "red"} size="small" name="circle" /> Must be at least 8 characters <br />
            <Icon color={letterInPassword ? "teal" : "red"} size="small" name="circle" /> Must contain a letter <br />
            <Icon color={numberInPassword ? "teal" : "red"} size="small" name="circle" /> Must contain a number
        </div>
    );

}

export default PwdValidatorPopup;