/**
*   File Purpose: if in development, utilizes default configurations.  In production, if "production.js"
*   has any overrides in it, it will override those values if they are in default.js.
*/
import dev from './default';
import prod from './production';


/**
 * TODO: Add this to typedef's file.
 * ProfileObject typedef
 * @typedef {Object} Config
 * @property {string} API_URL URL of the API.
 * @property {string} AUTHORIZE_PUBLIC_KEY Authorize.net API public key.
 * @property {string} AUTHORIZE_MERCHANT_NAME Authroize.net merchant name for API.
 */



/**
 * @type {Config}
 */
var config = {};

if ( process.env.NODE_ENV === 'development' ) {
    config = dev;
} else {
    config = {
        ...dev,
        ...prod
    };
}


export default config;