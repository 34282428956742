import React, { useEffect, useState } from 'react';

//semantic
import {Grid, Message, Input, Table, Button, TableBody, Loader, Popup} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

import Account from '../Account.js';
import PaymentNav from '../PaymentNav/PaymentNav';

import { findSubscriptions, removeSubscription } from "../../../feathers/services/subscriptions";
import DeleteModal from "../../DeleteModal/DeleteModal";
import SectionGroup from "../../SectionGroup/SectionGroup";
import EditSubscription from "../EditSubscription/EditSubscription";
import HomePageMsg from "../../HomePageMsg/HomePageMsg";

//utils
import { searchStringForMatch_h } from "../../../utils/filterSortHelpers";
import { formatToUSD_h } from "../../../utils/currencyHelpers";
import { formattedDate_h } from "../../../utils/dateHelpers";
import { debounce_h } from "../../../utils/formHelpers";





function Subscriptions() {

    const getSubscriptions = ()=>{
        return findSubscriptions({'isActive': true}).then((res) => {
            setSubscriptions(res.data);
            setIsLoading(false);
        }).catch((err) => {
            setErrorMessage("There was an error and your subscriptions could not be dispayed.  Try refreshing this window.  If this continues to occur, please contact support.  Thank you.");
        });
    }


    /*************************************************
     *  State
     *************************************************/ 
    var [subscriptions, setSubscriptions]                                   = useState([]);
    var [searchTerm, setSearchTerm]                                         = useState('');
    var [errorMessage, setErrorMessage]                                     = useState('');
    var [isDeleteModalShowing, setIsDeleteModalShowing]                     = useState(false);
    var [subscriptionToDelete, setSubscriptionToDelete]                     = useState({});
    var [isLoading, setIsLoading]                                           = useState(true);
    var [isEditSubscriptionModalShowing, setIsEditSubscriptionModalShowing] = useState(false);
    var [subscriptionToEdit, setSubscriptionToEdit]                         = useState(null);



    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        getSubscriptions();
    }, []);


    /*************************************************
     *  Handlers
     *************************************************/
    const handleDeleteOnClick = subscription => {
        setIsDeleteModalShowing(true);
        setSubscriptionToDelete(subscription);
    };
    //function to run when cancel button on delete modal is clicked
    const handleOnCancelDelete = e => {
        setIsDeleteModalShowing(false);
    };
    //function to run when confirmation of delete is clicked
    const handleOnConfirmDelete = (e) => {
        removeSubscription(subscriptionToDelete).then((res) => {
            findSubscriptions().then((res) => {
                setSubscriptions(res.data);
            }).catch((err) => {
                setErrorMessage("There was an error displaying all subscriptions.  Please refresh this window.  If the problem persists, please contact support.");
            });
        }).catch((err) => {
            setErrorMessage("There was an error deleting your subscription.  If this problem persists, please contact support.");
        });
        setIsDeleteModalShowing(false);
    };

    const handleSearchChange = debounce_h((e, data) => {
        setSearchTerm(data.value);
    }, 100, false);

    const handleEditSubscription = subscription => {
        setSubscriptionToEdit(subscription);
        setIsEditSubscriptionModalShowing(true);
    };

    const toggleSubscriptionEditModalShowing = (bool) =>{
        setIsEditSubscriptionModalShowing(bool);
    };

    const handleOnSubscriptionUpdate = updatedSubscription =>{
        var updatedSubcriptions = subscriptions.map(subscription => {
            if (subscription._id === updatedSubscription._id) {
                subscription.name = updatedSubscription.name;
            }

            return subscription;
        });
        setSubscriptions(updatedSubcriptions);
    };

    return (
        <>
            <Account pageTitle={"My Subscriptions"} helpPopup={"This part of your account allows you to manage your currently active subscriptions."}>

                <Grid>

                    <Grid.Row>

                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>

                            <PaymentNav
                                activeItem="Subscriptions"
                            />
                            <div className='mb-small'/>
                            <SectionGroup
                                title="Subscriptions"
                                description="The subscriptions for your account are shown below.  All plugins that you currently have access to have a corresponding subscription shown below.  Removing a subscription will remove access to that particular plugin."
                            >
                                {/* Error Message */}
                                {errorMessage &&
                                    <Message negative>
                                        <Message.Header>Error</Message.Header>
                                        <p>{errorMessage}</p>
                                    </Message>
                                }
                                {/* Error Message */}


                                {/*Search filter*/}
                                <Input
                                    icon={{ name: 'search', circular: true, link: true }}
                                    placeholder='Search...'
                                    onChange={handleSearchChange}
                                />
                                {/*Search filter*/}


                                {/* Loading Gif */}
                                {isLoading && !errorMessage &&
                                    <div className={styles['margin-top']}>
                                        <Loader active inline='centered' size='large'><span>Loading Subscriptions...</span></Loader>
                                    </div>
                                }
                                {/* Loading Gif */}

                                
                                {/* No Connections Message  */}
                                {subscriptions.length < 1 && !isLoading &&
                                    <>
                                        <HomePageMsg
                                            iconSize="huge"
                                            iconName="alarm"
                                            msgText='Looks like you currently have no subscriptions associated with this account.  Visit the plugin marketplace to subscribe to your first plugin!'
                                        />
                                        <div className="mb-medium" />
                                    </>
                                }
                                {/* No Connections Message  */}


                                {/* Main Table Content */}
                                {!isLoading && subscriptions.length > 0 &&
                                    <Table basic='very'>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Plugin Name</Table.HeaderCell>
                                                <Table.HeaderCell>Name</Table.HeaderCell>
                                                <Table.HeaderCell>Frequency</Table.HeaderCell>
                                                <Table.HeaderCell>Start Date</Table.HeaderCell>
                                                <Table.HeaderCell>Cost/Pay Period</Table.HeaderCell>
                                                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <TableBody>
                                            {subscriptions.length > 0 &&
                                                subscriptions.filter((subscription) => searchStringForMatch_h(`${subscription.name} ${subscription.plugin.name}`, searchTerm)).map(subscription =>
                                                    <Table.Row key={subscription._id}>
                                                        <Table.Cell>{subscription.plugin.name}</Table.Cell>
                                                        <Table.Cell>{subscription.name}</Table.Cell>
                                                        <Table.Cell>{subscription.frequency.indexOf('_') > 0 ? subscription.frequency.replace("_", " ") : subscription.frequency}</Table.Cell>
                                                        <Table.Cell>{formattedDate_h(subscription.startDate)}</Table.Cell>
                                                        <Table.Cell>{formatToUSD_h(subscription.price)}</Table.Cell>
                                                        <Table.Cell className={styles['table-action']}>
                                                            {/*Add new Connection modal trigger from there*/}
                                                            <div className={styles['table-action--buttons']}>
                                                                <Popup 
                                                                    content='Edit subscription title' 
                                                                    trigger={<Button
                                                                        icon={"pencil"}
                                                                        onClick={event => handleEditSubscription(subscription)}
                                                                    />} 
                                                                />
                                                                <Popup
                                                                    content='Delete this subscription?'
                                                                    trigger={<Button
                                                                        icon={"delete"}
                                                                        className={styles['connection--delete']}
                                                                        onClick={event => handleDeleteOnClick(subscription)}
                                                                    />}
                                                                />
                                                            </div>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                        </TableBody>
                                    </Table>
                                }
                                {/* Main Table Content */}
                            </SectionGroup>

                        

                            <DeleteModal
                                confirmButtonText="Delete"
                                modalContent={`Please confirm you want to delete the subscription "${subscriptionToDelete.name}" by clicking the "Delete" button below.  You will no longer be able to use this plugin after deleting the subscription for it.`}
                                modalHeader="Delete Subscription"
                                open={isDeleteModalShowing}
                                onCancel={handleOnCancelDelete}
                                onConfirm={handleOnConfirmDelete}
                            />

                            <EditSubscription 
                                open={isEditSubscriptionModalShowing}
                                toggleOpen={toggleSubscriptionEditModalShowing}
                                subscription={subscriptionToEdit}
                                onSuccess={handleOnSubscriptionUpdate}
                            />

                        </Grid.Column>

                    </Grid.Row>

                </Grid>

            </Account>
        </>
    );

}

export default Subscriptions;