import React from 'react';
import ContactUsLink from "../ContactUsLink";


//semantic
// import { Header, Grid } from 'semantic-ui-react';

import LegalWrapper from "../LegalWrapper/LegalWrapper";

//styles
// import styles from './index.module.css';

function TermsOfService() {

    return (
        <>
            <LegalWrapper
                title="OASIS TERMS OF SERVICE"
                updatedDate="1/1/2021"
            >
                <div class="legal">
                    <p>
                        Welcome, and thank you for your interest in the Oasis Marketplace ("Oasis"), and the Oasis digital product marketplace and management platform consisting of the websites located at oasisapps.com (including branded and white labeled subdomains of oasisapps.com) and other related websites, services, applications, add-ons, plug-ins, utilities, widgets, and networks owned MCF Technology Solutions, LLC (“MCFTech”) (collectively referred to herein as the "Oasis Marketplace" or the “Sites”, or individually the “Site”). 
                    </p>
                    <p>
                        <strong>User Agreement</strong>
                    </p>
                    <p>
                        These Website User Terms and Conditions (the “Agreement”) govern your use and access to the Oasis ‘Marketplace.  The term “MCFTech”, “us, “we”, or “our” refers to MCF Technology Solutions, LLC, the owner of the Sites, or the sites being used by MCFTech.  The term “you” or “your” refers to the user or viewer of the Site.  If the person using or viewing the Site is an individual working for another entity, such person is agreeing to the terms and conditions of this Agreement on behalf of the entity and certifies that he/she is an authorized agent of the entity and has all necessary authority to act on said entity’s behalf, including to bind the entity to the terms and conditions of this Agreement.  
                    </p>
                    <p>
                        Third parties who promote and market branded, white-labeled instances of the Oasis Marketplace are referred to herein as "Syndication Partners". The Oasis Marketplace allows independent vendors (the “Developers”) to make their software as a service add-ons, plug-ins, utilities or applications, or other products or services owned and operated by Developers available to you through the Oasis Marketplace. Each Add-on, Plug-in, Utility or Application that is provided by a third-party Developer is subject to a separate terms of use and privacy policy concluded directly with the Developer.
                    </p>
                    <p>
                        The Oasis Marketplace includes links or references to other websites or services, including without limitation advertisers’ and Developer’s websites and Applications, and Syndication Partner websites, solely as a convenience to Users (collectively referred to herein as "Reference Materials"). Oasis does not endorse any such Reference Materials, or the information, materials, products, or services contained on or accessible through Reference Materials. In addition, your correspondence or business dealings with, or participation in promotions of, third party Developers, Syndication Partners, or advertisers found on or through the Oasis Marketplace are solely between you and such third party. Access and use of Reference Materials and Applications, including the information, materials, products, and services on or available through Oasis or Reference Material websites is solely at your own risk.
                    </p>
                    <p>
                        <strong>
                            Eligibility
                        </strong>
                    </p>
                    <p>
                        The Oasis Marketplace is not available to persons under the age of 18 or to any users previously suspended or removed from the Oasis Marketplace by MCF Technology Solutions, LLC. You Hereby represent that you are at least 18 years of age and have not been previously suspended or removed from the Oasis Marketplace. 
                    </p>
                    <p>
                        <strong>
                            Privacy Policy
                        </strong>
                    </p>
                    <p>
                        The Oasis Privacy Policy link, which describes Oasis's policies related to the collection, use, and disclosure of your personal information is hereby incorporated into these Terms by reference. When using the Oasis Marketplace, you will be subject to any additional posted policies, guidelines or rules applicable to specific services and features which may be posted from time to time. All such policies are hereby incorporated by reference into these Terms.
                    </p>
                    <p>
                        <strong>
                            Your Rights and Obligations
                        </strong>
                    </p>
                    <p>
                        MCFTech grants you a non-exclusive, non-transferable, limited right to access the Site and use the functionality provided thereon, provided you comply fully with this Agreement.  You are solely responsible for maintaining the confidentiality of your accounts and passwords, and for restricting access to them, and you agree to accept responsibility for all activities that occur under your accounts or passwords. Information you provide to Oasis during registration and at all other times must be true, accurate, current, and complete. You also agree to keep this information accurate and up-to-date at all times. If you have reason to believe that your account has been compromised, then you agree to immediately notify Oasis.
                    </p>
                    <p>
                        If you are using or opening an account with Oasis on behalf of a company, entity, or organization (collectively, a "Subscribing Organization") then you represent and warrant that you are an authorized representative of that Subscribing Organization with the authority to bind such organization to these terms, and that you agree to these terms on behalf of such Subscribing Organization. 
                    </p>
                    <p>
                        <strong>
                            Additional Terms and Policies
                        </strong>
                    </p>
                    <p>
                        The following terms and policies are incorporated by reference into, and made part of, the Terms. By registering for, accessing, browsing, downloading, or using the Oasis Marketplace, you acknowledge that you have read, understood and agree to be bound by these linked policies, as well as the terms available on this page. In consideration of our agreement to allow you the use of the Site, you agree not to
                    </p>
                    <p>
                        Upload, post, email, transmit or otherwise make available any content that: 
                    </p>
                    <p>
                        <ul>
                            <li>
                                Is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable or harmful to another party;
                            </li>
                            <li>
                                You do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);
                            </li>
                            <li>
                                Infringes any patent, trademark, trade secret, copyright, or other intellectual property right of another party;
                            </li>
                            <li>
                                Is unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” website links, or any other form of content for the purpose of solicitation;
                            </li>
                            <li>
                                Contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;
                            </li>
                            <li>
                                Consists of forged or manipulated information that disguises the true origin of any content you provide; or
                            </li>
                            <li>
                                Impersonates another person or entity or falsely states or otherwise misrepresents your affiliation with a person or entity.
                            </li>
                            <li>
                                Collect or store personal data about other users in connection with any prohibited conduct and activities;
                            </li>
                            <li>
                                Use the Sites in any manner that could damage, disable, overburden, or impair any MCFTech server, or network(s) connections; disobey any requirements, procedures, policies, or regulations of networks connected to the Sites; or interfere with any other party's use and enjoyment of the Sites;
                            </li>
                            <li>
                                Attempt to gain unauthorized access to any Site content, other accounts, computer systems, or networks connected to any MCFTech server through hacking, password mining, scraping, or by any other means to obtain any materials or information not intentionally made available on the Sites;
                            </li>
                            <li>
                                Intentionally or unintentionally violate any applicable local, state, national, or international law, including, but not limited to, regulations promulgated by the U.S. Securities and Exchange Commission, any rules of any national or other securities exchange, including, without limitation, the New York Stock Exchange, the American Stock Exchange, or the NASDAQ, and any regulations having the force of law; or
                            </li>
                            <li>
                                Provide material support or resources (or conceal or disguise the nature, location, source, or ownership of material support or resources) to any organization(s) designated by the United States government as a foreign terrorist organization pursuant to section 219 of the Immigration and Nationality Act.
                            </li>
                        </ul>
                    </p>
                    <p>
                        <strong>
                            User Submissions Representations and Warranties
                        </strong>
                    </p>
                    <p>
                        You are solely responsible for your User Submissions and the consequences of posting or publishing them. By uploading and publishing Your User Submissions, you affirm, represent, and warrant that: 
                    </p>
                    <p>
                        <ol>
                            <li>
                                you are the creator and owner of, or have all the necessary licenses, rights, consents, and permissions to authorize Oasis to use and distribute your User Submissions in the manner consistent with the features of the Oasis Marketplace and these Terms 
                            </li>
                            <li>
                                your User Submissions do not and will not: (a) infringe, violate, or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right or (b) slander, defame, libel, or invade the right of privacy or other property rights of any other person
                            </li>
                            <li>
                                your User Submissions do not contain any viruses, adware, spyware, worms, or other malicious code or any content or file that may have the effect of compromising any security or access control feature of the Oasis Marketplace or of any Application
                            </li>
                        </ol>
                    </p>
                    <p>
                        Violators of third-party rights may be subject to criminal and civil liability. Oasis reserves all rights and remedies against any Users who violate these Terms.
                    </p>
                    <p>
                        <strong>
                            User Submissions Disclaimer
                        </strong>
                    </p>
                    <p>
                        You understand that when using the Oasis Marketplace, you will be exposed to User Submissions or other content from a variety of sources, and that Oasis is not responsible for the quality, accuracy, usefulness, or intellectual property rights of or relating to such User Submissions and other content. You further understand and acknowledge that you may be exposed to User Submissions (including without limitation communications with other users through public postings, private messaging, chat, or other similar features) and other content that is inaccurate, offensive, indecent or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against Oasis with respect thereto. Oasis does not endorse any User Submissions, and Oasis expressly disclaims any and all liability in connection with User Submissions and other content. Oasis reserves the right to determine in its sole discretion whether to remove any User Submission or other content. For clarity, Oasis does not permit copyright infringing activities on the Oasis Marketplace.
                    </p>
                    <p>
                        <strong>
                            Copyright and Trademarks
                        </strong>
                    </p>
                    <p>
                        All materials on the Sites, including without limitation text, images, audio and video clips, databases, and other Site services and products (collectively, the "Content") are owned or controlled by MCFTech, Developers or the party credited as the provider of the Content. The respective owner retains all right, title, and interest in and to its Content. The Sites and Content are protected by the copyright and trademark laws of the United States and other countries, international conventions, and other applicable laws.
                    </p>
                    <p>
                        You may not download, display, reproduce, create derivative works from, transmit, sell, distribute, or in any way exploit the Sites or any portion thereof, for any public or commercial use without the prior written permission of MCFTech.
                    </p>
                    <p>
                        You agree not to use any trademarks, service marks, names, logos, or other identifiers of MCFTech or its employees, licensors, independent contractors, providers, affiliates and MCFTech (collectively, "Affiliates") without the prior written permission of MCFTech or the relevant Affiliate. In addition, you may not use MCFTech or the relevant affiliate trademarks:
                    </p>
                    <p>
                        <ul>
                            <li>in, as, or as part of, your own trademarks or those of any third parties;</li>
                            <li>to identify products or services that are not those of MCFTech;</li>
                            <li>in a manner likely to cause confusion; </li>
                            <li>in a manner that implies that MCFTech sponsors or endorses or is otherwise connected with your own activities, products, and services or those of third parties.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>Ownership; Proprietary Rights</strong>
                    </p>
                    <p>
                        The Oasis Marketplace is owned and operated by MCF Technology Solutions, LLC. The visual interfaces, graphics, design, compilation, information, computer code (including source code or object code), products, software, services, and all other elements of the Oasis Marketplace provided by Oasis are protected by United States copyright, trade dress, patent, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. All Materials contained on the Oasis Marketplace are the property of MCFTech or its subsidiaries or affiliated companies and/or third-party licensors. All trademarks, service marks, and trade names are proprietary to Oasis or its affiliates and/or Developers or third-party licensors. You may not sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Materials. Oasis reserves all rights not expressly granted in these Terms.
                    </p>
                    <p>
                        <strong>Payments</strong>
                    </p>
                    <p>
                        You agree to pay for all Oasis Products, products and services that you purchase through the Oasis Marketplace, and we may charge your payment instrument for any such payments. You are required to keep your payment instrument information on file with Oasis current, complete and accurate, and notify Oasis if your account is changed or canceled (e.g., if it is compromised or deactivated). You are responsible for all charges incurred under your account made by you or anyone who uses your account (including your employees, contractors, agents, or other personnel). If your payment instrument fails or your account is past due, we may collect fees owed using other collection mechanisms and your account may be deactivated at our discretion without notice to you regardless of the dollar amount.
                    </p>
                    <p><strong>Terms of Purchase</strong></p>
                    <p>
                        Transactions in respect of your purchases, are processed directly and applicable fees are collected by Oasis, irrespective of whether the transaction itself is concluded between you and Oasis or you and a third party, such as a Developer. Recurring payments for periodic subscriptions are processed at the frequency you selected for each of your subscriptions, typically on a monthly, Quarterly or Annual basis and will begin based on the subscription activation date.
                    </p>
                    <p>
                        The Oasis Products offered on the Oasis Marketplace that are made available by third party Developers are not affiliated with Oasis. As such, you agree that Oasis is not responsible for such Oasis Products. Your purchase of access to and use of those Oasis Products are concluded directly between you and the applicable Developer, and subject to that Developer’s terms of use. You will have the opportunity to review and accept the Developer’s Terms before processing your transaction. You agree to abide by the applicable Developer Product Terms.
                    </p>
                    <p><strong>Fees</strong></p>
                    <p>
                        Certain functions on the Oasis Marketplace may have fees associated with them. When you use a service that has a fee, you have an opportunity to review and accept the fees that you will be charged based on our stated fees, which we may change from time to time. Oasis may choose to temporarily change the fees for Oasis’s services for promotional events or new services, and such changes are immediately effective when Oasis posts the temporary promotional event or new service on the Oasis Marketplace. Any changes to fees for the Oasis Marketplace or any Oasis services that are not temporary or promotional will be effective thirty (30) days after we provide you with notice by posting such changes on the Oasis Marketplace. Unless otherwise stated, all fees are quoted in U.S. Dollars. 
                    </p>
                    <p><strong>Taxes</strong></p>
                    <p>
                        You are responsible for paying any governmental taxes imposed on your use of the Oasis Marketplace, including, but not limited to, sales, use or value-added taxes. To the extent Oasis is obligated to collect such taxes, the applicable tax will be added to your billing account.
                    </p>
                    <p><strong>Refunds</strong></p>
                    <p>
                        All fees relating to Oasis Products and Services provided through MCFTech through the Oasis Marketplace are final and nonrefundable. 
                    </p>
                    <p>
                        Each Developer may have its own policies regarding refunds, as set forth in the applicable Developer Products Terms. If You have paid for access to a Developer Products that is later disabled, nonfunctioning, modified, or otherwise interfered with before the end of the period for which you purchased access, you will resolve the issue directly through the Developer. 
                    </p>
                    <p>
                        Oasis may make changes to or discontinue any of the Oasis Products, products or services available within the Oasis Marketplace at any time, and without notice. In the event that an Oasis Product that you have purchased access to through the Oasis Marketplace is no longer accessible through the Oasis Marketplace you will be contacted.  If the product was provided directly through a third-party Developer, you must contact the applicable Developer directly to ensure continuity of service during the remaining term of your applicable subscription.
                    </p>
                    <p><strong>Interest from Your Account</strong></p>
                    <p>
                        MCFTech reserves the right to collect any interest accumulated, if any, on the funds in your Oasis Account and MCFTech will not be required to pay you any interested collected.
                    </p>
                    <p><strong>Our Relationship With You</strong></p>
                    <p>We are here to help! For assistance with questions regarding these Terms including any of the related Policies or other inquiries, please first refer to the help section of the Oasis Marketplace. If you cannot find answers to your questions there, you can contact us by <ContactUsLink linkText="visiting this link here"/>. Please note that support questions related to Developer Oasis Products made available on the Oasis Marketplace should be referred directly to the Developer of the applicable Oasis Products. </p>
                    <p><strong>Your login and password; User Information</strong></p>
                    <p>
                        To access a Site, you may be required to enter a login and password. This login and password may be used by MCFTech to identify you, but MCFTech will not disclose this information to any third-party. You are solely responsible for maintaining the confidentiality of any password you use to access a Site and other products and features and agree that MCFTech will have no obligations with regard thereto. MCFTech will not record or use any personal information except as provided in its Privacy Policy described above. MCFTech may record and use any Site activity and may distribute this information to a third-party.
                    </p>
                    <p><strong>Use of MCFTech Products and Services</strong></p>
                    <p>
                        All use of Oasis products will be governed by a separate Product License Agreement that will be accessible via the subscription setup within the marketplace.
                    </p>
                    <p><strong>Links to Other Sites</strong></p>
                    <p>
                        The Oasis Site may contain links and pointers to other websites on the Internet, which may be maintained by third parties. Such links do not constitute an endorsement by MCFTech or its affiliates of any third-party website or any materials contained therein. Third-party sites not owned by MCFTech or its affiliates are not under the control of or the responsibility of MCFTech and by your use of this Site, you agree to relieve them of any responsibility for the availability, accuracy, privacy policy, or currency of such third-party websites or any information, content, products or services accessible from such third-party sites.
                    </p>
                    <p><strong>Cookies</strong></p>
                    <p>
                        The Oasis Marketplace uses "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a Web page server. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Oasis Marketplace services or Web sites you visit.
                    </p>
                    <p><strong>Indemnification</strong></p>
                    <p>
                        You agree to indemnify, defend, and hold MCFTech, Oasis Marketplace, its affiliated companies, contractors, employees, agents and its third party suppliers, licensors, and partners including Syndication Partners and Developers harmless from any claims, losses, damages, liabilities, including legal fees and expenses, arising out of your use or misuse of the Oasis Marketplace or any Product or Service, Your violation of these Terms, or any breach of your representations, warranties, and covenants. MCFTech reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify MCFTech, and you agree to cooperate with MCFTech defense of these claims. MCFTech will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.
                    </p>
                    <p><strong>DISCLAIMER OF WARRANTY</strong></p>
                    <p>
                        EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE OASIS MARKETPLACE INCLUDING ALL CONTENT, SOFTWARE, FUNCTIONS, OR OTHER PRODUCTS OR SERVICES, MATERIALS AND INFORMATION MADE AVAILABLE THEREON OR ACCESSED BY MEANS THEREOF ARE PROVIDED "AS IS" AND "AS AVAILABLE." MCFTECH DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED ON THE OASIS MARKETPLACE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE OASIS MARKETPLACE WILL MEET ANY PARTICULAR CRITERIA OF PERFORMANCE OR QUALITY, OR THAT THE SITES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                    </p>
                    <p>
                        TO THE FULLEST EXTENT PERMISSIBLE BY LAW, MCFTECH AND ITS AFFILIATES DISCLAIM ALL EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE WHETHER OR NOT THE PURPOSE HAS BEEN DISCLOSED, COMPATABILITY, SECURITY, ACCURACY, OR NON-INFRINGEMENT.
                    </p>
                    <p><strong>LIMITATION OF LIABILITY</strong></p>
                    <p>
                        USE OF A SITE IS AT YOUR OWN RISK. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF FILES, INFORMATION, COMMUNICATIONS, CONTENT, OR OTHER MATERIAL (INCLUDING WITHOUT LIMITATION SOFTWARE) ACCESSED THROUGH OR OBTAINED BY MEANS OF A SITE.
                    </p>
                    <p>
                        UNDER NO CIRCUMSTANCES SHALL MCFTECH OR ITS AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS OR ANY THIRD-PARTY PROVIDER OF TELECOMMUNICATIONS OR NETWORK SERVICES, SITE SOFTWARE OR SITE CONTENT FOR MCFTECH OR ITS AFFILIATES, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES (INCLUDING BUT NOT LIMITED TO DAMAGES FOR PERSONAL INJURY AND/OR DEATH, LOST REVENUES OR PROFITS, LOSS OF BUSINESS OR LOSS OF DATA) THAT ARE DIRECTLY OR INDIRECTLY RELATED TO YOUR USE OF OR INABILITY TO USE A SITE, EVEN IF MCFTECH, ITS AFFILIATES, OR THEIR PROVIDERS OF TELECOMMUNICATIONS OR NETWORK SERVICES, CONTENT OR SOFTWARE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, REGARDLESS OF WHETHER SUCH LIABILITY IS BASED IN TORT (INCLUDING NEGLIGENCE), CONTRACT OR ANY OTHER LEGAL OR EQUITABLE THEORY.
                    </p>
                    <p>
                        THE TOTAL LIABILITY OF MCFTECH AND ITS AFFILIATES HEREUNDER IS LIMITED TO THE AMOUNT, IF ANY, ACTUALLY PAID BY YOU FOR ACCESS TO AND USE OF A SITE. YOU HEREBY RELEASE MCFTECH AND ITS AFFILIATES FROM ANY AND ALL OBLIGATIONS, LIABILITIES AND CLAIMS IN EXCESS OF THIS LIMITATION.
                    </p>
                    <p><strong>Termination</strong></p>
                    <p>
                        In addition to any other rights of the parties set forth herein, MCFTech may cancel or terminate this Agreement at any time. MCFTech also reserves the right to restrict, suspend or terminate your access to a Site it owns, or the use of MCFTech’s products or services, in whole or in part, without notice, with respect to any breach or threatened breach by you of any portion of this Agreement. If MCFTech terminates this Agreement (and therefore your access to a Site MCFTech owns or are denied the use of MCFTech’s services) based on a breach of any portion of this Agreement, MCFTech reserves the right to refuse to provide access to a Site it owns, or to use MCFTech services, to you in the future.
                    </p>
                    <p><strong>Termination; Terms of Service Violations</strong></p>
                    <p>
                        You agree that MCFTech, in its sole discretion, for any or no reason, and without penalty, may terminate any account or any part thereof you may have with the Oasis Marketplace or your use of the Oasis Marketplace and remove and discard all or any part of your account, user profile, and any User Submission, at any time. You agree that any termination of your access to the Oasis Marketplace, or any account you may have, or portion thereof may be effected without prior notice, and you agree that MCFTech will not be liable to you or any third party for any such termination. Any suspected fraudulent, abusive, or illegal activity may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies MCFTech may have at law or in equity.
                    </p>
                    <p><strong>Modifications</strong></p>
                    <p>
                        <u>To the Agreement</u> <br/>
                        MCFTech has the right to modify this Agreement and any policies affecting the Oasis Marketplace.  Any modification is effective immediately upon posting to the Oasis Marketplace or distribution to you via electronic mail or conventional mail. Your continued use of the Oasis Marketplace following notice of any modification to this Agreement shall be conclusively deemed an acceptance of all such modification(s). Your only right with respect to any dissatisfaction with any modifications made pursuant to this provision, or any policies or practices of MCFTech in providing the, including without limitation; any change in the Content, or any change in the amount or type of fees associated with accessing a Site or products purchased through the Oasis Marketplace is to cancel your subscription if one is in place and to stop using or viewing the Oasis Marketplace.
                    </p>
                    <p>
                        <u>To the Site</u> <br/>
                        MCFTech has the right to modify, suspend or discontinue the Oasis Marketplace that it owns, or any portion thereof at any time, including the availability of any area of the Oasis Marketplace. MCFTech may also impose limits on its services without notice or liability.
                    </p>
                    <p>
                        <strong>
                            General
                        </strong>
                    </p>
                    <p>
                        This Agreement constitutes the entire agreement between you and MCFTech with respect to the Oasis Marketplace and supersedes all prior agreements between you and MCFTech.  Failure by MCFTech to enforce any provision of this Agreement shall not be construed as a waiver of any provision or right.  Interpretation and enforcement of this Agreement shall be governed by the laws of the State of Ohio.  If any portion of this Agreement is held unenforceable, the unenforceable portion shall be construed in accordance with applicable law as nearly as possible to reflect the original intentions of the parties, and the remainder of the provisions shall remain in full force and effect.
                    </p>
                </div>
            </LegalWrapper>
        </>
    );

}

export default TermsOfService;