import React from 'react';


//styles
import styles from './index.module.css';


/**
 * Provides an oasis styled header element in teal.
 * @param {Object} props React component props object
 * @param {String} props.headerText The text to show in the header
 * @param {String} props.size one of h1, h2, h3, h4
 */
function HeaderElement({headertext, size}) {

    var HeaderElement = null;

    switch (size) {
        case "h2":
            HeaderElement = (<h2 className={styles["header-element"]}>{headertext}</h2>);
            break;
        case "h3":
            HeaderElement = (<h3 className={styles["header-element"]}>{headertext}</h3>);
            break;
        case "h4":
            HeaderElement = (<h4 className={styles["header-element"]}>{headertext}</h4>);
            break;
        default:
            HeaderElement = (<h1 className={styles["header-element"]}>{headertext}</h1>);
            break;
    }

    return (
        <>
            { HeaderElement }
        </>
    );

}



export default HeaderElement;