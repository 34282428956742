import React from 'react';

//semantic
import {
    Header,
    Segment,
    TransitionablePortal,
    Icon
} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

/**
 * Error popup that shows read.  Transitions fade in and fade out
 * @param {Object} props React component props object
 * @param {Boolean} props.isOpen Determines whether the popup should show/hide.
 * @param {String} props.errorTitle Title of the error message
 * @param {String} props.errorMsg Error body
 */
function ErrorPopup({isOpen=false, errorTitle='', errorMsg='', onOpen, onClose}) {

    return (
        <>
            <TransitionablePortal
                closeOnTriggerClick
                onOpen={onOpen}
                onClose={onClose}
                open={isOpen}
                openOnTriggerClick
            >
                <div className={styles['alert']}>
                    
                            <Segment
                                inverted color='red'
                            >
                                <Header><Icon name="exclamation triangle" size='large' />{errorTitle}</Header>
                                <p>{errorMsg}</p>
                            </Segment>
                </div>
                 
                
            </TransitionablePortal>
        </>
    );

}

export default ErrorPopup;