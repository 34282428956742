import React, { useState, useEffect } from "react";

//semantic
import {Form, Button, Message} from 'semantic-ui-react';

//custom
import SectionGroup from "../SectionGroup/SectionGroup";
import { isFormError_h, formHasErrors_h, generateFormErrorsArray_h } from "../../utils/formHelpers";
import { ApiError_h } from "../../utils/apiHelpers";
import { emailRegex, phoneRegex, creditCardExpirationRegex } from "../../utils/formRegexValidations";
import CONFIG from "../../config";
import { createPaymentProfile, getPaymentProfile, patchPaymentProfile } from "../../feathers/services/paymentProfiles";
import { updatePaymentProfile } from "../../store/actions/userActions";
import CreditCardIcon from "./CreditCardIcon/CreditCardIcon";
import { card } from "creditcards";

//authorize.net
import { obtainNonce } from './authorizeModel.js';

//redux
import { useDispatch, useSelector } from "react-redux";

//react hook form
import { useForm } from 'react-hook-form';

//styles
import styles from './index.module.css';


/**
 * TODO: Add this to typedef's file. 
 * ProfileObject typedef
 * @typedef {Object} PaymentProfile
 * @property {string} firstName - 
 * @property {string} lastName - Indicates whether the Power component is present.
 * @property {string} phone - Indicates whether the Wisdom component is present.
 * @property {string} city - Indicates whether the Wisdom component is present.
 * @property {string} company - Indicates whether the Wisdom component is present.
 * @property {string} email - Indicates whether the Wisdom component is present.
 * @property {string} expiration - Indicates whether the Wisdom component is present.
 * @property {string} zip - Indicates whether the Wisdom component is present.
 * @property {string} state - Indicates whether the Wisdom component is present.
 * @property {string} cardNumber - Indicates whether the Wisdom component is present.
 * @property {string} cardCode - Indicates whether the Wisdom component is present.
 * @property {string} address - Indicates whether the Wisdom component is present.
 * 
 */



 /**
  * This function captures payments for the whole application
  * @param {object} props
  * @param {function} props.successHandler Funciton to run after successful update of payment information.
  */
function PaymentCapture( {successHandler=null, existingPaymentProfilId=null} ) {


    /*************************************************
     *  React Hook Form
     *************************************************/
    var { register, handleSubmit, errors, setValue } = useForm();

    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    //current user logged in
    const { user } = useSelector(state => state);


    /*************************************************
     *  State
     *************************************************/
    var [isLoading, setIsLoading]                     = useState(false);
    var [cardErrors, setCardErrors]                   = useState([]);
    var [createProfileErrors, setCreateProfileErrors] = useState([]);
    var [formSubmitSuccess, setFormSubmitSuccess]     = useState(false);
    var [isFormLoading, setIsFormLoading]             = useState(false);
    var [creditCardNumber, setCreditCardNumber]       = useState('');


    

    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        //email is always set for payments to the email address of the users email for oasis.
        setValue('email', user.email);
        
        if (user.paymentProfile) {
            setIsFormLoading(true);
            getPaymentProfile( user.paymentProfile.customerPaymentProfileId ).then((res)=>{
                //utilize existing connection and set values if present
                setValue('firstName', res.billTo.firstName);
                setValue('lastName', res.billTo.lastName);
                setValue('company', res.billTo.company);
                setValue('address', res.billTo.address);
                setValue('city', res.billTo.city);
                setValue('state', res.billTo.state);
                setValue('zip', res.billTo.zip);
                setValue('cardNumber', res.payment.creditCard.cardNumber);
                setIsFormLoading(false);
                setCreditCardNumber(res.payment.creditCard.cardNumber);
            }).catch((err)=>{
                setIsFormLoading(false);
            });
        }
    }, [user, setValue]);


    /*************************************************
     *  Handlers
     *************************************************/
    const handleCreditCardChange = (e) => {
        var value = e.target.value;
        setCreditCardNumber(card.format(card.parse(value), "-"));
    };


    /**
     * Handles submittal
     * @param { PaymentProfile } paymentProfile
     */
    const onSubmit = async paymentProfile =>{
        paymentProfile.cardNumber = card.parse(paymentProfile.cardNumber);

        setCardErrors([]);
        setCreateProfileErrors([]);
        setIsLoading(true);

        //obtain nonce
        try {
            var authorizeResponse = await obtainNonce(paymentProfile, { clientKey: CONFIG.AUTHORIZE_PUBLIC_KEY, apiLoginID: CONFIG.AUTHORIZE_MERCHANT_NAME });
        } catch (error) {
            setIsLoading(false);
            setCardErrors(error);
            return;
        }

        //Only show last 4 of credit card after submittal
        var obfuscatedCard = "XXXX" + paymentProfile.cardNumber.substr(paymentProfile.cardNumber.length - 4);

        //remove payment information before sending to the server
        delete paymentProfile.cardNumber;
        delete paymentProfile.cardCode;
        delete paymentProfile.expiration;

        //get the sanitized payment profile data.
        var sanitizedPaymentProfileData = {
            ...paymentProfile,
            opaqueData: authorizeResponse
        };

        //if new user - create payment profile, else update existing payment profile
        var paymentProfileResponse = null;
        if ( user.paymentProfile ) {
            try {
                paymentProfileResponse = await patchPaymentProfile(JSON.stringify(user.paymentProfile), sanitizedPaymentProfileData);
            } catch (error) {
                setIsLoading(false);
                var err = new ApiError_h(error);
                setCreateProfileErrors([err.getMessage()]);
                return;
            }
        } else {
            //create payment profile
            try {
                paymentProfileResponse = await createPaymentProfile(sanitizedPaymentProfileData);
                //update local redux state with payment profile information
                dispatch(updatePaymentProfile(paymentProfileResponse));
            } catch (error) {
                setIsLoading(false);
                var errPayment = new ApiError_h(error);
                setCreateProfileErrors([errPayment.getMessage()]);
                return;
            }
        }
        

        //remove sensitive information
        setValue('cardNumber', obfuscatedCard);
        setValue('cardCode', '');
        setValue('expiration', '');
        setCreditCardNumber(obfuscatedCard);

        
        //set necessary state after success
        setIsLoading(false);
        setFormSubmitSuccess(true);
        if (successHandler) successHandler();
        //after 3 seconds hide the message success
        setTimeout(() => {
            setFormSubmitSuccess(false);
        }, 4000)
  
    };



    return (
        <>

            

            <Form
                loading= { isFormLoading }
                success={ formSubmitSuccess }
                onSubmit={handleSubmit(onSubmit)}
                error={formHasErrors_h(errors) || cardErrors.length > 0 || createProfileErrors.length > 0}
            >
                <SectionGroup
                    title="Billing Information"
                    description={!user.paymentProfile ? "Please enter your billing information below." : "Update your billing information below."}
                >
                    <Form.Group>
                        <Form.Field
                            width={6}
                            required
                            error={isFormError_h(errors.firstName)}>
                            <label>First Name</label>
                            <input
                                placeholder='First name...'
                                type='text'
                                name="firstName"
                                ref={register({ required: "First name is required."})}
                            />
                        </Form.Field>

                        <Form.Field
                            width={6}
                            required
                            error={isFormError_h(errors.lastName)}>
                            <label>Last Name</label>
                            <input
                                placeholder='Last name...'
                                type='text'
                                name="lastName"
                                ref={register({ required: "Last name is required." })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group>
                        <Form.Field
                            width={6}
                            error={isFormError_h(errors.email)}>
                            <label>Email</label>
                            <input
                                disabled
                                placeholder='Email...'
                                type='text'
                                name="email" 
                                ref={register({ required: "Email is required.", pattern: {value: emailRegex, message: "A valid email is required."} })}
                            />
                        </Form.Field>
                        <Form.Field
                            width={6}
                            error={isFormError_h(errors.phone)}>
                            <label>Phone</label>
                            <input
                                placeholder='Phone...'
                                type='text'
                                name="phone" 
                                ref={register({ pattern: {value: phoneRegex, message: "Phone number must be valid (xxx-xxx-xxxx)."} })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group>
                        <Form.Field
                            width={6}
                            error={isFormError_h(errors.company)}>
                            <label>Company</label>
                            <input
                                placeholder='Company...'
                                type='text'
                                name="company" 
                                ref={register()}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            width={12}
                            required
                            error={isFormError_h(errors.address)}>
                            <label>Address</label>
                            <input
                                placeholder='Address...'
                                type='text'
                                name="address" 
                                ref={register({ required: "Address 1 is required." })}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            width={4}
                            required
                            error={isFormError_h(errors.city)}>
                            <label>City</label>
                            <input
                                placeholder='City...'
                                type='text'
                                name="city"
                                ref={register({ required: "City is required." })}
                            />
                        </Form.Field>
                        <Form.Field
                            width={4}
                            required
                            error={isFormError_h(errors.state)}>
                            <label>State</label>
                            <input
                                placeholder='State...'
                                type='text'
                                name="state" 
                                ref={register({ required: "State is required." })}
                            />
                        </Form.Field>

                        <Form.Field
                            width={4}
                            required
                            error={isFormError_h(errors.zip)}>
                            <label>Zip</label>
                            <input
                                placeholder='Zip...'
                                type='text'
                                name="zip" 
                                ref={register({ required: "Zip code is required." })}
                            />
                        </Form.Field>
                    </Form.Group>
                </SectionGroup>  


                <SectionGroup
                    title="Payment Information"
                    description={user.paymentProfile ? "Update your credit card details below (card information hidden for security purposes)" : "Please enter your credit card payment information below."}
                >
                    <div className={styles["credit-card-icon"]}>
                        <CreditCardIcon 
                            creditCardType="Visa" 
                        />
                    </div>
                    <Form.Group>
                        <Form.Field
                            width={6}
                            required
                            error={isFormError_h(errors.cardNumber)}
                        >
                            <label>Card Number</label>
                            <div className="ui left icon input" data-children-count="1">
                                <input
                                    placeholder='Card Number...'
                                    type='text'
                                    name="cardNumber"
                                    ref={register({ required: "Credit card number is required." })}
                                    onChange={handleCreditCardChange}
                                    value={creditCardNumber}
                                />
                                <i className="credit card alternative icon"></i>
                            </div>
                        </Form.Field>
                        <Form.Field
                            width={6}
                            required
                            error={isFormError_h(errors.expiration)}>
                            <label>Expiration (mmyy)</label>
                            <input
                                placeholder='Expiration...'
                                type='text'
                                name="expiration" 
                                ref={register({ required: "Credit card expiration is required.", pattern: { value: creditCardExpirationRegex, message:"Expiration must be in MMYY format."} })}
                            />
                        </Form.Field>
                    </Form.Group>
                    

                    <Form.Group>
                        <Form.Field
                            width={6}
                            required
                            error={isFormError_h(errors.cardCode)}>
                            <label>Security Code</label>
                            <input
                                placeholder='Security code...'
                                type='text'
                                name="cardCode" 
                                ref={register({ required: "Security code is required." })}
                            />
                        </Form.Field>
                    </Form.Group>
                </SectionGroup>
             
                <div className={styles["payment-button"]}>
                    <Button
                        primary
                        loading={isLoading}>
                        Submit
                    </Button>
                </div>

                <div className={styles["form-messages"]}>
                    <Message
                        negative
                        error
                        header='There were some errors submitting this form'
                        list={generateFormErrorsArray_h(errors).concat(cardErrors, createProfileErrors)}
                    />

                    <Message
                        success
                        header='Success'
                        content="Your payment information was updated successfully!"
                    />
                </div>
                

            </Form>
        </>
    );

}

export default PaymentCapture;