import React, { useState } from 'react';

//semantic
import {Form, Message, Grid, Icon} from 'semantic-ui-react';

// import { createVerifyPromotion } from "../../feathers/services/promotionVerify";
// import { ApiError_h } from "../../utils/apiHelpers";

//styles
import styles from './index.module.css';



/**
 * Component takes a promotional code and checks if code is valid.  If it is, it displays valid promo text, if not, it displays a promo error.
 * @param {Object} props React component props object
 * @param {Boolean} props.loading Controls whether loading is showing or not
 * @param {Function} props.onPromotionChange What happens to the promotion when it changes, function to run.
 */
function PromotionInput({ loading, onPromotionChange, errorMsg, successMsg } ) {

    
    /*************************************************
     *  State
     *************************************************/
    var [promotionalCode, setPromotionalCode]               = useState('');
    var [submitCount, setSubmitCount]                       = useState(0);

    /*************************************************
     *  Needed Functions
     *************************************************/
    // const verifyPromotion = () => {
    //     if (invalidPromoErrorMsg) setInvalidPromoErrorMessage("");
    //     if (successPromoMsg) setSuccessPromoMsg("");
    //     if (submitCount === 0) setSubmitCount(1);
    //     setLoading(true);

    //     createVerifyPromotion({
    //         code: promotionalCode,
    //         subscription,
    //         pluginId
    //     }).then(res => {
    //         if (!res.validPromotion) {
    //             setInvalidPromoErrorMessage(res.details);
    //             onPromotionCheck(res);
    //         } else {
    //             setSuccessPromoMsg("Valid promotional code!");
    //             onPromotionCheck(res);
    //         }
    //         setLoading(false);

    //     }).catch(err => {
    //         setLoading(false);
    //         var error = new ApiError_h(err);
    //         setInvalidPromoErrorMessage(error.getUserMessage());
    //         onPromotionCheck({
    //             validPromotion: false,
    //             subscriptionPrice: null,
    //             discount: null,
    //             basePrice: null,
    //             details: error.getUserMessage()
    //         });
    //     });
    // };

    /*************************************************
    *  Effects
    *************************************************/
   //used IF a promotional code is present and someone changes a subscription
    // useEffect(()=>{
    //     if( subscription && promotionalCode && submitCount > 0 ) {
    //         verifyPromotion();
    //     }
    // }, [subscription.frequency])


    // useEffect(()=>{
    //     if (submitCount < 1) {
    //         onPromotionCheck({
    //             validPromotion: false,
    //             subscriptionPrice: null,
    //             discount: null,
    //             basePrice: null
    //         });
    //     }
    // }, [submitCount])
    

    /*************************************************
    *  Handlers
    *************************************************/
    const handleInputChange = (e, data)=>{
        setPromotionalCode(data.value);
        if( submitCount > 0 ) setSubmitCount(0);
        onPromotionChange('');
    };

    const handleApplyClick = (e, data)=>{
        e.preventDefault();
        onPromotionChange( promotionalCode );
        if(submitCount === 0) {
            setSubmitCount(1);
        }
    };

    return (
        <>
            <Form.Group>
                <Form.Field
                    width={16}
                    error={false}>
                    <label>Promotional Code</label>
                    <Form.Input
                        action={{
                            loading,
                            color: 'black',
                            content: 'Apply',
                            onClick: handleApplyClick
                        }}
                        placeholder='Enter your code...'
                        onChange={handleInputChange}
                    />
                </Form.Field>
            </Form.Group>

            {/* Error and Confirmation Messages */}
            <Grid>
                <Grid.Column mobile={16} tablet={10} computer={16} largeScreen={16} widescreen={16}>
                    
                        <div className={styles["promotion-messages-wrapper"]}>
                            {((errorMsg || successMsg) && submitCount > 0) &&
                                <>
                                    {errorMsg &&
                                        <Message warning visible={true}>
                                            <p><Icon color="brown" name="circle warning" />{errorMsg}</p>
                                        </Message>
                                    }

                                    {successMsg &&
                                        <Message info>
                                            <p><Icon color="teal" name="circle info" />{successMsg}</p>
                                        </Message>
                                    }

                                </>
                            }

                            {((!successMsg && !errorMsg) || submitCount === 0) &&
                                <div className={styles["no-promotion-msg"]}>
                                    Click "Apply" to apply this promotional code.
                                </div>
                            }
                        </div>
                    
                    
                    <div className="mb-small"></div>
                </Grid.Column>
            </Grid>
            {/* Error and Confirmation Messages */}

        </>
    );

}

export default PromotionInput;