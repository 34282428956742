/**
 * Houses all sagas for config service
 */
import {takeLatest, call, fork, put} from 'redux-saga/effects';
import * as actions from '../actions/qbTableActions';
import * as types from '../types';
import * as api from '../../feathers/services/qbTables';


/**
 * Get Quick base Tables service saga's
 */

//get qbtables api call
function* getQbTables(action) {

    try {
        const connectionId = action.payload;
        const response = yield call(api.getQbTables, connectionId);

        //dispatch action to set success state for api call
        yield put(actions.getQbTablesSuccess(response.data));

    } catch (error) {
        console.error("getQbTables Saga Error", error);
        yield put(actions.getQbTablesFailure(error));
    }

}

// Create qbtables api call

function* createQbTables(action) {

    try {
        const response = yield call(api.createQbTable, action.payload);

        yield put(actions.createQbTableSuccess(response));

        const {connectionId} = response;

        yield put(actions.getQbTables(connectionId))

    } catch (error) {
        console.error("CreateQBTables Failure Saga Error", error);
        yield put(actions.createQbTableFailure(error));
    }

}


// Get qb tables watcher function
function* watchGetQbTables() {
    yield takeLatest(types.GET_QB_TABLES, getQbTables);
}

// Create qb table watcher function
function* watchCreateQbTables() {
    yield takeLatest(types.CREATE_QB_TABLE, createQbTables);
}


//Export all saga's here
const qbTableSagas = [
    fork(watchGetQbTables),
    fork(watchCreateQbTables)
];

export default qbTableSagas;

