
/**
 * Parse the React-Router-Dome search object value for example search:'?key=abcd1234&val=123&val2&val3=other'
 *
 * @param query
 *
 * @return {object} Return all the parameters
 * queryParams =
 *   {
 *       key:"asdfghjkl1234567890",
 *       val:"123",
 *       val2:true,
 *       val3:"other"
 *   }
 *
 */
function useQueryParameter(query) {

    if(query.length > 0) {

        const queryArray = query.split('?')[1].split('&');
        let queryParams = {};
        for (let i = 0; i < queryArray.length; i++) {
            const [key, val] = queryArray[i].split('=');
            queryParams[key] = val ? val : true;
        }

        return queryParams

    }

    return false;
}


export  default useQueryParameter;