/**
 * Contains some functions for mapping utils.
 */

import { createUUID_h } from "../../../utils/uuidGenerator";
import cloneDeep from "lodash.clonedeep";



 /**
  * 
  * @param {Array} configSections Array of objects of configsections for this plugin (configSection settings for this)
  * @param {Object} formData React Hook Form form data.
  * @return {Object} Returns the finalized mapped config object.
  */
export const generateConfigObjectFromFormData_h = (configSections, formData) => {
    var mappedConfigurationObject = {};
    formData                      = {...formData};

    configSections.forEach((configSection) => {

        const {
            name,
            repeatable
        } = configSection;

        if (!repeatable) {
            Object.keys(formData).forEach(function (fieldKey) {
                var formDataSectionName = fieldKey.substring(0, fieldKey.indexOf('__'));
                var formDataFieldName = fieldKey.substring(fieldKey.indexOf("__") + 2);
                if (formDataSectionName === name) {
                    if (!mappedConfigurationObject[formDataSectionName]) {
                        mappedConfigurationObject[formDataSectionName] = {};
                    }
                    mappedConfigurationObject[formDataSectionName][formDataFieldName] = formData[fieldKey];
                    delete formData[fieldKey];
                };
            });
        } else {
            var repeatSectionObject = {};
            Object.keys(formData).forEach(function (fieldKey) {
                var formDataSectionName = fieldKey.substring(0, fieldKey.indexOf('__'));
                var formDataSectionNameIncludingUUID = fieldKey.substring(0, fieldKey.lastIndexOf('__'));
                var formDataFieldName = fieldKey.substring(fieldKey.lastIndexOf("__") + 2);
                //if the field is part of this particular section
                if (formDataSectionName === name) {
                    //if this particular repeat section doesn't exist yet, create it.
                    if (!repeatSectionObject[formDataSectionNameIncludingUUID]) {
                        repeatSectionObject[formDataSectionNameIncludingUUID] = {};
                    }
                    repeatSectionObject[formDataSectionNameIncludingUUID][formDataFieldName] = formData[fieldKey];

                    if (!mappedConfigurationObject[formDataSectionName]) {
                        mappedConfigurationObject[formDataSectionName] = [];
                    }
                    delete formData[fieldKey];
                }
            });

            Object.keys(repeatSectionObject).forEach(function (sectionKey) {
                mappedConfigurationObject[name].push(repeatSectionObject[sectionKey]);
            });
        }
    });

    return mappedConfigurationObject;
};







/**
 * This takes the configMapping from the configuration and creates configSections that can be utilized by this UI with "defaultValues" input from the existing configMapping values
 * @param {Object} configMapping This is an object with the existing chosen values for reach field and each section.  Comes straight from the configurations in database for this particular user.
 * @param {Object} configSections The actual database plugin configSections for this particular plugin.
 * @returns {Array} Returns an array of configSections ready to render
 */
export const generateConfigSectionsFromConfigMapping_h = (configMapping, configSections) => {

    var updatedConfigSections = [];
    configSections.forEach((configSection) => {
        var updatedConfigSection = cloneDeep(configSection);
        var configMappingItem    = configMapping[updatedConfigSection.name];

        //if there is no values for this particular configSection, then just push the default.
        if (!configMappingItem) {
            updatedConfigSections.push(updatedConfigSection);
        } else {
            //config section is not repeatable
            if (!updatedConfigSection.repeatable) {
                Object.keys(configMappingItem).forEach(function (fieldKey) {
                    if (fieldKey === 'dbid' && updatedConfigSection.type === 'qbConfig') {
                        updatedConfigSection.defaultValue = configMappingItem[fieldKey];
                    } else {
                        for (var i = 0; i < updatedConfigSection.fields.length; i++) {
                            var field = updatedConfigSection.fields[i];
                            if (field.name === fieldKey) {
                                field.defaultValue = configMappingItem[fieldKey];
                                break;
                            }
                        }
                    }
                });
                updatedConfigSections.push(updatedConfigSection);
            //config section is repeatable
            } else {
                // if configsection is of type "repeatable", then it is an array of configItems
                configMappingItem.forEach((configValuesObject, i) => {
                    let mappingConfigSection = cloneDeep(configSection);
                    Object.keys(configValuesObject).forEach(function (fieldKey) {
                        if (fieldKey === 'dbid' && mappingConfigSection.type === 'qbConfig') {
                            mappingConfigSection.defaultValue = configValuesObject[fieldKey];
                        } else {
                            for (let v = 0; v < mappingConfigSection.fields.length; v++) {
                                let field = mappingConfigSection.fields[v];
                                if (field.name === fieldKey) {
                                    field.defaultValue = configValuesObject[fieldKey];
                                    break;
                                }
                            }
                        }
                    });
                    //for each repeatable configsection that isn't the parent, add child naming convention
                    if( i > 0 ) {
                        mappingConfigSection.name = `${mappingConfigSection.name}__${createUUID_h()}`;
                    }
                    updatedConfigSections.push(mappingConfigSection);
                });
            }
        }
    });
    return updatedConfigSections;
};