import React, {useEffect, useState} from 'react';

//semantic
import {
    Button, 
    Header,
    Icon,
    Grid
} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

/**
 * Component is used for making users accept a cookie consent.  Stores their answer in localstorage.
 */
function CookieConsent() {

    /*****************************************
     * State 
     *****************************************/
    const [open, setOpen] = useState(false);

    /*****************************************
     * Effects
     *****************************************/
    useEffect(()=>{
        if( localStorage.getItem('oasisCookieConsent') != "accepted" ) {
            setOpen(true);
        }
    }, []);


    /**
     * there is scrolling issue when this shows over another modal popup.  For instance, IF you have  modal open on page load, and this cookie consent gets added over top of this, the modal behind this cookie consent WILL NOT allow scrolling after someone accepts the cookie consent.  This fixes that issue.
     */
    // const fixbody = ()=>{
    //     const anotherModal = document.getElementsByClassName('ui page modals').length;
    //     if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
    // };


    return (
        <>
            {open &&
                <div className={styles["cookie-consent-wrapper"]}>
                    <Grid centered>
                        {/*Main Map form*/}
                        <Grid.Column mobile={16} table={16} computer={10} largeScreen={8} widescreen={5}>
                            <Header textAlign="center" as="h3" icon className={styles["cookie-header"]}>
                                <Icon color="teal" name='alarm' />
                                Cookie Consent
                            </Header>
                            <p className={styles["cookie-verbiage"]}>
                                This site uses cookies to provide you with a great user experience, show you targeted content and to analyse our website traffic.  By browsing our website, you consent to our use of cookies.
                            </p>
                            <Button floated="right" color="teal" inverted onClick={() => { setOpen(false); localStorage.setItem('oasisCookieConsent', 'accepted'); }}>
                                <Icon name='checkmark' /> Accept
                            </Button>
                        </Grid.Column>
                    </Grid>
                </div>
            }

            {/* <Modal
                basic
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size='small'
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
                <Header icon>
                    <Icon color="teal" name='alarm' />
                    Cookie Consent
                </Header>
                <Modal.Content>
                    <p className={styles["cookie-verbiage"]}>
                        This site uses cookies to provide you with a great user experience, show you targeted content and to analyse our website traffic.  By browsing our website, you consent to our use of cookies.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="teal" inverted onClick={() => { setOpen(false); localStorage.setItem('oasisCookieConsent', 'accepted'); fixbody()}}>
                        <Icon name='checkmark' /> Accept
                    </Button>
                </Modal.Actions>
            </Modal> */}
        </>
    );

}

export default CookieConsent;