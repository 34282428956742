/**
 * Houses all sagas for connection service
 */
import {takeLatest, call, fork, put} from 'redux-saga/effects';
import * as actions from '../actions/pluginsActions';
import * as types from '../types';
import * as api from '../../feathers/services/plugins';


/**
 * Find plugin
 */
function* getPluginsData() {
    try {
        const result = yield call(api.getPluginData);// request and loading
        yield put(actions.getPluginsSuccess(result.data)); // put the value in the store
    } catch (error) {
        yield put(actions.getPluginsFailure(error)); // put the error in the error store
    }
}


/**
 * Get Single plugin record
 */
function* getPluginById(action) {

    try {

        const result = yield call(api.getPluginById, action.payload);

        yield put(actions.getPluginSuccess(result));

    } catch (error) {

        yield put(actions.getPluginFailure(error)); // put the error in the error store

    }

}


function* watchGetPluginData() {
    yield takeLatest(types.GET_PLUGINS, getPluginsData);
}

function* watchGetPluginByIdData() {
    yield takeLatest(types.GET_PLUGIN, getPluginById);
}


//Export all saga's here
const pluginsSagas = [
    fork(watchGetPluginData),
    fork(watchGetPluginByIdData)
];

export default pluginsSagas;

