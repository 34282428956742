import client from '../index';




/**
 * Tries to re-authenticate the user by grabbing the token in localstorage and authenticating with the server
 */
export function reAuthenticate() {
    return client.reAuthenticate();
}


/**
 * Logs the user out of the application
 */
export function logUserOut() {
    return client.logout();
}


/**
 * @typedef {Object} LoginSuccessObject
 * @property {string} _id The _id of the user.
 * @property {number} email The email of the user
 */
/**
 * Logs the user in and sets the JWT in localstorage.
 * @param {string} email the email of the user trying to log in.
 * @param {string} password the password of the user trying to log in.
 * @return {Promise<LoginSuccessObject>} Responds with a promise that returns a user email and _id.
 */
export function login( email, password ) {

    return client.authenticate({
        strategy: 'local',
        email, 
        password
    }).then((res)=>{
        const { isVerified } = res.user;
        if(!isVerified) return Promise.reject("Users email is not verified.");
        return Promise.resolve( res.user );
    });

};




/**
 * @typedef {Object} UserObject
 * @property {string} firstName The first name of the user
 * @property {string} lastName The last name of the user
 * @property {email} email Users email
 * @property {password} password User password
 */
/**
* @typedef {Object} CreateUserResponseObject
* @property {string} firstName The first name of the user
* @property {string} lastName The last name of the user
* @property {string} email Users email
* @property {string} _id User password
*/
/**
 * Adds a new user to the database
 * @param {UserObject} user The user object.
 * @return {Promise<CreateUserResponseObject>} Responds with a promise that returns a user email and _id.
 */
export function addNewUser( user ) {

    return client.service('users')
    .create( user );

}