import React from 'react';
import { Link } from "react-router-dom";


//semantic
// import {} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';

/**
 * Generates a contact link
 * @param {Object} props React component props object
 * @param {} props.linkText The link text to render
 */
function ContactUsLink({linkText}) {

    return (
        <Link to="/contact">
            {linkText}
        </Link>
    );

}

export default ContactUsLink;