import React, { useState } from 'react';

//semantic
import { Grid,
        Button } from 'semantic-ui-react';

//styles
import styles from './index.module.css';

//custom components
import HomePageInput from '../HomePageInput/HomePageInput';
import HomePageMsg from '../HomePageMsg/HomePageMsg';
import HomePageTitle from '../HomePageTitle/HomePageTitle';


/**
 * Responsible for allowing users to reset their password.  Sends a reset request to their email.
 */
function UpdatePw() {

    


    //conditionally handle text and elements to be shown based on form submital & non-submittal
    const [isSubmitted] = useState(false);
    var msgText    = "Enter your new password below.";
    var iconName   = null;
    var buttonText = null;
    if (isSubmitted) {
        msgText    = "Your password has been successfully reset.  Click the link below to login."
        iconName   = "checkmark";
        buttonText = "Log in!"
    };

    return (
        <>
            <Grid centered>
                <Grid.Column mobile={16} tablet={10} computer={6} largeScreen={4} widescreen={4}>

                    <HomePageTitle 
                        title="Password Reset"
                    />

                    <HomePageMsg
                        msgText={ msgText }
                        iconName={ iconName }
                        buttonText={ buttonText }
                        link="/login"
                    />

                    {/* conditional content shown when form not submitted */}
                    {!isSubmitted &&
                        <div className={styles['margin-top']}>
                            <HomePageInput
                                labelText="New Password"
                                placeholder="Enter your password..."
                                type="text"
                            />

                            <HomePageInput
                                labelText="Retype Password"
                                placeholder="Retype your new password..."
                                type="password"
                            />

                            <div className={styles["margin-top"]}>
                                <Button className={styles["margin-top"]} type='submit' floated='right' primary>Submit</Button>
                            </div>
                        </div>
                    }
                    {/* conditional content shown when form not submitted */}
                   
                </Grid.Column>
            </Grid>
        </>
    );

}

export default UpdatePw;