import React from 'react';

//semantic
import {Grid} from 'semantic-ui-react';

//styles
// import styles from './index.module.css';

import Account from '../Account.js';
import PaymentNav from '../PaymentNav/PaymentNav';
import PaymentCapture from '../../PaymentCapture/PaymentCapture';

function PaymentMethod() {

    

    return (
        <>
            <Account pageTitle={"Payment Method"} helpPopup={"This part of your account allows updates to your current payment method."}>

                <Grid>

                    <Grid.Row>

                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>

                            <PaymentNav
                                activeItem="Payment Method"
                            />
                            
                            <div className='mb-small' />

                            <PaymentCapture />                 

                        </Grid.Column>

                    </Grid.Row>

                </Grid>

            </Account>
        </>
    );

}

export default PaymentMethod;