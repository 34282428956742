import React from 'react';
import ContactUsLink from "../ContactUsLink";


//semantic
// import { } from 'semantic-ui-react';

//styles
// import styles from './index.module.css';
import LegalWrapper from '../LegalWrapper/LegalWrapper';

function PrivacyPolicy() {



    return (
        <>
            <LegalWrapper
                title="OASIS PRIVACY POLICY"
                updatedDate="1/01/2021"
            >
                <div class="legal">
                   <p>
                        <strong>Our Commitment to Privacy</strong> – The Oasis Marketplace (“we, “our, or “us”) is committed to protecting the privacy of the people that access this website.  In this policy we will refer to the people accessing the website as “you” or ““your”.  Online privacy is constantly evolving, and we will endeavor to take all reasonable efforts to maintain up to date and effective online privacy standards and practices.  By accessing the Oasis Marketplace, you are agreeing to the terms and conditions of this Privacy Policy.  We may from time to time, modify this Privacy Policy to reflect legal, technological and/or other developments.  In that event, the changes will appear at this location and be effective for all access occurring after such changes are included herein.
                   </p>
                   <p>
                        <strong>Information We May Collect</strong>: The Privacy Policy applies to all information collected or submitted on the Oasis Marketplace and does not apply to information you may provide to us offline.  We may collect an email address, a password of your choosing, and the recorded history of your activity on the Oasis Marketplace.  We reserve the right to use your recorded activity and sell or give it to a third party.  We are the sole owners of the information collected on this Oasis Marketplace.  We will also use “cookies” on our website.  By access of this Oasis Marketplace, you provide prior consent to our use of cookies.  See Section 4 below for further information on cookies.
                   </p>
                   <p>
                        There are a variety of situations in which we may collect personal information, such as when you register as a customer; purchase one of our products; download free evaluation software, upgrades, or white papers; order email newsletters; respond to a marketing campaign; place orders on our Oasis Marketplace; or when you’re working with our service representatives.  In each of those situations, we will collect only the personal information required to respond to your requests or to provide the products or services desired.  Depending on the circumstances, we may ask for your name, company name, Internet domain name, industry, IP address, billing or shipping address, credit card information, email address, fax number, phone number, or other pertinent information.
                   </p>
                   <p>
                        We will use the information you provide to better understand your needs, provide you with the services you have requested, and with a view to provide you with better service.  We may use your information to help you complete a transaction, including fulfillment of orders and promotional offers, to communicate back to you, to inform you of and update you on services and benefits, and to manage and renew your agreement(s).  Credit card numbers are used only for payment processing, maintenance of your agreements, and fraud protection and are not used for other purposes without your permission.  We will keep your personal information for as long as is reasonably necessary to provide you products and services, for internal reporting and reconciliation purposes and to provide you with any feedback or information that you might request or require.  
                   </p>
                   <p>
                        You may opt out of any future contacts from us at any time.  You can do the following at any time by contacting us via the <ContactUsLink linkText="contact page."/>.
                   </p>
                   <ul>
                        <li>See what data we have about you, if any.</li>
                        <li>Change/correct any data we have about you.</li>
                        <li>Have us delete any data we have about you.</li>
                        <li>Express any concern you have about our use of your data.</li>
                   </ul>
                   <p>
                        With the consent of our customers, we may post customer testimonials on our Oasis Marketplace, which may contain personally identifiable information, such as the customer’s name.
                   </p>
                   <p>
                        If at any time you wish to remove your personal information from our Oasis Marketplace testimonial, please visit the <ContactUsLink linkText="contact page" /> and submit a support request.
                   </p>
                   <p>
                        In certain circumstances, we may need to share your information in order to meet your needs.  We have several relationships with vendors with whom we may share your personal information for the purpose of providing a service, fulfilling an order, or meeting your other needs, including the provision of customer support.  For example, we use selected vendors for the purpose of providing credit card transaction and check processing services.  These vendors verify your purchase information, such as credit card number or checking account number, and authorize your transaction.  In doing so, the vendors have access to limited information about users, but they are prohibited from using the information for any other purpose.  We impose confidentiality obligations on all third-party vendors with whom we share your information.  By providing your information to us, you are deemed to consent to the transfer of your information to such third-party vendors in accordance with the terms of this Privacy Policy.
                   </p>
                   <p>
                        We may also need to share your personal information with other of our affiliates.  By an affiliate, we mean any person or entity that control, is controlled by or is under common control with us.  All such affiliates will adhere to this Privacy Policy.  From time to time, we may provide personal information to law enforcement authorities or other third parties in response to a subpoena, an official inquiry, or as may be required in the case of national security.  We will not disclose your personal information to third parties for their own marketing purposes unless you have provided consent.
                   </p>
                   <p>
                        <strong>Children:</strong> We do not knowingly collect or maintain any personal information from children under the age of 13.  In addition, no part of the Oasis Marketplace is designed with the purpose of attracting any person under age 13.  
                   </p>
                   <p>
                        <strong>How we use cookies:</strong> A cookie is a small file that asks permission to be placed on your computer's hard drive.  The file is added, and the cookie helps analyze web traffic or lets you know when you visit a particular Oasis Marketplace.  We use cookies to provide easier Oasis Marketplace navigation and access to forms.  By accessing our website, you consent to our use of cookies and the placing of cookies on the hard drive of your computer.  Overall, cookies help us provide you with a better Oasis Marketplace, by, among other things, enabling us to monitor which pages of the Oasis Marketplace you find useful and which you do not.  A cookie does not give us access to your computer or any information about you, other than the data you choose to share with us.  Most web browsers automatically accept cookies, but you may modify your browser setting to decline cookies if you prefer or you may delete cookies already on your hard drive.  However, this may prevent you from taking full advantage, or any access to the services regarding the Oasis Marketplace.  The duration period of a cookie will be if is reasonably necessary to aid in your access to the Oasis Marketplace and to provide the products and services that you request from the Oasis Marketplace.  This provision is intended to comply with the European Union’s E-Privacy Directive.  If you are located in a member state of the European Union and believe that the Oasis Marketplace does not comply with such Directive, please immediately cease use of the Oasis Marketplace and notify us.
                   </p>
                   <p>
                        <strong>Links to Other Websites:</strong> The Oasis Marketplace may contain links to other websites of interest.  However, once you have used these links to leave the Oasis Marketplace, you should note that we do not have any control over the other websites.  Therefore, we cannot be responsible for the protection and privacy of any information that you provide while visiting such sites and such sites are not governed by this privacy statement.  You should exercise caution and look at the privacy statement applicable to the website in question.
                   </p>
                   <p>
                        <strong>Questions or Concerns</strong> If you have privacy-related questions that are not addressed here, please <ContactUsLink linkText="contact us here." />
                   </p>
                </div>
            </LegalWrapper>
        </>
    );

}

export default PrivacyPolicy;