import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';


//redux
import { useSelector } from "react-redux";
//react hook form
import { useForm } from 'react-hook-form';

import { createInquiry } from '../../feathers/services/inquiries';

//semantic
import {
    Container, Grid, Button, Form, Message
} from 'semantic-ui-react';

import {
    useLocation
} from "react-router-dom";

import { formHasErrors_h, generateFormErrorsArray_h, isFormError_h  } from "../../utils/formHelpers";

import { emailRegex } from "../../utils/formRegexValidations";
import { ApiError_h } from '../../utils/apiHelpers';
import { createSemanticDropdownObjects_h } from "../../utils/formHelpers";

import ConfigInputLabel from "../ConfigInputLabel/ConfigInputLabel";
import PopulatePluginStore from "../../hoc/PopulatePluginStore/PopulatePluginStore";


//styles
import styles from './index.module.css';
import ThankYou from "./ThankYou";

// Sub components
import useQueryParameter from "../../utils/useQueryParameter";




/**
 * Main contact us page component used for showing contact
 * form including contact form for the Author.
 */
function Contact() {

    const queryParams = useQueryParameter(useLocation().search);
    const contactOptions = [
        { key: '1', value: 'product', text: 'Product Feedback/Question' },
        { key: '2', value: 'technical', text: 'Technical Issue' },
        { key: '3', value: 'billing', text: 'Billing Inquiry' },
        { key: '3', value: 'proinstall', text: 'Pro Install' }
    ];

   

    /*************************************************
     *  React Hook Form
     *************************************************/
    var { register, handleSubmit, errors, setValue, watch, triggerValidation, formState } = useForm();
    const watchInquiryType = watch('inquiryType');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const watchPlugin      = watch('plugin');
    const {isSubmitted}    = formState;

    /*************************************************
     *  Redux
     *************************************************/
    //current user logged in
    const { user }                  = useSelector(state => state);
    const { isLoading, allPlugins } = useSelector(state => state.plugins);



    /*****************************************
     * Query Parameters
     *****************************************/
    const { pluginId }        = useParams();
    const isProInstallInquiry = queryParams && queryParams.proinstall && queryParams.proinstall === "true";

    /*****************************************
     * State 
     *****************************************/
    const [isFormSubmitted, setIsSubmitted]                 = useState(false);
    const [submitIsLoading, setsubmitIsLoading]             = useState(false);
    const [createInquiryErrors, setCreateInquiryErrors]     = useState([]);
    const [pluginDropdownOptions, setPluginDropdownOptions] = useState([]);
    const [pluginDropdownValue, setPluginDropdownValue]     = useState('');


    /*************************************************
     *  Effects
     *************************************************/
    useEffect(()=>{
        if(pluginId) {
            setValue('inquiryType', 'plugin', true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ( user.email ) {
            //utilize existing connection and set values if present
            setValue('user_id', user._id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // useEffect(()=>{
    //     if (pluginId) {
    //         setValue('plugin_id', pluginId);
    //     } 
    // }, [pluginId]);

    //generates plugin dropdown options from store
    useEffect(()=>{
        if(allPlugins && allPlugins.length > 0) {
            setPluginDropdownOptions(createSemanticDropdownObjects_h(allPlugins,"_id", "name", "_id"));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPlugins]);

    //sets default plugin
    useEffect(()=>{
        if ( pluginId && allPlugins.length > 0 ) {
            var defaultPlugin = allPlugins.find(plugin=>plugin._id === pluginId);
            if(defaultPlugin) {
                setPluginDropdownValue(defaultPlugin._id);
                setValue('plugin', defaultPlugin._id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginDropdownOptions, allPlugins]);

    //Used to trigger revalidation of plugin after submittal 
    useEffect(() => {
        if (isSubmitted)
            triggerValidation('plugin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchInquiryType]);


    /**
     * Show different Page title based on type of contact.
     * If the autherId parameter is present in the URL then that means
     * the contact form should send email to the author
     */
    const headerTitle = () => {
        if (pluginId) {
            return "PLUGIN QUESTION";
        } else {
            return "CONTACT US!";
        }
    };

    const headerContent = () => {
        if (pluginId) {
            return <>
                Have questions about a plugin?  Fill in the details below!
            </>
        } else {
            return <>
                Have questions? Fill in the form and we will get back to you as soon as possible!
            </>
        }
    };


    /**
     * Handles submittal
     * @param { PaymentProfile } paymentProfile
     */
    const onSubmit = async inquiry => {
        setsubmitIsLoading(true);
        setCreateInquiryErrors([]);
        createInquiry( inquiry ).then((res)=>{
            setIsSubmitted(true);
            setsubmitIsLoading(false);
        }).catch((err)=>{
            setsubmitIsLoading(false);
            var error = new ApiError_h(err);
            var errorText = error.getUserMessage();
            setCreateInquiryErrors([...createInquiryErrors, errorText]);
        });
    };


    const handleChange = (fieldName, e, element) => {
        setValue(fieldName, element.value, true);
        // setValue(element.value);
        if(fieldName === "plugin") {
            setPluginDropdownValue(element.value);
        }
    };




    return (
        <PopulatePluginStore>
            {isFormSubmitted &&
                <ThankYou
                    content={"Your message was sent successfully. Please allow some time for us to process this request and respond."} />
            }
            
            {!isFormSubmitted &&
                <Container className={"mb-medium"}>

                    <Grid centered>

                        <Grid.Row>

                            {/*Main Map form*/}
                            <Grid.Column mobile={16} table={16} computer={8} largeScreen={6} widescreen={6}>

                                <div className={styles["wrapper"]}>

                                    <div className={"mb-large"} />

                                    <h2 className={styles["title"]}>{headerTitle()}</h2>

                                    <p className={styles["hero-text"]}>{headerContent()}</p>

                                    <div className={"mb-medium"} />

                                    <Form
                                        className={styles['input-form']}
                                        onSubmit={handleSubmit(onSubmit)}
                                        error={formHasErrors_h(errors) || createInquiryErrors.length > 0}>
                                        

                                        <ConfigInputLabel
                                            label="Inquiry Type" 
                                        />
                                        <Form.Dropdown
                                            defaultValue={ isProInstallInquiry ? "proinstall" : pluginId && "product"}
                                            placeholder="Select inquiry type..."
                                            search
                                            clearable
                                            fluid
                                            selection
                                            options={contactOptions}
                                            onChange={(e, element)=>handleChange("inquiryType", e, element)}
                                            error={isFormError_h(errors['inquiryType'])}
                                            ref={register({ name: "inquiryType" }, { required: "Inquiry type is required." })}
                                        />

                                        <ConfigInputLabel
                                            label="Inquiry related to a Plugin?"
                                            // required={watchInquiryType === "product"}
                                            required={false}
                                        />
                                        <Form.Dropdown
                                            loading={ isLoading }
                                            value={ pluginDropdownValue }
                                            placeholder="Select a plugin..."
                                            search
                                            clearable
                                            fluid
                                            selection
                                            options={ pluginDropdownOptions }
                                            onChange={(e, element) => handleChange("plugin", e, element)}
                                            error={isFormError_h(errors['plugin'])}
                                            ref={register(
                                                { name: "plugin" }
                                                // ,
                                                // {
                                                //     validate: {
                                                //         required: (value) => {
                                                //             console.log(value, watchInquiryType);
                                                //             if (!value && watchInquiryType === "product")
                                                //                 return "Please select a plugin above.";
                                                //             return true;
                                                //         }
                                                //     }
                                                // }
                                            )}
                                        />
                                        

                                        {!user.email &&
                                            <Form.Group>
                                                <Form.Field
                                                    width={16}
                                                    error={isFormError_h(errors.name)}>
                                                    <ConfigInputLabel
                                                        label="Your Name"
                                                    />
                                                    <input
                                                        placeholder='First name...'
                                                        type='text'
                                                        name="name"
                                                        ref={register({ required: "Your name is required." })}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                        }

                                        {!user.email &&
                                            <Form.Group>
                                                <Form.Field
                                                    width={16}
                                                    error={isFormError_h(errors.email)}>
                                                    <ConfigInputLabel
                                                        label="Your Email"
                                                    />  
                                                    <input
                                                        placeholder='First name...'
                                                        type='email'
                                                        name="email"
                                                        ref={register({ required: "A valid email is required.", pattern: { value: emailRegex, message: "Email must be valid." } })}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                        }

                                        <Form.Group>
                                            <Form.Field
                                                width={16}
                                                error={isFormError_h(errors.subject)}>
                                                <ConfigInputLabel
                                                    label="Subject"
                                                />
                                                <input
                                                    defaultValue={
                                                        isProInstallInquiry ? "Pro Install Request!" : ""
                                                    }
                                                    placeholder='Subject...'
                                                    type='text'
                                                    name="subject"
                                                    ref={register({ required: "A subject is required." })}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        
                                        <Form.Group>
                                            <Form.Field
                                                width={16}
                                                error={isFormError_h(errors.message)}>
                                                <ConfigInputLabel
                                                    label="Message"
                                                />
                                                <textarea
                                                    placeholder='Message...'
                                                    type='text'
                                                    name="message"
                                                    ref={register({ required: "A message is required." })}
                                                    defaultValue={
                                                        isProInstallInquiry ?
                                                            "Please have an MCF Technology Solutions employee contact me as soon as possible so I can schedule an installation for the plugin selected above!" : ""
                                                    }
                                                />
                                            </Form.Field>
                                        </Form.Group>


                                        {/* {pluginId &&
                                            <input
                                                ref={register()}
                                                type="hidden"
                                                name='plugin_id' />
                                        } */}

                                        {user.email &&
                                            <input
                                                ref={register()}
                                                type="hidden"
                                                name='user_id' />
                                        }

                                        <input
                                            ref={register()}
                                            style={{ display: "none" }}
                                            type="text"
                                            name='user_data' 
                                        />

                                        <div className="text-right">
                                            <br />
                                            <Button 
                                                primary 
                                                loading={submitIsLoading}>
                                                Send
                                            </Button>
                                        </div>

                                        <Message
                                            negative
                                            error
                                            header='Form Errors:'
                                            list={generateFormErrorsArray_h(errors).concat(createInquiryErrors)}
                                        />

                                    </Form>


                                </div>

                            </Grid.Column>

                        </Grid.Row>

                    </Grid>

                </Container>
            }
        </PopulatePluginStore>
    );

}

export default Contact;