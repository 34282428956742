import React, { useState } from 'react';

//semantic
import {Grid, Button} from 'semantic-ui-react';
//react hook form
import { useForm } from 'react-hook-form';

import {ApiError_h} from "../../utils/apiHelpers";

//styles
import styles from './index.module.css';

//custom components
import HomePageMsg from '../HomePageMsg/HomePageMsg';
import HomePageTitle from '../HomePageTitle/HomePageTitle';
import HomePageInput from '../HomePageInput/HomePageInput';
import HomePageError from '../HomePageError/HomePageError';
import { authManagementSendResetPwd } from '../../feathers/services/authmanagement';




/**
 * View to put in a password reset request
 */
function ResetPw() {

    //handles form submittal and UI state changes
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrorText, setFormErrorText] = useState(null);

    /*************************************************
    *  React Hook Form
   *************************************************/
    const { register, handleSubmit, errors } = useForm();

    


    //conditionally handle text and elements to be shown based on form submital
    var msgText  = "Enter your email below and instructions to reset your password will be sent.";
    var iconName = null;
    if ( isSubmitted ) {
        msgText = "We have sent instructions to reset your password to the email provided.  If the account exists, you will receive an email momentarily.  The link in the email will be active for 2 hours"
        iconName = "checkmark";
    };


    const onSubmit = formData => {
        const { email } = formData;
        setIsLoading(true);

        authManagementSendResetPwd( email ).then((res)=>{
            setIsLoading(false);
            setIsSubmitted(true);
        }).catch((err)=>{
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const errorMsg = new ApiError_h(err);
            setIsLoading(false);
            setFormErrorText('Error sending necessary email.  If the problem persists, please contact support.');
        });

    };

    return (
        <>

            <HomePageTitle 
                title="Password Reset" 
            />

            <HomePageMsg 
                msgText={ msgText }
                iconName={ iconName }
            />

            {/* conditionally show form based on submittal status */}
            {!isSubmitted &&
                <div>
                    <Grid centered>
                        <Grid.Column mobile={16} tablet={10} computer={6} largeScreen={4} widescreen={4}>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <HomePageInput
                                    labelText="Email"
                                    placeholder="Enter your email..."
                                    type="email"
                                    name="email"
                                    reference={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                    inputError={errors.email}
                                />

                                <div className={styles["submit--position"]}>
                                    <Button 
                                        type='submit' 
                                        floated='right'
                                        loading={isLoading} 
                                        primary>Submit</Button>
                                </div>
                            </form>
                            
                        </Grid.Column>
                    </Grid>
                    {/* Error Messages */}
                    <HomePageError
                        errorText={formErrorText}
                    />
                    {/* Error Messages */}
                </div>
            }
            {/* End conditionally show form */}
            

        </>
    );

}

export default ResetPw;