/****************************************
 *  Reducer for Plugins Service/Store
 ****************************************/
import {ApiError_h} from '../../utils/apiHelpers';
import * as types from '../types';

const initialState = {
    isLoading: true,
    error: "",
    allPlugins: [],
    plugin: {}
};

export default (state = initialState, action) => {
    let error;
    switch (action.type) {
        /* GET PLUGINS REDUCERS */
        case types.GET_PLUGINS:
            return {
                ...state,
                isLoading: true
            };
        case types.GET_PLUGINS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                allPlugins: [...action.payload]
            };
        case types.GET_PLUGINS_FAILURE:
            error = new ApiError_h(action.payload).getMessage();
            return {
                ...state,
                error
            };
        /* GET PLUGINS REDUCERS */


        /* GET SINGLE PLUGINS REDUCERS */
        case types.GET_PLUGIN:
            return {
                ...state,
                isLoading: true
            };
        case types.GET_PLUGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                plugin: action.payload
            };
        case types.GET_PLUGIN_FAILURE:
            error = new ApiError_h(action.payload).getMessage();
            return {
                ...state,
                error
            };
        /* GET SINGLE PLUGINS REDUCERS */



        default:
            return state;
    }
};