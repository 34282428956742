/**
* Connections API calls
*/

import client from '../index';



/**
 * Adds a new configuration to the database.
 * @param {ConnectionObject} configuration The configuration object.
 * @return {Promise<CreateConnectionResponseObject>} Responds with the title, _id, and description of the configuration
 */
export function createConfiguration(configuration) {
    return client.service('configurations').create(configuration);
}

/**
 * Get the configuration data by Id
 *
 * @param {string} configurationId the id of the configuration in question
 * @return {*}
 */
export function getConfiguration(configurationId) {

    return client.service('configurations').get(configurationId);

}


/**
 * 
 * @param {string} id Id of the connection to be patched
 * @param {ConnectionObject} configuration Configuration object to be updated (only fields of interest)
 * @return {Promise<ConnectionObject>} Responds with the updated connection object
 */
export function patchConfiguration(id, configuration) {
    return client.service('configurations').patch(id, configuration);
}