import React, { useState, useEffect } from 'react';

//semantic
import {} from 'semantic-ui-react';

import {
    useParams
} from "react-router-dom";

import { authManagementVerifySignup } from "../../feathers/services/authmanagement";


//custom components
import HomePageMsg from "../HomePageMsg/HomePageMsg";
import HomePageTitle from "../HomePageTitle/HomePageTitle";
import HomePageError from "../HomePageError/HomePageError";




/**
 *  View that showcases when an account has been confirmed
 */
function AccountConfirmation() {

    /**
     * STATE
     */
    const [errorText, setErrorText]             = useState('');
    const [buttonText, setButtonText]           = useState('');
    const [homePageText, setHomePageText]       = useState('Verifying Email...');
    const [HomePageMsgText, setHomePageMsgText] = useState('Verifying your email, please wait...');



    /**
     * Get the verification token
     */
    let { token } = useParams();


    /** 
    * EFFECTS 
    */
    /**
     * If no token, then show error message
     */
    useEffect(()=>{
        if (!token) {
            setErrorText('Error!  Please click the link in your email to get to this page.');
        }
    }, [token]);


    useEffect(()=>{
        authManagementVerifySignup( token ).then((res)=>{
            setHomePageText('Email Verified!')
            setHomePageMsgText('Your email has been confirmed!  Please click the button below to log in.');
            setButtonText('Log In Now!');
        }).catch((err)=>{
            setErrorText(`Error: ${err.message}`);
        });
    },[token]);
    


   
    return (
        <>
            <HomePageTitle
                title={homePageText}
            />

            <HomePageMsg
                iconName="user"
                msgText={ HomePageMsgText }
                buttonText={buttonText}
                link="/login"
            />
            <br />
            <br />
            <HomePageError 
                errorText={ errorText }
            />
        </>
    );

}

export default AccountConfirmation;