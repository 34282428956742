/**
* Connections API calls
*/

import client from '../index';



/**
 * @typedef {Object} ConnectionObject
 * @property {string} title The title of the connection
 * @property {string} realm The realm of the connection
 * @property {string} appId The application id of the connection
 * @property {Object} auth Object containing the usertoken, applicationId and realm of the connection
 */
/**
* @typedef {Object} CreateConnectionResponseObject
* @property {string} title Title of the connection
* @property {string} _id Id of the new connection
*/
/**
 * Adds a new connection to the database.
 * @param {ConnectionObject} connection The connection object.
 * @return {Promise<CreateConnectionResponseObject>} Responds with the title, _id, and description of the connection
 */
export function createConnection(connection) {

    return client.service('connections').create(connection);

}




/**
 * 
 * @param {string} id Id of the connection to be patched
 * @param {ConnectionObject} connection Connection object to be updated
 * @return {Promise<ConnectionObject>} Responds with the updated connection object
 */
export function patchConnection( id, connection ) {
    return client.service('connections').patch(id, connection);
}



/**
 * Returns all connections
*/
export function getConnections() {
    return client.service('connections').find();
}


/**
 * 
 * @param {string} id Id of the connection to be removed
 * @return {Promise<ConnectionObject>} Responds with the removed connection object
 */
export function removeConnection(id) {
    return client.service('connections').remove(id);
}