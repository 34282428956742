/**
 * Defines all user store actions
 */
import * as types from '../types';


/************************************************
 * GET ACTIONS
 ************************************************/
/**
 * Get the connections from the API
 * TODO: Pagination logic
 * @return {{type: object}}
 */
export const getConnections = () => {
    return {
        type: types.GET_CONNECTIONS
    }
};

export const getConnectionFailure = payload => {
    return {
        type: types.GET_CONNECTION_FAILURE,
        payload
    };
};


export const getConnectionSuccess = payload => {
    return {
        type: types.GET_CONNECTION_SUCCESS,
        payload
    };
};


/************************************************
 * CREATE ACTIONS
 ************************************************/
//Create a connection
export const createConnection = (payload) => {
    return {
        type: types.CREATE_CONNECTION,
        payload
    };
};


export const createConnectionFailure = (payload) => {
    return {
        type: types.CREATE_CONNECTION_FAILURE,
        payload
    };
};


export const createConnectionSuccess = (payload) => {
    return {
        type: types.CREATE_CONNECTION_SUCCESS,
        payload
    };
};

export const clearConnectionError = (payload) => {
    return {
        type: types.CLEAR_CONNECTION_ERROR,
        payload
    };
};


/************************************************
 * EDIT ACTIONS
 ************************************************/

//Create a connection
export const patchConnection = (payload) => {
    return {
        type: types.PATCH_CONNECTION,
        payload
    };
};


export const patchConnectionFailure = (payload) => {
    return {
        type: types.PATCH_CONNECTION_FAILURE,
        payload
    };
};


export const patchConnectionSuccess = (payload) => {
    return {
        type: types.PATCH_CONNECTION_SUCCESS,
        payload
    };
};




/************************************************
 * DELETE ACTIONS
 ************************************************/
export const removeConnection = (payload) => {
    return {
        type: types.REMOVE_CONNECTION,
        payload
    };
};


export const removeConnectionFailure = (payload) => {
    return {
        type: types.REMOVE_CONNECTION_FAILURE,
        payload
    };
};


export const removeConnectionSuccess = (payload) => {
    return {
        type: types.REMOVE_CONNECTION_SUCCESS,
        payload
    };
};



