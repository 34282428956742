/**
 * Houses all sagas for config service
 */
import {takeLatest, call, fork, put} from 'redux-saga/effects';
import * as actions from '../actions/configurationActions';
import * as types from '../types';
import * as api from '../../feathers/services/configrations';


/**
 * Configuration service saga's
 */

//create Configuration api call
function* createConfigurationSaga(action) {

    try {

        const result = yield call(api.createConfiguration, action.payload);

        //dispatch action to set success state for api call
        yield put(actions.createConfigurationSuccess(result));

    } catch (error) {

        console.error("Create Configuration Saga Error", error);
        yield put(actions.createConfigurationFailure(error));

    }

}

//create Configuration api call
function* getConfiguration(action) {
    try {
        const result = yield call(api.getConfiguration, action.payload);

        //dispatch action to set success state for api call
        yield put(actions.getConfigurationSuccess(result));

    } catch (error) {
        console.error("Get Configuration Saga Error", error);
        yield put(actions.getConfigurationFailure(error));
    }

}


// Delete config


// Create config watcher function
function* watchCreateConfiguration() {
    yield takeLatest(types.CREATE_CONFIGURATION, createConfigurationSaga);
}

// get config watcher function
function* watchGetConfiguration() {
    yield takeLatest(types.GET_CONFIGURATION, getConfiguration);
}


//Export all saga's here
const configurationSagas = [
    fork(watchCreateConfiguration),
    fork(watchGetConfiguration),
];

export default configurationSagas;

