import {Card, Image, Label} from "semantic-ui-react";
import React from "react";
import { Link } from "react-router-dom";



// Styles
import styles from "./index.module.css";


// Components
import PluginRating from "../Rating/PluginRating";


/**
 * List of user installed plugins currently active.
 *
 * @param {Object} props Component props
 * @param {string} props.plugin the plugin in question.
 * @param {string} props.onCardClick Handler for card click
 * @returns {*}
 * @constructor
 */
function PluginBriefCard({ plugin, pluginType = "Quickbase Plugin", onCardClick}) {

    return (
        <>
            <Card 
                fluid 
                className={styles['main-card']}
                as={Link}
                to={{
                    pathname: `${plugin.seo.productName}`,
                    //used to set react router "modal" state to conditionally show plugin modal
                    state: { 
                        pluginModalIsOpen: true,
                        plugin
                    }
                }}
            >
                <Card.Content>
                    {!plugin.configTemplate.configSections &&
                        <Label color='red' ribbon>
                            Coming Soon!
                        </Label>
                    }

                    {plugin.proInstall &&
                        <div>
                            <Label color='blue' ribbon>
                                Pro Install
                            </Label>
                        </div>
                    }
                    
                    <Image
                        size="huge"
                        src={plugin.thumbnail}
                    />
                    <div className="mb-small"></div>
                    <Card.Header className={styles['card--header']}>{plugin.name} </Card.Header>
                    {/* <PluginRating rating={plugin.rating} /> &nbsp; &nbsp;
                    {plugin.numRatings} */}
                    <Card.Meta>{pluginType}</Card.Meta>
                    <Card.Description>
                        {plugin.shortSummary}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra textAlign="center">
                    <PluginRating rating={plugin.rating} /> &nbsp; &nbsp;
                    {plugin.numRatings}
                </Card.Content>
            </Card>
        </>
    
    // <GridColumn mobile={16} tablet={8} computer={8} largeScreen={5} widescreen={4} className={"hybrid"}>

    //     <Card fluid onClick={() => onCardClick( plugin )} className={styles['card']}>

    //         <div className={[styles['card--image-holder'], styles['card--image-holder-small']].join(" ")}>
    //             <Image src={plugin.thumbnail} wrapped ui={false} className={styles['card--image-img']} />
    //         </div>

    //         <Card.Content>
    //             <Card.Header className={styles['card--header']}>

    //                 <span>{ plugin.name }</span>

    //                 {/*Show the Star Rating */}
    //                 <PluginRating rating={plugin.rating}/>

    //             </Card.Header>
    //             <Card.Description className={styles['card--description']}>
    //                 { pluginType }
    //             </Card.Description>

    //             <p className={styles['card--paragraph']}>
    //                 { plugin.shortSummary }
    //             </p>
    //         </Card.Content>

    //     </Card>

    // </GridColumn>
    );

}

export default PluginBriefCard;