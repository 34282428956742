
import client from '../index';


/**
 * Creates a new button installation into the clients Quick Base application
 * @param {Object} subscriptionId Subscription ID from the database
 * @return {Promise} 
 */
export function createQbBtnInstall(subscriptionId) {
    return client.service('qb-btn-install').create(subscriptionId);
}