import React, { useState } from 'react';

//semantic
import { Grid, Button } from 'semantic-ui-react';

//styles
import styles from './index.module.css';

//react hook form
import { useForm } from 'react-hook-form';

//custom components
import HomePageMsg from '../HomePageMsg/HomePageMsg';
import HomePageTitle from '../HomePageTitle/HomePageTitle';
import HomePageInput from '../HomePageInput/HomePageInput';
import HomePageError from '../HomePageError/HomePageError';

import { authManagementResendVerifySignup } from '../../feathers/services/authmanagement';
import { ApiError_h } from '../../utils/apiHelpers';



/**
 * View to put in a password reset request
 */
function ResendConfirmation() {

    /*************************************************
     *  React Hook Form
    *************************************************/
    const { register, handleSubmit, errors } = useForm();

    //handles form submittal and UI state changes
    const [isSubmitted, setIsSubmitted]                     = useState(false);
    const [isLoading, setIsLoading]         = useState(false);
    const [formErrorText, setFormErrorText] = useState(null);


    //conditionally handle text and elements to be shown based on form submital
    var msgText = "Please enter your email below and click the Submit button to get another verification email sent.";
    var iconName = null;
    if (isSubmitted) {
        msgText = "Another verification email has been sent.  Please check your email and spam folders."
        iconName = "checkmark";
    };

    const onSubmit = data => {
        const { email } = data;
        setIsLoading(true);

        authManagementResendVerifySignup( email ).then((res)=>{
            setIsSubmitted(true);
            setIsLoading(false);
        }).catch((err)=>{
            const errorMsg = new ApiError_h(err);
            setFormErrorText(errorMsg.getUserMessage());
            setIsLoading(false);
        });
    };



    return (
        <>

            <HomePageTitle
                title="Resend Confirmation"
            />

            <HomePageMsg
                msgText={msgText}
                iconName={iconName}
            />

            {/* conditionally show form based on submittal status */}
            {!isSubmitted &&
                <div>
                    <Grid centered>
                        <Grid.Column mobile={16} tablet={10} computer={6} largeScreen={4} widescreen={4}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <HomePageInput
                                    labelText="Email"
                                    placeholder="Enter your email..."
                                    type="email"
                                    name="email"
                                    reference={register({ required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                    inputError={errors.email}
                                />

                                <div className={styles["submit--position"]}>
                                    <Button 
                                        type="submit" 
                                        floated='right' 
                                        primary
                                        loading={isLoading}>Submit</Button>
                                </div>
                            </form>

                            {/* Error Messages */}
                            <HomePageError
                                errorText={formErrorText}
                            />
                            {/* Error Messages */}
                        </Grid.Column>
                    </Grid>
                </div>
            }
            {/* End conditionally show form */}


        </>
    );

}

export default ResendConfirmation;