import React from 'react';

//semantic
import {
    Segment
} from 'semantic-ui-react';

//styles
import styles from './index.module.css';



/**
 * A SectionGroup has a title and description.  The title is represented as an H3 and a description as plain text.
 * @param {Object} props Component props
 * @param {string} props.title Title text to show to the user
 * @param {string} props.description Description to show to the end user
 * @param {string} props.showColor Allows you to show the color of the segment - red orange yellow olive green teal blue violet purple pink brown grey black
 *
 */
function SectionGroup({ title, description, color='', children }) {

    return (
        <>

            {color && 
                <Segment padded color={color} secondary>
                    <h3
                        className={styles["header"]}>
                        {title}
                    </h3>
                    <div className={styles["description"]}>
                        {description}
                    </div>
                    {children}
                </Segment>
            }

            {!color && 
                <Segment padded>
                    <h3
                        className={styles["header"]}>
                        {title}
                    </h3>
                    <div className={styles["description"]}>
                        {description}
                    </div>
                    {children}
                </Segment>
            }
            
                                     
        </>
    );

}

export default SectionGroup;