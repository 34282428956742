import React from 'react';

import {
    Route, Redirect
} from "react-router-dom";

//semantic
import {
   Dimmer,
   Loader
} from 'semantic-ui-react';

//redux 
import { useSelector } from "react-redux";



/**
 * This is utilized for two things - to set a layout for the different sections of the site and also to redirect to login if a user is visiting a route that needs auth and aren't logged in yet.
 * @param {Object} props
 * @param {Component} props.content Content to be rendered
 * @param {Component} props.layout Layout to utlize
 * @param {Boolean} props.requiresAuth Does this route require authentication?
 * @returns {Component} Returns route
 */
const RouteLayoutWrapper = ({ content: Content, layout: Layout, requiresAuth, ...rest }) => {

    const { isLoggedIn, isLoading } = useSelector(state => state.auth);

        return (
            // TODO separate out isLoading - right now the page loads and it's blank white because no components are rendering as islogged in and isloading is still processing
            <Route {...rest} render={props => (
                (requiresAuth && !isLoggedIn && !isLoading)
                    ?<Redirect to='/login' />
                    :(
                        <Layout>
                            {isLoading && 
                                <Dimmer active={isLoading} page>
                                    <Loader size='large'>Loading...</Loader>
                                </Dimmer>
                            }
                            {!isLoading &&
                                <Content {...props} />
                            }
                        </Layout>
                    )
            )} />
        ); 

    
};


export default React.memo(RouteLayoutWrapper, (prevProps, nextProps) =>
    prevProps.content === nextProps.content &&
    prevProps.layout === nextProps.layout &&
    prevProps.requiresAuth === nextProps.requiresAuth);