/**
* Connections API calls
*/

import client from '../index';

const serviceName = 'connection-configs';

/**
 * Get the connection config from the Database based on the connection config Id
 *
 * @param {string} id The connection config id
 * @return {*}
 */
export function getConnectionConfig(id) {
    return client.service(serviceName).get(id);
}


/**
 * Returns all connections
*/
export function findConnectionConfigs(query) {
    return client.service(serviceName).find(query);
}