/**
* Defines all config object store actions
*/
import * as types from '../types';


/************************************************
 * GET ACTIONS
 ************************************************/

/**
 * Create QB Fields action
 * The two parameter in the object is requred.
 * First is for the selected Connection Id
 * Second is the selected Table id for which the fields will be quried.
 *
 * @param {object} payload
 * @param {object} payload.connectionId
 * @param {object} payload.tableId
 * @return {{payload: *, type: string}}
 */
export const getQbFields = (payload) => {
    return {
        type: types.GET_QB_FIELDS,
        payload
    };
};


export const getQbFieldsFailure = payload => {
    return {
        type: types.GET_QB_FIELDS_FAILURE,
        payload
    };
};



export const getQbFieldsSuccess = payload => {
    return {
        type: types.GET_QB_FIELDS_SUCCESS,
        payload
    };
};



/************************************************
 * CREATE ACTIONS
 ************************************************/


/**
 *
 * @param {object} payload
 * @param {string} payload.connectionId
 * @param {string} payload.tableId
 * @param {string} payload.fieldType
 * @param {string} payload.fieldName
 * @return {{payload: *, type: string}}
 */
export const createQbField = (payload) => {
    return {
        type: types.CREATE_QB_FIELD,
        payload
    };
};


export const createQbFieldFailure = payload => {
    return {
        type: types.CREATE_QB_FIELD_FAILURE,
        payload
    };
};



export const createQbFieldSuccess = payload => {
    return {
        type: types.CREATE_QB_FIELD_SUCCESS,
        payload
    };
};


