import React from 'react';
import { Carousel } from 'react-responsive-carousel';

//semantic
import {} from 'semantic-ui-react';

//styles (styles are ported from react-responsive-carousel)
import "./index.css";


/**
 * Displays an image carousel - react responsive carousel
 * @param {Object} props Component props
 * @param {array} props.images Array of images for the carousel
 */
function ImageCarousel({ images }) {


    return (
        <Carousel autoPlay showThumbs={false} showArrows infiniteLoop>
            {images && images.length > 0 &&
                images.map((imageUrl, index) => (
                    <div key={index}>
                        <img 
                            src={ imageUrl } 
                            alt="Quickbase Plugin Images" 
                        />
                    </div>
                ))
            }
        </Carousel>
    );

}

export default ImageCarousel; 