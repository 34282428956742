/**
 * Initialize Feathers API REST Client
 */
import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';
import authManagementService from 'feathers-authentication-management';
import axios from 'axios';
import CONFIG from '../config/index';

const app = feathers();
const restClient = rest(CONFIG.API_URL);

//set up local auth
app.configure(auth());

//set up feature-authentication-management
app.configure(authManagementService({path:'authmanagement'}));

//utilize axios for the rest client
app.configure(restClient.axios(axios));


export default app;

