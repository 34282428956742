import React from "react";
import {Link} from "react-router-dom";


// Styles
import styles from '../index.module.css';

//Components

import {Icon, Menu} from "semantic-ui-react";


/**
 * The sidebar menu for account pages
 * @returns {*}
 * @constructor
 */
function AccountMenu() {

    return <Menu vertical fluid className={styles['menu']}>

       
        <Menu.Item as={Link} to={"/account/my-apps"}>
            My Plugins
            <Icon name='plug' />
        </Menu.Item>

        <Menu.Item as={Link} to={"/account/my-connections"}>
            My Connections
            <Icon name='lock' />
        </Menu.Item>

        <Menu.Item as={Link} to={"/account/subscriptions"}>
            Subscriptions and Billing
            <Icon name='dollar sign' />
        </Menu.Item>

        <Menu.Item as={Link} to={"/account/my-profile"}>
            My Profile
            <Icon name='user' />
        </Menu.Item>

    </Menu>

}

export default AccountMenu;