/**
 *  File contains a list of types for actions/reducers split by redux store
 */


/************************************************
 * GENERIC TYPES
 ************************************************/

export const SET_MODAL = 'SET_MODAL';



/************************************************
 * AUTH TYPES
 ************************************************/
//authenticate user
export const GET_AUTH_DATA = 'GET_AUTH_DATA';
export const GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS';
export const GET_AUTH_FAILURE = 'GET_AUTH_FAILURE';

//re-authenticate user
export const REAUTHENTICATE_USER_SUCCESS = "REAUTHENTICATE_USER_SUCCESS";
export const REAUTHENTICATE_USER_FAILURE = "REAUTHENTICATE_USER_FAILURE";
export const GET_REAUTHENTICATE_DATA = "GET_REAUTHENTICATE_DATA";

//log user out
export const LOG_USER_OUT = "LOG_USER_OUT";


/************************************************
 * USER TYPES
 ************************************************/
export const SET_USER = 'SET_USER';
export const UPDATE_USER_PAYMENT_PROFILE = "UPDATE_USER_PAYMENT_PROFILE";
export const UPDATE_USER_FREE_TRIALS = "UPDATE_USER_FREE_TRIALS";

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';


/************************************************
 * CONNECTION TYPES
 ************************************************/
// Retrieve
export const GET_CONNECTIONS = "GET_CONNECTIONS";
export const GET_CONNECTION_FAILURE = "GET_CONNECTION_FAILURE";
export const GET_CONNECTION_SUCCESS = "GET_CONNECTION_SUCCESS";

// Create
export const CREATE_CONNECTION = "CREATE_CONNECTION";
export const CREATE_CONNECTION_SUCCESS = "CREATE_CONNECTION_SUCCESS";
export const CREATE_CONNECTION_FAILURE = "CREATE_CONNECTION_FAILURE";
export const CLEAR_CONNECTION_ERROR = "CLEAR_CONNECTION_ERROR";

//update
export const PATCH_CONNECTION = "UPDATE_CONNECTION";
export const PATCH_CONNECTION_SUCCESS = "UPDATE_CONNECTION_SUCCESS";
export const PATCH_CONNECTION_FAILURE = "UPDATE_CONNECTION_FAILURE";

//remove
export const REMOVE_CONNECTION = " REMOVE_CONNECTION";
export const REMOVE_CONNECTION_SUCCESS = " REMOVE_CONNECTION_SUCCESS";
export const REMOVE_CONNECTION_FAILURE = " REMOVE_CONNECTION_FAILURE";


/************************************************
 * CONFIGURATION TYPES
 ************************************************/
// Create
export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const CREATE_CONFIGURATION_SUCCESS = "CREATE_CONFIGURATION_SUCCESS";
export const CREATE_CONFIGURATION_FAILURE = "CREATE_CONFIGURATION_FAILURE";
export const CLEAR_CONFIGURATION_ERROR = "CLEAR_CONFIGURATION_ERROR";

// Get
export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAILURE = "GET_CONFIGURATION_FAILURE";
export const GET_CONFIGURATION_ERROR = "GET_CONFIGURATION_ERROR";

// Delete


/************************************************
 * QB_TABLES TYPES
 ************************************************/

// Get
export const GET_QB_TABLES = "GET_QB_TABLES";
export const GET_QB_TABLES_SUCCESS = "GET_QB_TABLES_SUCCESS";
export const GET_QB_TABLES_FAILURE = "GET_QB_TABLES_FAILURE";
export const GET_QB_TABLES_ERROR = "GET_QB_TABLES_ERROR";

// Create
export const CREATE_QB_TABLE = "CREATE_QB_TABLE";
export const CREATE_QB_TABLE_SUCCESS = "CREATE_QB_TABLE_SUCCESS";
export const CREATE_QB_TABLE_FAILURE = "CREATE_QB_TABLE_FAILURE";
export const CREATE_QB_TABLE_ERROR = "CREATE_QB_TABLE_ERROR";

/************************************************
 * QB_FIELDS TYPES
 ************************************************/

// Get
export const GET_QB_FIELDS = "GET_QB_FIELDS";
export const GET_QB_FIELDS_SUCCESS = "GET_QB_FIELDS_SUCCESS";
export const GET_QB_FIELDS_FAILURE = "GET_QB_FIELDS_FAILURE";
export const GET_QB_FIELDS_ERROR = "GET_QB_FIELDS_ERROR";

// Create
export const CREATE_QB_FIELD = "CREATE_QB_FIELD";
export const CREATE_QB_FIELD_FAILURE = "CREATE_QB_FIELD_FAILURE";
export const CREATE_QB_FIELD_SUCCESS = "CREATE_QB_FIELD_SUCCESS";
export const CREATE_QB_FIELD_ERROR = "CREATE_QB_FIELD_ERROR";

/************************************************
 * MODAL TYPES
 ************************************************/
export const SET_CREATE_CONNECTION_MODAL = "SET_CREATE_CONNECTION_MODAL";



/************************************************
 * Plugin Types
 ************************************************/
export const GET_PLUGINS         = "GET_PLUGINS";
export const GET_PLUGINS_FAILURE = "GET_PLUGINS_FAILURE";
export const GET_PLUGINS_SUCCESS = "GET_PLUGINS_SUCCESS";

// GET single plugin
export const GET_PLUGIN             = "GET_PLUGIN";
export const GET_PLUGIN_FAILURE     = "GET_PLUGIN_FAILURE";
export const GET_PLUGIN_SUCCESS     = "GET_PLUGIN_SUCCESS";


