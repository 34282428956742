/******************************
 *  Reducer for QB TABLES
 ******************************/
import * as types from '../types';
import {ApiError_h} from '../../utils/apiHelpers';


const initialState = {
    isLoading: false,
    qbTables: [],
    error: '',

    // For create reducers
    isCreating: false,
    createError: ''
};

export default (state = initialState, action) => {
    switch (action.type) {

        /* GET REDUCERS */
        case types.GET_QB_TABLES:
            return {
                ...state,
                isLoading: true
            };
        case types.GET_QB_TABLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                qbTables: action.payload
            };
        case types.GET_QB_TABLES_FAILURE:
            const error = new ApiError_h(action.payload);
            const errorMsg = error.getUserMessage();
            return {
                ...state,
                isLoading: false,
                error: errorMsg
            };
        case types.GET_QB_TABLES_ERROR:
            return {
                ...state,
                error: ''
            };
        /* GET CASES */



        /* CREATE REDUCERS */
        case types.CREATE_QB_TABLE:
            return {
                ...state,
                isCreating: true
            };
        case types.CREATE_QB_TABLE_SUCCESS:
            return {
                ...state,
                isCreating: false,
                createError: '',
            };
        case types.CREATE_QB_TABLE_FAILURE:
            const createError = new ApiError_h(action.payload);
            const createErrorMsg = createError.getUserMessage();
            return {
                ...state,
                isCreating: false,
                createError: createErrorMsg
            };
        case types.CREATE_QB_TABLE_ERROR:
            return {
                ...state,
                createError: ''
            };
        /* CREATE CASES */



        default:
            return state;
    }
};