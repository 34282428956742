import React from 'react';

//semantic
import {Grid} from 'semantic-ui-react';


/**
 * Generates a grid column for an input element in the config section.
 * @param {Object} props React component props object
 * @param {number} props.screenWidth current width of the screen
 */
function ConfigInputColumn({screenWidth, children}) {


    return (
        <Grid.Column width={screenWidth > 980 ? 8 : 16}>
            { children }
        </Grid.Column>
    );

}

export default ConfigInputColumn;