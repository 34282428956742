import React, {useState, useEffect} from 'react';

//semantic
import {  Grid, Button, Icon, Popup, Message } from 'semantic-ui-react';

import SectionGroup from "../../../SectionGroup/SectionGroup";
import useWindowDimensions from "../../../../utils/useWindowDimentions";
import ConfigSectionInput from "../ConfigSectionInput/ConfigSectionInput";
import NestedInputContainer from "../NestedInputContainer/NestedInputContainer";
import ConfigInputColumn from "../ConfigInputColumn/ConfigInputColumn";
import { getQbFields, createQbFields } from "../../../../feathers/services/qbFields";


//styles
import styles from '../index.module.css';

import { ApiError_h } from "../../../../utils/apiHelpers";
import { sortDBFields_h, generateFieldListByFieldType_h } from "./configUtils";





/********************   JSDOC TYPEDEFS   ***********************/

/**
 * Connection
 * @typedef {Object} Connection
 * @property {string} _id ID of the this particular connection
 * @property {string} type type of connection Quick Base | Box etc.
 * @property {string} description user-derived connection description
 * @property {Object} auth authentication information for this connection
 */

/**
 * Config section object returned from plugin configuration object from database.
 * @typedef {Object} ConfigSection
 * @property {string} type options of "qbConfig" | "settings".  qbConfig is used to get QB configs and "settings" is general settings for the app.
 * @property {boolean} repeatable Allows the user to generate one or many of these sections.
 * @property {string} name The name of the table in Question for Quick Base if this is of type "qbConfig".
 * @property {title} title The title of this particular configuration section.
 * @property {string} sectionDescription Description that shows to the user of this section.
 * @property {string} label The label for the Quick Base table
 * @property {string} placeHolder Placeholder text to show if type = "qbConfig" for table dropdown field
 * @property {string} userHelpText Help text for the user next to the section title.  Shows in a hover popup.
 * @property {string} userCanCreate Gives the user the option to create a new table if "type" equals "qbConfig
 * @property {Array} fields Array of fields to render for the end user to select.
 */

/********************   JSDOC TYPEDEFS   ***********************/







/**
 * This component creates the different config sections.  This maps straight to the database config sections off of a plugin object.
 * @param {Object} props React component props object
 * @param {ConfigSection} props.configSectionObject This is the configuration section object from the plugin 
 * @param {function} props.handleAddNewSection Used when a section is repeatable.  Allows user to create a new section.
 * @param {number} props.index Current index of this particular configSection in "mapconfiguration" component.  Used to append/remove config sections in the right order
 * @param {Object} props.dbTables Database tables that are associated with this configuration (dropdown options for semantic dropdown input)
 * @param {String} props.connectionID The connection ID being used for this particular mapping
 * @param {function} props.handleDeleteSection This is run after a section is deleted.
 * @param {function} props.handleSetConnectionType Used to set the appropriate connection type for the connection popup
 * @param {string} connectionId The current connection ID of this configuration
 * @param {function} onAddNewTable Function to run when adding a new table
 * @param {function} onAddNewField Function to run after a new field is added.
 * @param {object} newFieldEmitter When a new field is added to a dropdown, this propogates the new field to all other config sections taht currently have the same db selected in the dropdown.
 * @param {boolean} connectionsLoading whether or not the connections are currently loading (brought in via redux)
 * @param {Array.<Connection>} connections array of connection objects
 *
 */
const ConfigSection = ({ 
    configSectionObject, 
    handleAddNewSection, 
    handleDeleteSection,
    handleSetConnectionType,
    index, 
    dbTables, 
    connectionId, 
    onAddNewTable, 
    onAddNewField, 
    newFieldEmitter, 
    connectionsLoading,
    connections
}) => {

    //when a user is editing a configuration, the URL scheme has "edit" in it.
    var userIsEditingExistingConfig = window.location.href.indexOf('edit') >= 0 ? true : false;

    /*************************************************
     *  Handle Config Section Settings & initialization
     *************************************************/

    // Get the window width to calculate the number of fileds to be shown in a row.
    const { screenWidth } = useWindowDimensions();

    //determines if this config section is a child (child of a repeatable parent) configsection.  IF it is hide the add button.
    const isChild = configSectionObject.name.indexOf('__') > 0 ? true : false;


    //rewrite configobject name for children elements - strip UUID for visual purposes and add index of the child to the title of the configsection.
    var title = null;
    if (configSectionObject.name.includes("__")) {
        title = configSectionObject.title + ` (Repeat Item)`;
    } else {
        title = configSectionObject.title.toUpperCase();
    }


    /*************************************************
     *  State
     *************************************************/
    var [error, setError]                       = useState('');
    var [dbTable, setDatabaseTable]             = useState(null);
    var [fieldsAreLoading, setFieldsAreLoading] = useState(false);
    var [tablesAreLoading, setTablesAreLoading] = useState(false);
    var [dbFields, setDbFields]                 = useState(null);



    /*************************************************
     *  General Functions
     *************************************************/
    //TODO - break this function out - highly coupled and not pure.  helps to initialize field settings with certain options.  Some of these options change depending on the section type and the field setting in question
    /**
     * 
     * @param {Object} configSectionObject configuration section object from plugin config.
     * @param {Object} fieldSetting field setting object from configuration object
     * @param {function} generateFieldListByFieldType_h Used to generate semantic field list dropdowns from available Quickbase fields in this particular table that has been selected.
     * @returns {Object} fieldsettings that have been initialized for this particular field.
     */
    const initializeFieldSettings = (configSectionObject, fieldSetting, generateFieldListByFieldType_h )=>{
        //intialize some additional settings for all fields
        fieldSetting = {
            ...fieldSetting,
            configSectionName: configSectionObject.name,
            configSectionTitle: configSectionObject.title
        };

        //if this section is a qbConfig - modify fieldtypes to dropdowns and add the field options based on the table selected
        if (configSectionObject.type === "qbConfig" && !fieldSetting.isSettingsField ) {
            fieldSetting = {
                ...fieldSetting,
                options: dbFields ? generateFieldListByFieldType_h(fieldSetting, dbFields) : dbFields,
                loading: fieldsAreLoading,
                //if there is no table selected yet, then make all fields disabled
                disabled: !dbTable ? true : false,
                //used to allow the field to be cleared on min QB table reset (uses references to the HTML element to clear the field on table reset)
                isDbField: true,
                fieldType: fieldSetting.allowMultiSelect ? 'multiselect' : 'dropdown'
            };
        }

        //if this is a connection field, populate necessary connections
        if(fieldSetting.isConnection) {

            let options = connections.reduce((result, connection) => {
                if (!fieldSetting.connectionType || fieldSetting.connectionType === connection.type) {
                    return result.concat({
                        key: connection._id,
                        text: connection.title,
                        value: connection._id
                    });
                } else {
                    return result;
                }
            }, []);


            fieldSetting = {
                ...fieldSetting,
                loading: connectionsLoading,
                options
            }
        }

        return fieldSetting;
    };
    //obtains Quick Base application table fields for a particular table
    const getQuickBaseFieldsForTable = () => {
        getQbFields(connectionId, dbTable).then((res) => {
            if (error) setError(false);
            const fields = res.data;
            //create field options based on response from database
            //const fieldOptions = createSemanticDropdownObjects_h(fields, 'id', 'label');

            const dbFields = sortDBFields_h(fields);
            setDbFields(dbFields);
            setFieldsAreLoading(false);
        }).catch((error) => {
            var err = new ApiError_h(error);
            setError(`There was an issue loading the fields for this table.  Please check your "connection" that was used to map the fields.  If the issue persists, please contact support with the following error message: ${err.getUserMessage()}`)
            setFieldsAreLoading(false);
        });
    };


    /*************************************************
     *  Effects
     *************************************************/

    useEffect(()=>{
        if( dbTable ) {
            getQuickBaseFieldsForTable();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbTable]);

    //sets the default value of the table when a user is editing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>{
        if (configSectionObject.defaultValue && userIsEditingExistingConfig ) {
            setDatabaseTable(configSectionObject.defaultValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //If a new field is added in another configsection, and this particular configSection is set to the same table, make sure to add in that new field to the dropdown here.   Each time a new field is added to a different configSection, the "newFieldEmitter" is updated which allows updates to other configSections
    useEffect(() => { 
        if (newFieldEmitter && newFieldEmitter.dbTable === dbTable && newFieldEmitter.configSectionName !== configSectionObject.name ) {
            const {newField, fieldType} = newFieldEmitter;
            setDbFields({ ...dbFields, [fieldType]: dbFields[fieldType].concat(newField) });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFieldEmitter])


    /*************************************************
     *  Handlers
     *************************************************/

     /**
      * Used when the refresh Quick Base fields button is clicked
      */
     const handleRefreshQBFields = ()=>{
         setFieldsAreLoading(true);
         getQuickBaseFieldsForTable();
     };

     /**
      * Creates a new database table
      * @param {String} dbTableName The name of the new database table that needs to be created.
      */
    const handleAddNewDbTable = ( dbTableName )=>{
        setTablesAreLoading(true);
        onAddNewTable({ connectionId, tableName: dbTableName}).then((res)=>{
            setTablesAreLoading(false);
        }).catch((error)=>{
            // setIsLoading(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            var errorAddNewTable = new ApiError_h(error);
            //TODO - error handling missing
            setTablesAreLoading(false);
        });
    };


    /**
     * Creates a new database field given the currently selected dbTable in the configsection.
     * @param {String} fieldName Name of the new field being created
     * @param {String} fieldType The UI field type related to "ConfigSectionInput" component field types
     * @param {String} dbFieldType The database type - ie - what type of field from Quick Base or another systems field types.
     */
    const handleAddNewDbField = ( fieldName, fieldType, dbFieldType ) => {
        setFieldsAreLoading(true);
        createQbFields({ connectionId, tableId: dbTable, fieldName, fieldType: dbFieldType}).then((res)=>{
            var id            = res.data.id;
            var newField      = {key: id, text: fieldName, value:id};
            var updatedFields = { ...dbFields, [dbFieldType]: dbFields[dbFieldType].concat(newField) };

            setDbFields(updatedFields);
            setFieldsAreLoading(false);
            onAddNewField({ newField, configSectionName: configSectionObject.name, dbTable, fieldType  });
            getQuickBaseFieldsForTable();
        }).catch((error)=>{
            // eslint-disable-next-line react-hooks/exhaustive-deps
            var errorAddNewFields = new ApiError_h(error);
            onAddNewField({ error: true, errorMsg: errorAddNewFields.getMessage() });
            setFieldsAreLoading(false);
        });
    };


    /**
     * When a particular input element changes - this function can run.  This essentially promps the system to get fields from a different table.
     * @param {*} value The value of the input element that changed (The dbid or database name that you need to grab fields from.)
     * 
     */
    const handleDbTableChange = ( value )=>{
       
        //determine if this 
        var tableExistsAlready = dbTables.find((dbTable)=>{
            return dbTable.value === value;
        });

        //if a valid table is found, and if it's not the table that was selected before (for instance - if you null out the tables field, then select the same table again.)
        if (tableExistsAlready && value !== dbTable ) {
            if (value) {
                setFieldsAreLoading(true);
            }
            setDatabaseTable(value);
        }
        
    };


    return (
        <>
            {/* Quick Base Section Group */}
            <SectionGroup
                title={ title }
                description={configSectionObject.sectionDescription}
                color={ isChild ? "red" : '' }
            >

                {/* delete button  */}
                { isChild &&
                    <div className={styles["delete-icon"]}>
                        <Popup inverted content={"Click to remove this section"} trigger={
                            <Icon
                                name="delete"
                                color="red"
                                size="big"
                                onClick={() => handleDeleteSection(configSectionObject.name)}
                            />
                        } />
                    </div>
                }
                {/* delete button  */}

                <div className={styles["margin-top"]} />


                {/* Show DB Table Seclection if configSection type is Quick Base */}
                {configSectionObject.type === "qbConfig" && 
                    <>
                        <Grid>
                            <ConfigInputColumn 
                                screenWidth={screenWidth}
                            >
                                <NestedInputContainer 
                                    fieldSettings={{
                                        configSectionTitle: configSectionObject.title,
                                        name: 'dbid', 
                                        configSectionName: configSectionObject.name, 
                                        placeholder: configSectionObject.placeHolder, 
                                        userCanCreate: true, 
                                        label: configSectionObject.label, 
                                        userHelpText: configSectionObject.userHelpText, 
                                        required: typeof configSectionObject.required === "undefined" ? true : false, 
                                        options: dbTables, 
                                        defaultValue: configSectionObject.defaultValue ? configSectionObject.defaultValue : null, 
                                        loading: 
                                        tablesAreLoading, 
                                        fieldType: 'dropdown' }}
                                    configSectionType={configSectionObject.type}
                                    onInputChange={handleDbTableChange}
                                    onAddNewTable={handleAddNewDbTable}
                                    InputElement={ConfigSectionInput}
                                />
                                
                            </ConfigInputColumn>
                        </Grid>
                        
                        {configSectionObject.fields.length > 0 &&
                            <h3 className={styles['section-title']}>Select Fields 
                                {!fieldsAreLoading && dbFields &&
                                    <Popup inverted content={"Click this icon to refresh the dropdowns in this Configuration Section below to incorporate any new fields that have been added to this Quickbase table.  Clicking this button will not remove your current field selections below if you have any."} trigger={
                                        <span 
                                            className={styles['refresh-fields']}
                                            onClick={() => handleRefreshQBFields()}>
                                            <Icon
                                                color="teal"
                                                
                                                name="refresh"
                                                
                                            /> Field Refresh
                                        </span>
                                    }
                                    />
                                }
                            </h3>
                        }
                    </>
                }
                {/* Show QB Table Seclection if Type is Quick Base */}


                {/* Render Fields  */}
                <Grid>
                    {configSectionObject && configSectionObject.fields.length > 0 &&
                        configSectionObject.fields.map( (fieldSetting, i) =>{

                            fieldSetting = initializeFieldSettings(configSectionObject, fieldSetting, generateFieldListByFieldType_h);
                    

                            return (
                                < ConfigInputColumn
                                    screenWidth = { screenWidth }
                                    key = { i }
                                >
                                    <NestedInputContainer 
                                        fieldSettings={fieldSetting}
                                        configSectionType={configSectionObject.type}
                                        dbTable={dbTable}
                                        onAddField={handleAddNewDbField}
                                        InputElement={ConfigSectionInput}
                                        onOpenConnectionModal={handleSetConnectionType}
                                    />
                                    
                                </ConfigInputColumn>
                            );

                        })
                    }
                </Grid>
                {/* Render Fields  */}

                

                <div className={styles["margin-bottom"]} />

                {/* if repeatable section */}
                {configSectionObject.repeatable && !isChild &&
                    <Grid centered>
                        <Grid.Column
                            mobile={16}
                            tablet={16}
                            computer={16}
                            largeScreen={12}
                            widescreen={12}
                        >
                            <Button
                                type="button"
                                primary
                                fluid
                                onClick={() => handleAddNewSection(configSectionObject, index)}
                            >
                                Add Another {title}
                        </Button>
                        </Grid.Column>
                    </Grid>
                }
                {/* if repeatable section  */}

                {/* Initial Page Load Error Message */}
                {error &&
                    <Message negative>
                        <Message.Header>Error Encountered</Message.Header>
                        <p>{error}</p>
                    </Message>
                }
                {/* Initial Page Load Error Message */}
                

            </SectionGroup>

            <div className={styles["margin-bottom"]} />



            {/* Quick Base Section Group */}
        </>
    );

};


export default React.memo(ConfigSection, (prevProps, nextProps) =>
    prevProps.connectionsLoading === nextProps.connectionsLoading &&
    prevProps.connections === nextProps.connections &&
    prevProps.configSectionObject === nextProps.configSectionObject && 
    prevProps.dbTables === nextProps.dbTables &&
    prevProps.newFieldEmitter === nextProps.newFieldEmitter &&
    prevProps.configSections === nextProps.configSections);



