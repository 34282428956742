import React from 'react';

//semantic
import {Icon} from 'semantic-ui-react';

//styles
import styles from './index.module.css';


/**
 * Renders a credit card icon based on the card type
 * @param {Object} props React component props object
 * @param {String} props.creditCardType One of - Visa | Mastercard | Diners Club | Discover | JCB | UnionPay | Maestro | Forbrugsforeningen | Dankort | Troy | Elo | Mir | UATP
 */
function CreditCardIcon() {

    return (
        <>
            <Icon className={styles["visa"]} name="cc visa" size="huge" />
            <Icon className={styles["amex"]} name="cc amex" size="huge" />
            <Icon className={styles["discover"]} name="cc discover" size="huge" />
            <Icon className={styles["mastercard"]} name="cc mastercard" size="huge" />
            <Icon className={styles["jcb"]} name="cc jcb" size="huge" />
            {/* <br />
            <Popup
                trigger={<div className={styles['credit-card-list']}>Full List Supported Card Types</div>}
                content={<div className={styles["credit-card-types"]}>Visa<br/>Mastercard <br/>Diners Club <br/>Discover <br/>JCB <br/> UnionPay <br/> Maestro <br/> Forbrugsforeningen <br/> Dankort <br/> Troy <br/> Elo <br/> Mir <br/> UATP</div>}
                inverted
            /> */}
            
        </>
    );

}

export default CreditCardIcon;

