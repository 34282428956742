//TODO - add to container directory
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

//react hook form
import { useForm } from 'react-hook-form';

//redux 
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "../../store/actions/authActions";

import { emailRegex } from "../../utils/formRegexValidations"

//semantic
import {
    Grid,
    Button
} from 'semantic-ui-react';

//styles
import styles from './index.module.css';

// Sub components
import useQueryParameter from "../../utils/useQueryParameter";

//custom components
import HomePageTitle from '../HomePageTitle/HomePageTitle';
import HomePageInput from '../HomePageInput/HomePageInput';
import HomePageError from '../HomePageError/HomePageError';


/**
 * This is the main login form for the website.
 */
function Login() {

    /*************************************************
     *  Query Parameters
     *************************************************/
    const queryParams = useQueryParameter(useLocation().search);
    var {nexturl} = queryParams;
    if(nexturl) nexturl = decodeURIComponent(nexturl);


    /*************************************************
     *  STATE
     *************************************************/
    //handles form submittal and UI state changes
    const [formErrorText, setFormErrorText] = useState('');


    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch   = useDispatch();
    const { isLoggedIn, isLoading, error } = useSelector(state => state.auth);

    
    //set error msg if there is an auth error from API
    useEffect(() => {
            if (error) setFormErrorText( error );
    }, [error]);

    /*************************************************
     *  React Hook Form
     *************************************************/
    const { register, handleSubmit, errors } = useForm();


    // If the user is logged in - navigate them to their apps
    const history = useHistory();
    useEffect(() => {
            if (isLoggedIn) {
                let rurl = nexturl ? nexturl : "/account/my-apps";
                console.log(rurl);
                history.push(rurl);
            };
        },
        [isLoggedIn, history] //history must be passed to avoid warning.
    );
    

    /*************************************************
     *  FORM SUBMITTAL
     *************************************************/
    const onSubmit = data => {
        const { email, password } = data;
        //log the user into backend utilizing redux
        dispatch( getAuth({email, password}) );
    };

    
    /*************************************************
     *  DOM Rendering
     *************************************************/
    return (
        <>
        
            <HomePageTitle title="Lets do this!" />

            <Grid centered>
                <Grid.Column mobile={16} tablet={10} computer={6} largeScreen={4} widescreen={4}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <HomePageInput 
                            name="email"
                            labelText="Email" 
                            placeholder="Enter your email..." 
                            type="text" 
                            reference={register({ required: true, pattern: emailRegex })}
                            inputError={errors.email}
                        />

                        <HomePageInput 
                            name="password"
                            labelText="Password" 
                            placeholder="Enter your password..." 
                            type="password"
                            reference={register({ required: true })}
                            inputError={errors.password}
                        />

                        <div className={styles["user-actions"]}>
                            <div className={styles["user-actions__item"]}>
                                <div className={styles["user-actions--position"]}>
                                    <Link to="resetpw">
                                        <span className={styles["user-actions--hover"]}>Forgot Password?</span>
                                    </Link>
                                    <br />
                                    <Link to="signup">
                                        <span className={styles["user-actions--hover"]}>Sign Up!</span>
                                    </Link>
                                </div>
                            </div>
                            <div className={styles["user-actions__item"]}>
                                <Button 
                                    loading={isLoading}
                                    className={styles["margin-top"]} 
                                    type='submit' 
                                    floated='right' 
                                    primary>
                                        Submit
                                </Button>
                            </div>
                        </div>

                        {/* Error Messages */}
                        <HomePageError 
                            errorText={ formErrorText }
                        />
                        {/* Error Messages */}

                    </form>
                </Grid.Column>
            </Grid>
        </>
    );

}

export default Login;