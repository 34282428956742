import React, { useEffect } from 'react';

//semantic
// import {} from 'semantic-ui-react';


import { useDispatch, useSelector } from "react-redux";

import * as actions from '../../store/actions/connectionActions';



//styles
// import styles from './index.module.css';

/**
 * Gets all users connections and populates the redux store.
 * @param {Object} props React component props object
 * @param {} props.children Children pass to this React component 
 */
function PopulateConnectionStore({ children }) {


    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    //error here is from an API call error when calling connections service
    const { connections } = useSelector(state => state.connections);



    /*************************************************
     *  Effects
     *************************************************/

    useEffect(() => {
        //if no connections are present - get the plugins on page load
        if (connections.length === 0) {
            dispatch(actions.getConnections());
        }
    }, []);

    return (
        <>
            {children}
        </>
    );

}

export default PopulateConnectionStore;