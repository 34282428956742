import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    useParams
} from "react-router-dom";

import {
    Grid,
    Button
} from 'semantic-ui-react';

import { authManagementResetPwdLong } from "../../feathers/services/authmanagement";
import { ApiError_h } from "../../utils/apiHelpers";



//custom components
import HomePageMsg from "../HomePageMsg/HomePageMsg";
import HomePageTitle from "../HomePageTitle/HomePageTitle";
import HomePageError from "../HomePageError/HomePageError";
import HomePageInput from "../HomePageInput/HomePageInput";

import { passwordInputValidator } from "../../utils/passwordHelpers";
import PwdValidatorPopup from "../PwdValidatorPopup/PwdValidatorPopup";






/**
 *  This component allows you to fill in your new password and submit it to be updated.
 */
function PwdReset() {

    //react hook form
    const { register, handleSubmit, errors, watch } = useForm();

    /**
     * STATE
     */
    const [isLoading, setIsLoading]             = useState(false);
    const [errorText, setErrorText]             = useState('');
    const [buttonText, setButtonText]           = useState('');
    const [homePageText]                        = useState('Password Update');
    const [HomePageMsgText, setHomePageMsgText] = useState('Please fill in the form below to update your password.');
    const [isSubmitted, setIsSubmitted]         = useState(false);
    const [pw, setPw]                           = useState('');


    /**
     * Get the verification token
     */
    let { token } = useParams();


    /*************************************************
     *  EFFECTS
     *************************************************/
    /**
     * If no token, then show error message
     */
    useEffect(()=>{
        if (!token) {
            setErrorText('Error!  Please click the link in your email to get to this page.');
        }
    }, [token]);

    //set error msg if there is an auth error from API
    useEffect(() => {
        if ( errors.password || errors.retypePassword ) {
            setErrorText("Passwords must be filled in and they must match.  Passwords must be 8 characters or more, and contain letters and numbers.")
        } else {
            setErrorText("");
        };
    },
        [errors.password, errors.retypePassword]
    );


    /*************************************************
     *  HANDLERS
     *************************************************/
    const handlePwChange = e => {
        setPw(e.target.value);
    };


    /**
    * FORM SUBMISSION
    */
    const onSubmit = formData => {
        const { password } = formData;

        setIsLoading(true);

        authManagementResetPwdLong(token, password).then((res)=>{
            setIsLoading(false);
            setButtonText("Log In Now!");
            setHomePageMsgText("Your password has been updated successfully.  Click the button below to log in.");
            setIsSubmitted(true);
        }).catch((err)=>{
            setIsLoading(false);
            const error = new ApiError_h(err);
            setErrorText(error.getUserMessage());
        });
    };

    
    return (
        <>
            <HomePageTitle
                title={homePageText}
            />

            <HomePageMsg
                iconName="lock"
                msgText={ HomePageMsgText }
                buttonText={buttonText}
                link="/login"
            />
            <br />
            {!isSubmitted &&
                < form onSubmit={handleSubmit(onSubmit)}>
                    <Grid centered>
                        <Grid.Column mobile={16} tablet={10} computer={6} largeScreen={4} widescreen={4}>

                            <HomePageInput
                                popupHeader="Password Requirements"
                                popupContent={<PwdValidatorPopup password={pw} />}
                                name="password"
                                labelText="New Password"
                                placeholder="Enter your new password..."
                                type="password"
                                handleChange={handlePwChange}
                                reference={register({
                                    required: true,
                                    validate: {
                                        // match: value => value === watch('retypePassword'),
                                        securePw: passwordInputValidator
                                    }
                                })}
                                inputError={errors.password}
                            />

                            <HomePageInput
                                name="retypePassword"
                                labelText="Retype New Password"
                                placeholder="Enter your new password again..."
                                type="password"
                                reference={register({ required: true, validate: value => value === watch('password') })}
                                inputError={errors.retypePassword}
                            />

                            <br />
                            <br />
                            <Button
                                loading={isLoading}
                                type='submit'
                                floated='right'
                                primary>
                                Submit
                                </Button>
                            {/* error handler */}
                            <HomePageError
                                errorText={errorText}
                            />
                            {/* error handler */}

                        </Grid.Column>
                    </Grid>
                </form>
            }
            
        </>
    );

}

export default PwdReset;