import React from 'react';


//styles
import styles from './index.module.css';

//semantic
import {
    Popup
} from 'semantic-ui-react';



/**
 * Input element for all landing page forms
 * @param {Object} props Component props
 * @param {string} props.placeholder Placeholder to add to input.
 * @param {string} props.labelText Label text that will show.
 * @param {string} props.type Type of input
 * @param {boolean} props.inputError Show error class (red border)
 * @param {string} props.name Name of the input for form submital.
 * @param {Function} props.reference Register function used by react hook form library
 * @param {Component} props.popupContent Content to render in popup when a user focus's on an input
 * @param {String} props.popupHeader Header for popup if applicablej
 * @param {Function} props.handleChange onChange handler function
 */
function HomePageInput ({ name=null, placeholder=null, labelText=null, type="text", inputError=false, reference=null, popupContent, popupHeader, handleChange=null }) {


    //Add error class if input error is present.
    var inputClasses = [styles["landing-pg-input"]];
    if (inputError) inputClasses.push(styles["input-error"]);


    return (
        <>
            { labelText && 
                <label className={[styles["landing-page-label"], styles["margin-top"]].join(' ')}>{labelText}</label>
            }
            

            { popupContent &&
                <Popup
                    flowing
                    inverted
                    trigger={
                        <input
                            name={name}
                            className={inputClasses.join(' ')}
                            type={type}
                            placeholder={placeholder}
                            ref={reference}
                            onChange={handleChange}
                        />
                    }
                    position="top right"
                    header='Password Requirements'
                    content={popupContent}
                    on='focus'
                />
            }

            { !popupContent &&
                <input
                    name={name}
                    className={inputClasses.join(' ')}
                    type={type}
                    placeholder={placeholder}
                    ref={reference}
                />
            }            
            
        </>
    );

}

export default HomePageInput;