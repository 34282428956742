import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import configs from '../../config/index';
import {
   useHistory
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logUserOut_a } from "../../store/actions/authActions";
//feathers api
import { logUserOut } from "../../feathers/services/users";




/**
 * Used to force idle timeouts for the application.  
 * @param {Object} props React component props object
 * @param {Object} props.children Children components to render
 */
function IdleTimeout({children}) {

    /*************************************************
     *  Forward to Contact Us on Request Update
     *************************************************/
    const history = useHistory();


    /*************************************************
     *  REDUX
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.auth);



    /*************************************************
     *  HANDLERS
     *************************************************/

    const handleOnIdle = event => {
        // console.log('user is idle', event);
        // console.log('last active', getLastActiveTime());
        if( isLoggedIn ) {
            logUserOut().then((res) => {
                //dispatch action to log user out in store
                dispatch(logUserOut_a());
                history.push("/login");
            });
        }
    }

    // const handleOnActive = event => {
    //     console.log('user is active', event)
    //     console.log('time remaining', getRemainingTime())
    // }

    // const handleOnAction = event => {
    //     console.log('user did something', event)
    // }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: configs.IDLE_TIMEOUT,
        onIdle: handleOnIdle,
        // onActive: handleOnActive,
        // onAction: handleOnAction,
        debounce: 1000
    });

    return (
        <>
            {children}
        </>
    )

}

export default IdleTimeout;