/**
* Feathers Authentication Management API Calls.
*/


import client from '../index';



/**
 * Resends the email to the user to verify their email
 * @param {string} email email of the user to resend verif
 */
export function authManagementResendVerifySignup(email) {

    return client.service('authManagement').create({
        action: 'resendVerifySignup',
        value: {email}
    });

};


/**
 * Verifies the current user given a token
 * @param {string} token Verification token sent in the users email
 */
export function authManagementVerifySignup( token ) {
    return client.service('authManagement').create({
        action: 'verifySignupLong',
        value: token
    });
};


/**
 * Sends a password reset to the users email
 * @param {string} email email of the user who wants to reset their password
 */
export function authManagementSendResetPwd ( email ){
    return client.service('authManagement').create({
        action: 'sendResetPwd',
        value: { email }
    }); 
};



/**
 * Updates the users password
 * @param {string} token password change token
 * @param {string} password new password to update
 */
export function authManagementResetPwdLong( token, password ) {
    return client.service('authManagement').create({
        action: 'resetPwdLong',
        value: {
            token, // compares to .resetToken
            password // new password
        }
    });
};









