import {Rating} from "semantic-ui-react";
import React from "react";

import "./index.css";


/**
 * Show the rating stars
 * @param {Object} props Component props
 * @param {number} props.rating Rating from 0 to 5
 * @returns {*}
 * @constructor
 */

function PluginRating({rating}) {

    //round rating for stars
    rating = rating ? Math.round(rating) : rating;

    return (<Rating
                   maxRating={5}
                   rating={rating}
                   size='small'
                   disabled={true}
            />);

}

export default PluginRating;