import React, {useState} from 'react';

//semantic
import {Icon} from 'semantic-ui-react';

//router
import { Link, useHistory} from 'react-router-dom';

//styles
import styles from './index.module.css';

//redux 
import { useDispatch, useSelector } from "react-redux";
import { logUserOut_a } from "../../../store/actions/authActions";

//feathers api
import { logUserOut } from "../../../feathers/services/users";


/**
 * Displays the sidebarmenu when clicked
 */
function SidebarMenu() {
    const history = useHistory();

    //handle sidebard show/hide functionality
    const [shouldShowMenu, setShouldShowMenu] = useState(false);
    const toggleMenu = ()=>{
        setShouldShowMenu(!shouldShowMenu);
    };

    //set up redux dispatch
    const dispatch       = useDispatch();
    const { isLoggedIn } = useSelector(state => state.auth);


    /**
     * Handle logout click
     * @param {Object} e Event
     */
    const handleClick = e =>{
        logUserOut().then((res)=>{
            //dispatch action to log user out in store
            dispatch(logUserOut_a());
            setShouldShowMenu(false);
            history.push("/login");
        });
    };

    return (
        <>
            <div className={styles["menu-icon"]}>
                <div 
                    className={styles["menu-icon--position"]}
                    onClick={ toggleMenu }>
                    <Icon 
                        name="bars" 
                        size="big" 
                    />
                </div>
            </div>

            {/* conditional render of menu */}
            {shouldShowMenu &&
                <div>
                    <div
                        className={styles["dimmer"]}
                        onClick={toggleMenu}>
                    </div>
                    <div className={styles["cust-sidebar-menu"]}>
                        <div className={styles["cust-sidebar-menu__title"]}>
                            Menu
                        </div>
                        <nav>
                            <Link to="/marketplace/all-plugins" onClick={toggleMenu}>
                                <div className={styles["cust-sidebar-menu__item"]}>
                                    Plugins
                                </div>
                            </Link>

                            {/* User Not logged In Menu*/}
                            {!isLoggedIn &&
                                <>
                                    <Link to="/login" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            Login
                                        </div>
                                    </Link>
                                    <Link to="/signup" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            Sign Up
                                        </div>
                                    </Link>
                                    <Link to="/resendconfirmation" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            Resend Verification
                                        </div>
                                    </Link>
                                </>
                            }
                            {/* User Not logged In Menu*/}

                        
                            {isLoggedIn &&
                                <>
                                    <Link to="/account/my-apps" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            My Plugins
                                        </div>
                                    </Link>

                                    <Link to="/account/my-connections" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            My Connections
                                        </div>
                                    </Link>

                                    <Link to="/account/subscriptions" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            My Subscriptions
                                        </div>
                                    </Link>
                                    <Link to="/account/payment-method" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            My Payment Method
                                        </div>
                                    </Link>
                                    <Link to="/account/my-profile" onClick={toggleMenu}>
                                        <div className={styles["cust-sidebar-menu__item"]}>
                                            My Profile
                                        </div>
                                    </Link>
                                </>
                                
                            }
                            

                            <Link to="/contact" onClick={toggleMenu}>
                                <div className={styles["cust-sidebar-menu__item"]}>
                                    Contact Us
                                </div>
                            </Link>

                            {/* LOGOUT */}
                            { isLoggedIn &&
                                < div
                                    onClick={handleClick}
                                    className={styles["cust-sidebar-menu__item"]}>
                                    Logout
                                </div>
                            }
                            {/* LOGOUT */}
                        </nav>

                    </div>
                </div>
            }
            {/* conditional render of menu */}

        </>
        
    );

}




export default SidebarMenu;