import React, {useEffect, useState, createRef} from 'react';

import DatePicker from "react-datepicker";

import ConfigInputLabel from "../../../ConfigInputLabel/ConfigInputLabel";
import DropdownAdditionLabel from "./DropdownAdditionLabel/DropdownAdditionLabel";

import { isFormError_h } from "../../../../utils/formHelpers";
import { formatDateToUnixTimeStamp_h } from "../../../../utils/dateHelpers";
import { debounce_h } from "../../../../utils/formHelpers";
import { useDispatch } from "react-redux";
import * as modalActions from '../../../../store/actions/modals';




//semantic
import { Form, Dropdown, Ref } from 'semantic-ui-react';
// import configs from '../../../../config/default';

//styles
import styles from './index.module.css';

/**
 * This is an input field inside of ConfigSections.  This component returns the correct field based on the field type and field settings provided by the configuration.
 * @param {Object} props React component props object
 * @param {string} props.fieldSettings The type of field settings that are needed to render the approriate field
 * @param {string} props.configSectionType What type of config section is this?  Different config sections require different field types.  For instance qbConfig has all dropdown types.
 * @param {string} props.onInputChange Function to run when there is an input change
 * @param {string} props.dbTable the specific table in question that this field is associated with
 * @param {string} props.onAddTable Adds a dropdown item - only used for adding new fields/new table
 * @param {function} props.onOpenConnectionModal Function to run to set the appropriate connection type in the mapconfigruation.js file.
 */
function ConfigField({ fieldSettings, configSectionType, onInputChange = null, dbTable, onAddNewTable, onAddField, register, errors, setValue, clearError, unregister, onOpenConnectionModal }) {

    //uses react context to pass React Hook Form down deeply nested structures.
    // const { register, errors, setValue, clearError, unregister } = useFormContext();

    //get necessary items from field settings.
    var {
        connectionType,
        isConnection,
        configSectionName,
        configSectionTitle,
        name,
        placeholder,
        userCanCreate,
        label,
        userHelpText,
        fieldType,
        // permissions,
        required,
        defaultValue,
        loading,
        disabled, 
        dbFieldType,
        //used to tell whether this field is a mapping field (not a mapping table but a mapping field inside a mapping table)
        isDbField
    } = fieldSettings;

    

    const fieldName       = `${configSectionName}__${name}`;
    const debounceLength  = fieldType === 'dropdown' || fieldType === 'multiselect' || fieldType === "date" || fieldType === "datetime" ? 5 : 150;

    /*************************************************
     *  Redux
     *************************************************/
    //set up redux dispatch
    const dispatch = useDispatch();

    /*************************************************
    *  State
    *************************************************/
    const [inputValue, setInputValue] = useState(null);

    //Note - that counter is somewhat of a hack.  Memo access was restricted for react hook form, so we have to do a quick rerender to show the form validation (error classes).
    const [counter, setCounter] = useState(1);
    const [defaultCounter, setDefaultCounter] = useState(0);


    /*************************************************
    *  Effects
    *************************************************/
   //Note - that counter is somewhat of a hack.  Memo access was restricted for react hook form, so we have to do a quick rerender to show the form validation (red error classes).  Without this, the input must be changed twice.  Minor performance impact, but with the benefit of keeping all other fields from re-rendering and causing huge perf impacts.
    useEffect(()=>{
        setCounter(counter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);
   

    /*************************************************
    *  Create necessary ref for dropdowns
    *************************************************/
    //dbfields need to be cleared when the table changes, use a ref to make that happen so they aren't controlled components as controlled components cause perf issues on huge forms.
    var ref = {};
    ref[fieldName] = createRef();

    

    

    /*************************************************
    *  Handlers
    *************************************************/

    const handleAddConnectionsClick = (meta) => {
        onOpenConnectionModal(meta);
        dispatch(modalActions.setCreateModalState(true));
    }

    const handleChange = debounce_h((e, element)=>{
        switch (fieldType) {
            case 'checkbox':
            case 'toggle':
            case 'slider':
                setValue(fieldName, element.checked, true);
                break;
            case 'date' :
            case 'datetime' : 
                let utcString = formatDateToUnixTimeStamp_h(e);
                setValue(fieldName, utcString, true);
                setInputValue(e);
                break;
            case "password":
            case "text":
            case "textarea":
            case "percent":
            case "currency":
            case "number":
            case "color":
            case "dropdown":
                setValue(fieldName, element.value, true);
                setInputValue(element.value);
                //updates fields when dbtable changes
                if (onInputChange) onInputChange(element.value);
                break;
            case "multiselect":
                //multiselet returns an empty array if there are no values present (user deletes values)
                var multiSelectvalue = element.value;
                if(element.value.length < 1) {
                    multiSelectvalue = null;
                }
                setValue(fieldName, multiSelectvalue, true);
                setInputValue(multiSelectvalue);
                break;
            default:
                break;
        }

    }, debounceLength, false);


    /*************************************************
    *  Effects
    *************************************************/
    useEffect(() => {
        //if default value when this loads, update form state to show default value
        if (defaultValue) setValue(fieldName, defaultValue);
        //if this is a checkbox/boolean field type, initialize a false value.  This corrects for situations where defaultValue is true.
        if (!defaultValue && (fieldType==="checkbox" || fieldType==="toggle" || fieldType === "slider")) setValue(fieldName, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //When dbtable changes, do the following
    useEffect(()=>{
        if( dbTable && defaultCounter > 1 ) {
            //reset the react hook form value to null as this will be a different database with different fields
            setValue(fieldName, null);
            // setInputValue(null);

            //use the ref to clear the dropdown value so the uncontrolled dropdown clears
            if ( isDbField ) {
                let element = ref[fieldName].current.querySelector(".clear");
                if (element) {
                    element.click();
                }
            }
        }
        //This is to allow defaults to show when editing a configuration.  Default counter is set to 0 on page load, when you are editing a configuration we dont' want to clear the fields when the dbTable is changed the first time (we want default fields to show).  If the user again changes the dbTable, then we want the fields to clear.  This counter assures the first time the page loads, if there is a defaultValue for the dbTable for a qbConfig, it doesnt' reset the values - reference the "if" statement above that is using the defaultCounter.
        setDefaultCounter(defaultCounter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbTable]);

    //If user deletes this section - make sure to clear any validation errors and unregister this particular field
    useEffect(() => {
        return () => {
            clearError(fieldName);
            unregister(fieldName);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
   

    const customLabel = (
        <ConfigInputLabel 
            label={label} 
            required={required} 
            help={userHelpText} 
            buttonText={isConnection ? "Add Connection":""}
            onButtonClick={isConnection ? () => handleAddConnectionsClick({type:connectionType}) : null}
        />
    );

    //updates react hook forms required object depending on whether the field is required or not.  Accounts for child naming conventions with '__'.
    //var indexOfConfigUID          = configSectionName.indexOf('__');
    //var requiredNameConfigSection = indexOfConfigUID > 0 ? configSectionName.substring(0, indexOfConfigUID) : configSectionName;
    //object passed to react hook form if field is required
    const requiredObject          = (required) ? { required: `"${label}" is a required field in the "${configSectionTitle}" section.` } : {required: false};

    switch (fieldType) {
        case 'checkbox':
        case 'toggle':
        case 'slider':
            return (
                <Form.Checkbox
                    ref={register({ name: fieldName })}
                    label={customLabel}
                    toggle={fieldType === 'toggle'}
                    slider={fieldType === 'slider'}
                    defaultChecked={defaultValue === true ? true : false}
                    error={isFormError_h(errors[fieldName])}
                    onChange={handleChange}
                />
            );
        case 'text':
        case 'color':
            return (
                <Form.Input 
                    fluid
                    type={fieldType ==="text" ? "text": "color"}
                    label={customLabel}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    error={isFormError_h(errors[fieldName])}
                    ref={register({ name: fieldName }, requiredObject)}
                />
            );
        case 'password':
            return (
                <Form.Input
                    fluid
                    type="password"
                    label={customLabel}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    error={isFormError_h(errors[fieldName])}
                    ref={register({ name: fieldName }, requiredObject)}
                />
            );
        case 'dropdown':
        case 'multiselect':
            return (
                <>
                    {customLabel}
                    <Ref innerRef={ref[fieldName]}>
                        <Dropdown
                            noResultsMessage={userCanCreate ? "No results found.  Start typing to create a new item." : "No results found."}
                            disabled={disabled ? disabled : false}
                            loading={loading}
                            allowAdditions={userCanCreate}
                            additionLabel={<DropdownAdditionLabel type="field" />}
                            additionPosition="bottom"
                            onAddItem={(e, { value }) => fieldSettings.isDbField ? onAddField(value, fieldType, dbFieldType) : onAddNewTable(value)}
                            search
                            clearable
                            fluid
                            selection
                            options={fieldSettings.options}
                            defaultValue={defaultValue}
                            // value={inputValue ? inputValue : fieldType === 'multiselect' ? [] : null}
                            multiple={fieldType.includes("multi")}
                            onChange={handleChange}
                            error={isFormError_h(errors[fieldName])}
                            ref={register({ name: fieldName }, requiredObject)}
                        />
                    </Ref>
                </>
            );
        case 'date':
            //custom validation styling for react datepicker
            let customClassName = required && !inputValue && isFormError_h(errors[fieldName]) ? styles["error"] : "";
            let initialSelected = inputValue ? inputValue : defaultValue;

            if(initialSelected) {
                initialSelected = new Date(initialSelected);
            }

            return (
                <>
                    {customLabel}
                    <DatePicker
                        selected={initialSelected}
                        dateFormat="MM/dd/yyyy"
                        showTimeSelect={false}
                        todayButton="Today"
                        dropdownMode="select"
                        placeholderText="MM/DD/YYYY"
                        onChange={handleChange}
                        ref={register({ name: fieldName }, requiredObject)}
                        // name={`${sectionName}[${fieldName}]`}
                        className={ customClassName }
                    />
                </>
            );
        case 'datetime':
            //initialize date object
            let initialSelectedDateTime = inputValue ? inputValue : defaultValue;

            if (initialSelectedDateTime) {
                initialSelectedDateTime = new Date(initialSelectedDateTime);
            }

            //custom validation styling for react datepicker
            let customClass = required && !inputValue && isFormError_h(errors[fieldName]) ? styles["error"] : "";
            return (
                <>
                    {customLabel}
                    <DatePicker
                        selected={initialSelectedDateTime}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Time"
                        todayButton="Today"
                        dropdownMode="select"
                        placeholderText="MM/dd/yyyy h:mm aa"
                        onChange={handleChange}
                        className={ customClass }
                        ref={register({ name: fieldName }, requiredObject)}
                    />
                </>
            );
        case 'currency':
            return (
                <Form.Input fluid type={'number'}
                    step="0.01"
                    iconPosition={"left"}
                    icon={"dollar"}
                    label={customLabel}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    error={isFormError_h(errors[fieldName])}
                    ref={register({ name: fieldName }, requiredObject)}
                />
            );
        case 'number':
            return (
                <Form.Input 
                    fluid 
                    type={'number'}
                    label={customLabel}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    error={isFormError_h(errors[fieldName])}
                    ref={register({ name: fieldName }, requiredObject)}
                />
            );
        case 'percent':
            return (

                <Form.Input 
                    fluid
                    label={customLabel}
                    type={'number'}
                    step="0.01"
                    icon={"percent"}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    error={isFormError_h(errors[fieldName])}
                    ref={register({ name: fieldName }, requiredObject)
                    }
                />

            );
        case 'textarea':
            return (
                <Form.TextArea 
                    label={customLabel}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    error={isFormError_h(errors[fieldName])}
                    ref={register({ name: fieldName }, requiredObject)}
                />
            );
        
        default: 
            break;
       
    }

    return (
        <>
            <Form.Input
                fluid
                type={"text"}
                label={customLabel}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={handleChange}
                error={isFormError_h(errors[fieldName])}
                ref={register({ name: fieldName }, requiredObject)}
            />
        </>
    );

}

// export default ConfigField;

export default React.memo(ConfigField, (prevProps, nextProps) =>
    prevProps.fieldSettings === nextProps.fieldSettings && 
    prevProps.dbTable === nextProps.dbTable &&
    prevProps.errors === nextProps.errors);