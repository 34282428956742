/******************************
 *  Reducer for Users Service
 ******************************/
import * as types from '../types';

const initialState = {
    isLoading: false,
    error:'',
    email:'',
    firstName:'',
    lastName:'',
    _id:''
};

export default (state = initialState, action) => {
    
    switch (action.type) {
        case types.UPDATE_USER_FREE_TRIALS:
            return {
                ...state,
                freeTrials: {
                    ...state.freeTrials,
                    [action.payload.id]: {
                        expiration: action.payload.expiration
                    }
                }
            }
        case types.UPDATE_USER_PAYMENT_PROFILE:
            return {
                ...state,
                paymentProfile: action.payload
            }
        case types.SET_USER:
            var paymentProfile = null;
            var { email, _id, firstName, lastName } = action.payload;
            if( action.payload.paymentProfile ) {
                paymentProfile = action.payload.paymentProfile;
            }
            return {
                ...state,
                email,
                firstName,
                lastName,
                paymentProfile,
                freeTrials: action.payload.freeTrials ? action.payload.freeTrials : null,
                _id
            };
        case types.LOG_USER_OUT: 
            return Object.assign(initialState);
        default:
            return state;
    }
};