/**
 * Connections API calls
 */

import client from '../index';

/**
 * TODO: document this function
 * @param connection
 * @return {*}
 */
export function getQbTables(connection, dbid='') {
    return client.service('qb-tables').find({
        query: {
            connection,
            dbid
        }
    });
}


export function createQbTable({connectionId, tableName}) {

    return client.service('qb-tables').create({
        connectionId: connectionId,
        name: tableName
    });
}
