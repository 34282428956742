/**
* Plugins API Calls
*/

import client from '../index';

/**
 * Returns all plugins
 */
export function getPluginData() {
    return client.service('plugins').find();
}


/**
 * Get the plugin from the Database based on the Plugin Id
 *
 * @param {string} id The plugin id
 * @return {*}
 */
export function getPluginById(id) {
    return client.service('plugins').get(id)
}