import React from 'react';
import { Helmet } from "react-helmet";




/**
 * Used to incorporate page titles and descriptions across all pages in the application.
 * @param {Object} props React component props object
 * @param {String} props.description Page description
 * @param {String} props.title Page title
 */
function HelmetSeo({title, description}) {

    return (
        <>
            {/* SEO HELMET */}
            {(title || description) &&
                <Helmet>
                    {title &&
                        <title>{title}</title>
                    }
                    {description &&
                        <meta
                            name="description"
                            content={description}
                        />
                    }
                </Helmet>
            }
            {/* SEO HELMET */}
        </>
    );

}

export default HelmetSeo;