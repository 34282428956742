import React, { useEffect } from 'react';

//semantic
import {
    Button, Form, Grid
} from 'semantic-ui-react';

//react hook form
import { useForm } from 'react-hook-form';

//redux
import { useSelector } from "react-redux";

//react router
import { Link } from "react-router-dom";

import SectionGroup from '../../SectionGroup/SectionGroup';

//styles
import Account from "../Account";

//styles
// import styles from './index.module.css';



/**
 * Profile update page.
 * Uses Global State to get user data form the reduced state.
 *
 * @returns {*}
 * @constructor
 */
function MyProfile() {

    /*************************************************
     *  React Hook Form
     *************************************************/
    var { register, setValue } = useForm();



    /*************************************************
     *  Redux
     *************************************************/
    //current user logged in
    const { user } = useSelector(state => state);



    /*************************************************
     *  Effects
     *************************************************/
    // Updates form state
    useEffect(() => {
        //utilize existing connection and set values if present
        if (user) {
            setValue('firstName', user.firstName);
            setValue('lastName', user.lastName);
            setValue('email', user.email);
        }

    }, [user, setValue]);


    return (
        <>

            <Account pageTitle={"My Profile"} helpPopup={"This part of your account displays your current user profile."}>
                <SectionGroup
                    title="My Profile"
                    description="Your profile is shown below.  If you want to make updates to your profile, you will need to put in a support ticket by clicking the button below."
                >
                        <Grid>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={8} widescreen={8}>

                                    <Form error>

                                        <Form.Group widths='equal'>

                                            <Form.Field>
                                                <label>First Name</label>
                                                <input
                                                    placeholder='First Name'
                                                    name="firstName"
                                                    ref={register({ required: true })}
                                                    disabled
                                                />
                                            </Form.Field>

                                            <Form.Field>
                                                <label>Last Name</label>
                                                <input
                                                    placeholder='Last Name'
                                                    name="lastName"
                                                    ref={register({ required: true })}
                                                    disabled
                                                />
                                            </Form.Field>

                                        </Form.Group>

                                        <Form.Field>
                                            <label>Your Email</label>
                                            <input
                                                placeholder='Your Email'
                                                type={"email"}
                                                name={"email"}
                                                ref={register({ required: true })}
                                                disabled />
                                        </Form.Field>

                                        <div className="mb-medium" />

                                        <Link to="/contact">
                                            <Button
                                                primary
                                            >
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                Request Update
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                            </Button>
                                        </Link>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </SectionGroup>
            </Account>

        </>
    );

}

export default MyProfile;